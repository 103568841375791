import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.APP_STAGE,
    release: process.env.APP_VERSION_RELEASE,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

if (!process.env.REACT_APP_SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.error('Sentry DSN not defined');
}

export default Sentry;
