import cookie from 'js-cookie';

const setCookie = (key, value) => {
    cookie.set(key, value, {
        expires: 1,
    });
};

const removeCookie = (key) => {
    cookie.remove(key);
};

const getCookie = (key) => cookie.get(key);

export { setCookie, getCookie, removeCookie };
