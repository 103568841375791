import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Label/index.module.scss';

export const COLOR = {
    default: style.default,
    white: style.white,
    success: style.success,
    warning: style.warning,
    danger: style.danger,
};

const Label = ({ htmlFor, color, children, className }) => (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={htmlFor} className={classNames(style.label, color, className)}>
        {children}
    </label>
);

Label.propTypes = {
    htmlFor: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Label.defaultProps = {
    htmlFor: undefined,
    color: COLOR.default,
    className: undefined,
};

export default Label;
