import moment from 'moment';
import { createStatusBookingCalendarModal } from '_utils/createStatus';
import translateMealPlan from '_utils/translateMealPlan';

export const createBookingObjectToTable = (bookings) =>
    bookings.map((booking) => ({
        ...booking,
        status: booking.status,
        by_request_status: booking.by_request_status,
        by_request: booking.by_request,
        guests__name: !booking.guests.length ? '' : booking.guests[0].name,
        row_id: booking.id,
        total_days: moment(booking.end_date).diff(moment(booking.start_date), 'days'),
        meal_plan: translateMealPlan(booking.fare_data.meal_plan),
        reservation_type: booking.by_request ? 'Sob-consulta' : 'Allotment',
    }));

export const splitBookings = (bookings) => {
    const splitedBookings = {
        booked: [],
        byRequest: [],
    };

    bookings.map((booking) => {
        const { status, start_date: startDate, by_request_status: byRequestStatus } = booking;
        switch (createStatusBookingCalendarModal({ status, startDate, byRequestStatus }).message) {
            case 'Confirmada':
                splitedBookings.booked.push(booking);
                break;
            case 'Pendente':
                splitedBookings.byRequest.push(booking);
                break;
            default:
                break;
        }
        return true;
    });
    return splitedBookings;
};

export default { createBookingObjectToTable, splitBookings };
