import { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { removeRoom } from '_services/requestRooms';
import { errorHandler } from '_utils/errorHandler';

const useRemoveRoom = () => {
    const { id: roomId } = useParams();
    const history = useHistory();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [loadingModal, setLoadingModal] = useState(false);

    const handleRemove = useCallback(async () => {
        setLoadingModal(true);

        try {
            await removeRoom(accommodation.id, roomId, jwt);
            setLoadingModal(false);
            history.replace('/quartos');
        } catch (error) {
            setLoadingModal(false);
            const responseError = errorHandler(error).join(' ');
            history.push({
                pathname: history.location.pathname,
                data: {
                    show: true,
                    message: responseError,
                    type: 'danger',
                },
            });
        }
    }, [accommodation.id, roomId, jwt, history]);

    return [handleRemove, loadingModal];
};

export default useRemoveRoom;
