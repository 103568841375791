import useSWR from 'swr';
import { portalAdmin, portal } from '_services/apiConfig';

const apiType = (type) => {
    switch (type) {
        case false:
            return portal;

        default:
            return portalAdmin;
    }
};

export const useCacheRequest = (request, config, type) => {
    const connection = apiType(type);
    const {
        data: response,
        error,
        isValidating,
        revalidate,
        mutate,
    } = useSWR(
        JSON.stringify(request),
        async () => {
            const resp = await connection(request);
            return resp;
        },
        config
    );

    return {
        data: response && response.data,
        response,
        error,
        isValidating,
        revalidate,
        mutate,
    };
};

export default {
    useCacheRequest,
};
