import moment from 'moment';

export const validateVoucher = ({ status, byRequestStatus, startDate }) => {
    if (!status || !byRequestStatus || !startDate) throw Error('Argumentos não foram informados.');
    if (!['Booked', 'Canceled', 'Expired'].includes(status)) {
        throw Error('Status informado é inválido');
    }

    if (!['Approved', 'Wait', 'Refused'].includes(byRequestStatus)) {
        throw Error('byRequestStatus é inválido');
    }

    if (!moment(startDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).isValid()) {
        throw Error('Formato de data é inválido.');
    }
};

export const createStatusVoucher = ({ status, byRequestStatus, startDate }) => {
    validateVoucher({ status, byRequestStatus, startDate });

    if (status === 'Canceled' || status === 'Expired' || byRequestStatus === 'Refused') {
        return {
            status: 'danger',
            message: 'Cancelada',
            buttonDisabled: byRequestStatus !== 'Approved',
        };
    }

    if (
        byRequestStatus === 'Wait' &&
        moment(startDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).isAfter(moment())
    ) {
        return {
            status: 'default',
            message: 'Pendente',
            buttonDisabled: true,
        };
    }

    if (
        status === 'Booked' &&
        byRequestStatus === 'Approved' &&
        moment(startDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).isBefore(moment())
    ) {
        return {
            status: 'default',
            message: 'Realizada',
            buttonDisabled: false,
        };
    }

    return { status: 'success', message: 'Confirmada', buttonDisabled: false };
};

export const createStatusBookingCalendarModal = ({ status, byRequestStatus, startDate }) => {
    validateVoucher({ status, byRequestStatus, startDate });

    if (status === 'Canceled' || status === 'Expired' || byRequestStatus === 'Refused') {
        return {
            status: 'danger',
            message: 'Cancelada',
        };
    }

    if (byRequestStatus === 'Wait') {
        return {
            status: 'default',
            message: 'Pendente',
        };
    }

    if (
        byRequestStatus === 'Wait' &&
        moment(startDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).isAfter(moment())
    ) {
        return {
            status: 'default',
            message: 'Pendente',
        };
    }

    return { status: 'success', message: 'Confirmada' };
};

export const defineActiveOrInactive = (status) => {
    switch (status) {
        case true:
            return 'success';

        default:
            return 'disabled';
    }
};

export const defineLabelActiveOrInactive = (status) => {
    switch (status) {
        case true:
            return 'Ativo';

        default:
            return 'Inativo';
    }
};

export default {
    createStatusVoucher,
    validateVoucher,
    createStatusBookingCalendarModal,
    defineActiveOrInactive,
    defineLabelActiveOrInactive,
};
