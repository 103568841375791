import { useState } from 'react';

export const useCheckBox = (initialState = []) => {
    const [checkBox, setCheckBox] = useState(initialState);

    const handleCheckBox = (value) => {
        if (!Object.keys(value).length) {
            setCheckBox([]);
            return;
        }

        if (checkBox.find((item) => item === value)) {
            const checkboxList = checkBox.filter((check) => check !== value);
            setCheckBox(checkboxList);
            return;
        }

        setCheckBox([value, ...checkBox]);
    };

    return [checkBox, handleCheckBox];
};

export const useRadio = (initialState = []) => {
    const [radio, setRadio] = useState(initialState);

    const handleRadio = (value) => {
        if (!Object.keys(value).length) {
            setRadio([]);
            return;
        }

        setRadio([value]);
    };

    return [radio, handleRadio];
};

export const useCheckBoxId = () => {
    const [checkBoxId, setCheckBoxId] = useState([]);
    const handleCheckBoxId = (checkBox) => {
        if (!checkBox.length) {
            setCheckBoxId([]);
            return;
        }

        setCheckBoxId(checkBox.map((room) => room.id));
    };

    return [checkBoxId, handleCheckBoxId];
};
