import React from 'react';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentMeuHotel from '_organisms/SideContentMeuHotel';
import MainContentMeuHotelCadastroCondicaoAcomodacao from '_organisms/MainContentMeuHotelCadastroCondicaoAcomodacao';

const Condicao = () => (
    <TemplateMeuHotelContent
        title="Condição"
        main={<MainContentMeuHotelCadastroCondicaoAcomodacao />}
        side={<SideContentMeuHotel currentPath="/meu-hotel/condicao/novo" />}
        linkBack
    />
);

export default Condicao;
