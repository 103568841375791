const currencyConverter = (valueToConvert, format = 'pt-BR', currency = 'BRL') => {
    const value = Number(valueToConvert);
    if (value === 'NaN') return 'Erro ao converter pontos.';

    const convertedValue = new Intl.NumberFormat(format, {
        style: 'currency',
        currency,
    }).format(value);

    return convertedValue;
};

export default currencyConverter;
