import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '_organisms/Modal';
import Loading from '_atoms/Loading';
import Heading, { LEVEL } from '_atoms/Heading';
import Icon from '_atoms/Icon';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';

import style from '_organisms/ModalAction/index.module.scss';
import cancel from '_images/cancel.svg';
import success from '_images/check.svg';

const ModalAction = ({
    title,
    description,
    type,
    onSubmit,
    onCancel,
    submitLabel,
    cancelLabel,
    isLoading,
    ...props
}) => {
    let styleType;
    let styleButton;
    let styleIcon;

    switch (type) {
        case 'success':
            styleType = style.success;
            styleButton = TYPE.success;
            styleIcon = success;
            break;

        default:
            styleType = style.danger;
            styleButton = TYPE.danger;
            styleIcon = cancel;
            break;
    }

    return (
        <>
            <Loading visible={isLoading} />
            <Modal closeOnOverlay={false} {...props}>
                <div className={classNames(style.containerModal, styleType)}>
                    <Icon image={styleIcon} alt="icone" size="85" classname={style.icon} />
                    <Heading level={LEVEL.h2} className={classNames(style.header, style.h2)}>
                        {title}
                    </Heading>
                    <p className={style.text}>{description}</p>

                    <div className={style.button}>
                        {onCancel && (
                            <Button type={TYPE.link} onClick={onCancel}>
                                {cancelLabel}
                            </Button>
                        )}
                        <Button type={styleButton} size={BUTTON_SIZE.large} onClick={onSubmit}>
                            {submitLabel}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

ModalAction.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    isLoading: PropTypes.bool,
};

ModalAction.defaultProps = {
    title: '',
    description: '',
    type: '',
    onCancel: undefined,
    submitLabel: '',
    cancelLabel: '',
    isLoading: false,
};

export default ModalAction;
