import { SET_MODAL_BOOKINGS, SET_MODAL_TITLE } from '_redux/types';

const initialState = {
    bookings: [],
    title: {
        date: '',
        room: '',
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL_BOOKINGS:
            return { ...state, bookings: action.payload };
        case SET_MODAL_TITLE:
            return { ...state, title: action.payload };
        default:
            return state;
    }
};
