import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Select from '_atoms/Select/Select';
import TextArea from '_atoms/TextArea/TextArea';
import ButtonShimmer from '_atoms/Button/shimmer';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentMeuHotelCadastroCondicaoAcomodacaoShimmer from '_organisms/MainContentMeuHotelCadastroCondicaoAcomodacao/shimmer';
import { getConditions, saveAccommodationConditions } from '_services/requestAccommodations';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentMeuHotelCadastroCondicaoAcomodacao/index.module.scss';

const validationSchema = Yup.object().shape({
    description: Yup.string().required('Obrigatório'),
});

const MainContentMeuHotelCadastroCondicaoAcomodacao = () => {
    const [alert, setAlert] = useState({});
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState([]);
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        condition: conditions[0] ? conditions[0].value : '',
        description: '',
    };

    const onHandleSubmit = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});
            try {
                const params = {
                    accommodation: accommodation.id,
                    ...values,
                };

                await saveAccommodationConditions(accommodation.id, params, jwt);
                history.push('/meu-hotel/politicas-e-condicoes');
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível realizar o cadastro',
                    type: 'danger',
                });
            }
            setLoading(false);
        },
        [accommodation.id, jwt, history]
    );

    useEffect(() => {
        const getListConditions = async () => {
            try {
                setErrorMessage({});
                const { results } = await getConditions(jwt);
                const listConditions = results.map((condition) => ({
                    value: condition.id,
                    label: condition.title,
                }));
                setConditions(listConditions);
            } catch (error) {
                setErrorMessage(error);
            }
        };

        getListConditions();
    }, [jwt]);

    useEffect(() => () => setLoading(false), [loading]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                onHandleSubmit(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, handleChange, handleBlur } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <>
                                {loading ? (
                                    <MainContentMeuHotelCadastroCondicaoAcomodacaoShimmer />
                                ) : (
                                    <>
                                        <SectionBorder>
                                            <Heading
                                                level={LEVEL.h5}
                                                className={style.sectionTitle}
                                            >
                                                Acomodação
                                            </Heading>

                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Select
                                                        label="Condição"
                                                        name="condition"
                                                        quantityOptions={conditions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <TextArea
                                                        label="Descrição"
                                                        name="description"
                                                        placeholder="Descrição"
                                                        rows={5}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col d-flex justify-content-end">
                                                    {loading ? (
                                                        <ButtonShimmer />
                                                    ) : (
                                                        <>
                                                            <Button
                                                                type={TYPE.link}
                                                                size={BUTTON_SIZE.normal}
                                                                onClick={() => history.goBack()}
                                                            >
                                                                Voltar
                                                            </Button>

                                                            <Button
                                                                type={TYPE.secondary}
                                                                size={BUTTON_SIZE.normal}
                                                                onClick={() => {}}
                                                                typeSubmit
                                                                disabled={isSubmitting}
                                                            >
                                                                Salvar
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </SectionBorder>
                                    </>
                                )}
                            </>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelCadastroCondicaoAcomodacao;
