import React, { useState, useCallback, useEffect } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Alert from '_molecules/Alert';
import ImageGalleryUpload from '_molecules/ImageGalleryUpload';
import {
    saveBulkImagesRoom,
    updateImageCoverRoom,
    listImagesRooms,
    deleteImageRoom,
} from '_services/requestRooms';
import ErrorHandler from '_molecules/ErrorHandler';

const MainContentQuartoGaleriaImagens = () => {
    const [uploadFiles, setUploadFiles] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [imageCover, setImageCover] = useState({});
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const { id: roomId } = useParams();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState({});

    const handleImage = useCallback(
        (files) => {
            const uploadedFiles = files.map((file) => ({
                file,
                id: uniqueId('image_'),
                name: file.name,
                readableSize: filesize(file.size),
                preview: URL.createObjectURL(file),
                image_cover: file.is_cover ? file.url : '',
            }));

            setUploadFiles([...uploadFiles, ...uploadedFiles]);
            setImageList([...imageList, ...uploadedFiles]);
        },
        [uploadFiles, imageList]
    );

    const handleDelete = useCallback(
        async (imageId) => {
            setImageCover({});
            if (uploadFiles.some((image) => image.id === imageId)) {
                const uploadImages = uploadFiles.filter((image) => image.id !== imageId);
                const listImages = imageList.filter((image) => image.id !== imageId);

                setUploadFiles(uploadImages);
                setImageList(listImages);

                return;
            }

            setLoading(true);
            setErrorMessage({});

            try {
                await deleteImageRoom(accommodation.id, roomId, imageId, jwt);
                setAlert({
                    show: true,
                    message: 'Imagem excluída com sucesso!',
                    type: 'success',
                });

                const images = imageList.filter((image) => image.id !== imageId);

                setImageList(images);
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível excluir a imagem.',
                    type: 'danger',
                });
            }
            setLoading(false);
        },
        [imageList, accommodation.id, jwt, uploadFiles, roomId]
    );

    const listImages = useCallback(async () => {
        const { results } = await listImagesRooms(accommodation.id, roomId, jwt);

        const images = results.map((file) => {
            const imageName = file.url.split('/').pop();

            const image = {
                file,
                id: file.id,
                name: imageName,
                preview: file.url,
                image_cover: file.is_cover ? imageName : '',
            };

            return image;
        });

        setImageList(images);
        setUploadFiles([]);
        setLoading(false);
    }, [accommodation.id, jwt, roomId]);

    const handleSubmit = useCallback(async () => {
        if (Object.keys(imageCover).length && !uploadFiles.length) {
            await updateImageCoverRoom(accommodation.id, roomId, imageCover.id, jwt);
            setImageCover({});
        }

        if (!Object.keys(imageList).length) return;
        if (!uploadFiles.length) return;

        setLoading(true);
        setErrorMessage({});

        const imageData = new FormData();
        imageData.append(
            'image_cover',
            Object.keys(imageCover).length ? imageCover.name : imageList[0].name
        );
        uploadFiles.forEach((image) => {
            imageData.append('url', image.file);
        });

        try {
            await saveBulkImagesRoom(accommodation.id, roomId, imageData, jwt);
            setAlert({
                show: true,
                message: 'Imagens cadastradas com sucesso!',
                type: 'success',
            });

            setImageCover({});
            listImages();
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível cadastrar as imagens.',
                type: 'danger',
            });
        }
        setLoading(false);
    }, [accommodation.id, imageCover, jwt, uploadFiles, listImages, imageList, roomId]);

    useEffect(() => {
        listImages();
    }, [listImages]);

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }
        return () => setAlert({});
    }, [history.location.data]);

    return (
        <section className="col-xl-8 col-md-12">
            {alert.show && (
                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Alert type={alert.type}>{alert.message}</Alert>
                    </div>
                </div>
            )}
            {Object.values(errorMessage).length > 0 && (
                <div className="row mb-3">
                    <div className="col-xl-12">
                        <ErrorHandler errors={errorMessage} />
                    </div>
                </div>
            )}
            <ImageGalleryUpload
                title="Imagens do quarto"
                onDrop={handleImage}
                onDelete={handleDelete}
                onActiveImage={(image) => setImageCover({ ...image, image_cover: image.name })}
                onSubmit={handleSubmit}
                files={imageList}
                maxSize={10000000}
                loading={loading}
            />
        </section>
    );
};

export default MainContentQuartoGaleriaImagens;
