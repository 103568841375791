import React, { Children, useState, cloneElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MdClear } from 'react-icons/md';

import Button, { TYPE } from '_atoms/Button';
import BadgeCount from '_atoms/BadgeCount';
import DropdownMenu from '_molecules/DropdownMenu';

import style from '_molecules/DropDownFilterList/index.module.scss';

const renderChildren = (children) => {
    const childrenArray = Children.toArray(children);
    return Children.map(childrenArray, (child) =>
        cloneElement(<li className={style.navItem}>{child}</li>, {
            onClick: child.props.onChange,
        })
    );
};

const DropDownFilterList = ({
    label,
    children,
    onHandleFilterList,
    onResetFilter,
    itemSelected,
}) => {
    const [dropDown, setDropDown] = useState(true);

    return (
        <div className={style.container}>
            <div className={style.content}>
                <DropdownMenu
                    trigger={
                        <Button
                            className={classNames(style.buttonComponent, {
                                [style.isActive]: itemSelected,
                            })}
                            onClick={() => setDropDown(true)}
                        >
                            {label}
                            {itemSelected > 0 && <BadgeCount message={itemSelected} />}
                        </Button>
                    }
                    className={style.dropDownMenuContent}
                    keepOnClickInside={dropDown}
                >
                    <div className={style.nav}>
                        <ul className={classNames(style.nav, style.statusContainer)}>
                            {renderChildren(children)}
                        </ul>
                        <Button
                            type={TYPE.secondary}
                            onClick={() => {
                                onHandleFilterList();
                                setDropDown(false);
                            }}
                            isBlock
                        >
                            Aplicar
                        </Button>
                    </div>
                </DropdownMenu>
                {itemSelected > 0 && (
                    <div className={style.resetFilter}>
                        <button onClick={() => onResetFilter()} className={style.resetFilterButton}>
                            <MdClear className={style.icon} size={16} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

DropDownFilterList.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object])
        .isRequired,
    onHandleFilterList: PropTypes.func.isRequired,
    onResetFilter: PropTypes.func.isRequired,
    itemSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DropDownFilterList.defaultProps = {
    itemSelected: 0,
};

export default DropDownFilterList;
