import React from 'react';
import Dashboard from '_templates/Dashboard';
import imgError from '_images/icon-error-404.svg';

import style from '_pages/erro/index.module.scss';

const Erro = () => (
    <Dashboard>
        <div className="wrapper">
            <div className="wrapperContent">
                <div className={style.flexContainer}>
                    <div className={style.contentContainer}>
                        <img className={style.imgError} src={imgError} alt="Ícone Erro" />
                        <h1 className={style.titlePage}>
                            Ops!
                            <small className={style.small}>
                                Não encontramos o que você estava buscando
                            </small>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
);

export default Erro;
