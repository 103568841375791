import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SectionBorder from '_molecules/SectionBorder';

import style from '_molecules/CollapsibleContent/index.module.scss';

const CollapsibleContent = ({ title, children, showOpened, message }) => {
    const [isActive, setIsActive] = useState(showOpened);

    return (
        <SectionBorder>
            <button type="button" className={style.button} onClick={() => setIsActive(!isActive)}>
                {title} {message && `- ${message}`}
                <div className={classNames(style.arrow, { [style.active]: isActive })} />
            </button>
            <div className={classNames(style.content, { [style.active]: isActive })}>
                <div className={style.contentInner}>{children}</div>
            </div>
        </SectionBorder>
    );
};

CollapsibleContent.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    showOpened: PropTypes.bool,
    message: PropTypes.string,
};

CollapsibleContent.defaultProps = {
    showOpened: false,
    message: '',
};

export default CollapsibleContent;
