import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Input from '_atoms/Input/Input';
import SectionBorder, { BGCOLOR } from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentMeuHotelDadosContatoShimmer from '_organisms/MainContentMeuHotelContatos/shimmer';
import ButtonShimmer from '_atoms/Button/shimmer';
import { CELLPHONE_MASK } from '_utils/inputMasks';
import actions from '_redux/actions';
import useAccommodation from '_hooks/useAccommodation';
import useUserPermission from '_hooks/useUserPermission';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentMeuHotelContatos/index.module.scss';

const MainContentMeuHotelContatos = () => {
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const [accommodationDetails, isLoading] = useAccommodation(accommodation.id, jwt);
    const { userIsSuperUser } = useUserPermission();
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        phone: accommodationDetails.phone || '',
        email: accommodationDetails.email || '',
        reservation_name: accommodationDetails.reservation_name || '',
        reservation_phone: accommodationDetails.reservation_phone || '',
        reservation_email: accommodationDetails.reservation_email || '',
        commercial_name: accommodationDetails.commercial_name || '',
        commercial_phone: accommodationDetails.commercial_phone || '',
        commercial_email: accommodationDetails.commercial_email || '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('Obrigatório'),
        phone: Yup.string().required('Obrigatório'),
        reservation_email: Yup.string().email('E-mail inválido').required('Obrigatório'),
    });

    const onHandleSubmit = async (values) => {
        setLoading(true);
        setErrorMessage({});
        try {
            await dispatch(actions.updateAccommodation(accommodation.id, jwt, values));
            setAlert({
                show: true,
                message: 'As informações do hotel foram atualizadas!',
                type: 'success',
            });
        } catch (error) {
            setAlert({
                show: true,
                message: 'Não foi possível atualizar as informações.',
                type: 'danger',
            });
            setErrorMessage(error);
        }
        setLoading(false);
    };

    useEffect(() => setLoading(isLoading), [isLoading]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                onHandleSubmit(values, setFieldError);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleChange, handleBlur, handleSubmit } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <SectionBorder>
                                <Heading level={LEVEL.h6} className={style.sectionTitle}>
                                    Contatos do hotel
                                </Heading>
                                {loading ? (
                                    <MainContentMeuHotelDadosContatoShimmer />
                                ) : (
                                    <>
                                        <div className="row mt-3">
                                            <div className="col-xl-12 col-md-12">
                                                <MaskedInput
                                                    label="Telefone"
                                                    mask={CELLPHONE_MASK}
                                                    placeholder="Digite o telefone"
                                                    name="phone"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    inputMode="tel"
                                                    autoCorrect="off"
                                                    render={(ref, props) => (
                                                        <Input ref={ref} {...props} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-xl-12 col-md-12">
                                                <Input
                                                    label="E-mail"
                                                    name="email"
                                                    placeholder="Digite o e-mail"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </SectionBorder>
                            {userIsSuperUser && (
                                <SectionBorder backgroundColor={BGCOLOR.gray}>
                                    <Heading level={LEVEL.h6}>Uso interno</Heading>
                                    <Heading
                                        level={LEVEL.h6}
                                        className={classNames(style.sectionTitle, 'mt-3')}
                                    >
                                        Contatos de reservas
                                    </Heading>
                                    {loading ? (
                                        <MainContentMeuHotelDadosContatoShimmer />
                                    ) : (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="Nome"
                                                        name="nameReservation"
                                                        placeholder="Digite o Nome"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-4 col-md-4">
                                                    <MaskedInput
                                                        label="Telefone"
                                                        mask={CELLPHONE_MASK}
                                                        placeholder="Digite o telefone"
                                                        name="reservation_phone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        autoCorrect="off"
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xl-8 col-md-8">
                                                    <Input
                                                        label="E-mail"
                                                        name="reservation_email"
                                                        placeholder="Digite o e-mail"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <Heading
                                        level={LEVEL.h6}
                                        className={classNames(style.sectionTitle, 'mt-3')}
                                    >
                                        Contatos do comercial
                                    </Heading>
                                    {loading ? (
                                        <MainContentMeuHotelDadosContatoShimmer />
                                    ) : (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="Nome"
                                                        name="commercial_name"
                                                        placeholder="Digite o Nome"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-4 col-md-4">
                                                    <MaskedInput
                                                        label="Telefone"
                                                        mask={CELLPHONE_MASK}
                                                        placeholder="Digite o telefone"
                                                        name="commercial_phone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        autoCorrect="off"
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xl-8 col-md-8">
                                                    <Input
                                                        label="E-mail"
                                                        name="commercial_email"
                                                        placeholder="Digite o e-mail"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </SectionBorder>
                            )}
                            <div className="container">
                                <div
                                    className={classNames(
                                        'row justify-content-end',
                                        style.blockButton
                                    )}
                                >
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            {loading ? (
                                                <ButtonShimmer />
                                            ) : (
                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.normal}
                                                    onClick={() => {}}
                                                    typeSubmit
                                                    isBlock
                                                    disabled={isSubmitting}
                                                >
                                                    Salvar
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelContatos;
