import React, { PureComponent, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Button, { TYPE } from '_atoms/Button';
import SectionBorder from '_molecules/SectionBorder';
import Heading, { LEVEL } from '_atoms/Heading';
import SectionContent from '_molecules/SectionContent';
import currencyConverter from '_utils/currencyConverter';
import useUserPermission from '_hooks/useUserPermission';
import Badge from '_atoms/Badge';
import { cancellationConfirmation } from '_services/requestBooking';
import Alert from '_molecules/Alert';

import style from '_pages/reserva/index.module.scss';

import logo from '_images/logo-viajar-faz-bem.svg';
import errorOutline from '_images/error-outline.svg';

const ComponentToPrint = ({
    bookingRequestData,
    bookingStatus,
    onToggleModalConfirm,
    onToggleModalCancel,
    onToggleModalConfirmCancelation,
    refreshVoucher,
}) => {
    const { userIsStaff } = useUserPermission();
    const { authentication } = useSelector((state) => state);

    const showByRequestButton = useCallback((bookingObject) => {
        const today = moment().format('YYYY-MM-DD');
        return (
            bookingObject.status !== 'Canceled' &&
            bookingObject.by_request_status === 'Wait' &&
            moment(bookingObject.start_date).isSameOrAfter(today)
        );
    }, []);

    const translateCanceledByName = useCallback((value) => {
        switch (value) {
            case 'LeadAccount':
                return 'Administrador';
            case 'Agent':
                return 'Agente';
            case 'Hotel':
                return 'Hotel';
            case 'System':
                return 'Sistema';
            default:
                return null;
        }
    }, []);

    const guests = useMemo(
        () =>
            bookingRequestData &&
            bookingRequestData.search_parameters.rooms.reduce((acc, item) => {
                let crianca;
                const adultos =
                    item.adults > 1 ? `${item.adults} adultos` : `${item.adults} adulto`;
                if (item.children > 0) {
                    crianca = item.children_age.map((age) => `, 1 criança de ${age} anos`).join('');
                }

                return `${adultos}${crianca || ''}`;
            }, ''),
        [bookingRequestData]
    );

    const pricePerday = useMemo(() => {
        const date =
            bookingRequestData &&
            bookingRequestData.price.map((price, idx) => {
                const day = idx + 1;
                return (
                    <p key={day}>
                        {moment(bookingRequestData.start_date)
                            .add(idx, 'days')
                            .format('DD/MM/YYYY')}
                        : {currencyConverter(price)}
                    </p>
                );
            });

        return date;
    }, [bookingRequestData]);

    const showButtonCancelConfirmation = useMemo(
        () =>
            userIsStaff &&
            bookingRequestData.status === 'Canceled' &&
            bookingRequestData.cancellation_confirmation === null,
        [bookingRequestData, userIsStaff]
    );

    useEffect(() => {
        const handleAutomaticCancelConfirmation = async () => {
            try {
                await cancellationConfirmation(bookingRequestData.id, authentication);
                await refreshVoucher();
            } catch (error) {
                console.log(error);
            }
        };

        if (
            !userIsStaff &&
            bookingRequestData.status === 'Canceled' &&
            bookingRequestData.by_request === false &&
            bookingRequestData.cancellation_confirmation === null
        ) {
            handleAutomaticCancelConfirmation();
        }
    }, [userIsStaff, bookingRequestData, authentication, refreshVoucher]);

    return (
        <div className={style.content}>
            <Alert type="warning" icon={errorOutline} className={style.alertVoucherReminder}>
                <strong>Lembre-se! Nossos vouchers são pessoais e intransferíveis.</strong> Não
                aceite hóspedes com informações diferentes das registradas em nosso sitema.
            </Alert>
            <div className="row">
                <div className="col-xl-6 col-md-6">
                    <div className={style.print}>
                        <div className={style.printLogo}>
                            <img src={logo} alt="viajar faz bem" />
                        </div>
                    </div>
                    <div className={style.print}>
                        <Heading level={LEVEL.h1} className={classNames(style.h1)}>
                            {`Reserva #${bookingRequestData.id}`}

                            <div className={style.badge}>
                                <Badge
                                    message={bookingStatus.message}
                                    status={bookingStatus.status}
                                />
                            </div>
                        </Heading>
                    </div>
                    {showByRequestButton(bookingRequestData) && (
                        <div className={style.containerButtonsApprove}>
                            <SectionBorder>
                                <Heading level={LEVEL.h6} className={style.header}>
                                    Sob-consulta
                                </Heading>
                                <div className={classNames('row')}>
                                    <div className="col-auto">
                                        <Button type={TYPE.success} onClick={onToggleModalConfirm}>
                                            Aprovar
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button type={TYPE.danger} onClick={onToggleModalCancel}>
                                            Recusar
                                        </Button>
                                    </div>
                                </div>
                            </SectionBorder>
                        </div>
                    )}
                    {showButtonCancelConfirmation && (
                        <div className={style.containerButtonsApprove}>
                            <SectionBorder>
                                <Heading level={LEVEL.h6} className={style.header}>
                                    Confirmar cancelamento
                                </Heading>
                                <div className={classNames('row')}>
                                    <div className="col-auto">
                                        <Button
                                            type={TYPE.success}
                                            onClick={onToggleModalConfirmCancelation}
                                        >
                                            Confirmar
                                        </Button>
                                    </div>
                                </div>
                            </SectionBorder>
                        </div>
                    )}
                    {bookingRequestData.guests &&
                        bookingRequestData.guests.map((guest, index) => (
                            <SectionBorder key={`${guest.name}${index + 1}`}>
                                <Heading level={LEVEL.h6} className={style.header}>
                                    Informações do hóspede #{index + 1}
                                    {index === 0 && ' - Principal'}
                                </Heading>
                                <div className={classNames('container', style.container)}>
                                    <div className={classNames('row', style.row)}>
                                        <p className="col-xl-4 col-md-4 col-sm-4 col-4">
                                            Tipo de hóspede
                                        </p>
                                        <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                            {guest.guest_type === 'A' ? 'Adulto' : 'Criança'}
                                        </p>
                                    </div>
                                    <div className={classNames('row', style.row)}>
                                        <p className="col-xl-4 col-md-4 col-sm-4 col-4">Hóspede</p>
                                        <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                            {guest.name} {guest.surname}
                                        </p>
                                    </div>
                                </div>
                            </SectionBorder>
                        ))}
                    <SectionBorder>
                        <Heading level={LEVEL.h6} className={style.header}>
                            Informações do hospedagem
                        </Heading>
                        <div className={classNames('container', style.container)}>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Emitido em</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.by_request_status !== 'Wait' &&
                                        moment(
                                            bookingRequestData.booked_at ||
                                                bookingRequestData.created_at
                                        ).format('DD/MM/YYYY')}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">
                                    Número de quartos
                                </p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.quantity}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Hóspedes</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">{guests}</p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Valor por dia</p>
                                <div className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {pricePerday}
                                </div>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Valor total</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {currencyConverter(bookingRequestData.total_price)}
                                </p>
                            </div>
                        </div>
                    </SectionBorder>
                </div>
                <div className={classNames('col-xl-6 col-md-6', style.extraMargin)}>
                    <SectionBorder>
                        <Heading level={LEVEL.h6} className={style.header}>
                            Detalhes da reserva
                        </Heading>
                        <div className={classNames('container', style.container)}>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Check-in</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {moment(bookingRequestData.start_date).format('DD/MM/YYYY')}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Check-out</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {moment(bookingRequestData.end_date).format('DD/MM/YYYY')}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Dias</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {moment(bookingRequestData.end_date).diff(
                                        moment(bookingRequestData.start_date),
                                        'days'
                                    )}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Apartamento</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.room_name}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Variante</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.room_variant_data.label}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Cidade</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.accommodation &&
                                        bookingRequestData.accommodation.city.name}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Hotel</p>
                                <p
                                    className={classNames(
                                        style.hotelName,
                                        'col-xl-8 col-md-8 col-sm-8 col-8'
                                    )}
                                >
                                    {bookingRequestData.accommodation &&
                                        bookingRequestData.accommodation.trade_mark}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">CNPJ</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.accommodation.document}
                                </p>
                            </div>
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Agente</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.agent_id}
                                </p>
                            </div>

                            {bookingRequestData.date_cancel && (
                                <div className={classNames('row', style.row)}>
                                    <p className="col-xl-4 col-md-4 col-sm-4 col-4">
                                        Cancelado por
                                    </p>
                                    <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                        {bookingRequestData.canceled_by_agent ||
                                            translateCanceledByName(
                                                bookingRequestData.canceled_by
                                            )}{' '}
                                        em{' '}
                                        {moment(bookingRequestData.date_cancel).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </p>
                                </div>
                            )}

                            {bookingRequestData.cancellation_confirmation && (
                                <div className={classNames('row', style.row)}>
                                    <p className="col-xl-4 col-md-4 col-sm-4 col-4">
                                        Cancelamento revisado por
                                    </p>
                                    <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                        {bookingRequestData.cancellation_confirmation.confirmed_by}{' '}
                                        em{' '}
                                        {moment(
                                            bookingRequestData.cancellation_confirmation
                                                .confirmed_at
                                        ).format('DD/MM/YYYY')}
                                    </p>
                                </div>
                            )}
                            <div className={classNames('row', style.row)}>
                                <p className="col-xl-4 col-md-4 col-sm-4 col-4">Observações</p>
                                <p className="col-xl-8 col-md-8 col-sm-8 col-8">
                                    {bookingRequestData.notes}
                                </p>
                            </div>
                        </div>
                    </SectionBorder>
                    <SectionContent>
                        <div className={style.print}>
                            <div className={style.printCompanyInfo}>
                                <p>
                                    VIAJAR FAZ BEM SBTUR S.A. <br />
                                    04.495.717/0001-02 <br />
                                    (48) 4001-9200 <br />
                                    Rod. José Carlos Daux, 550 - 407 Torre Jurerê A <br />
                                    SC 401 Square Corporate, João Paulo <br />
                                    Florianópolis - SC <br />
                                    CEP: 88032005
                                </p>
                            </div>
                        </div>
                    </SectionContent>
                </div>
            </div>
        </div>
    );
};

ComponentToPrint.propTypes = {
    bookingRequestData: PropTypes.shape({
        id: PropTypes.number,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        total_price: PropTypes.number,
        room_variant_data: {
            label: PropTypes.string,
        },
        accommodation: {
            city: {
                name: PropTypes.string,
            },
            company_name: PropTypes.string,
            document: PropTypes.string,
        },
        canceled_by: PropTypes.string,
        canceled_by_agent: PropTypes.string,
        date_cancel: PropTypes.string,
        agent_id: PropTypes.string,
        notes: PropTypes.string,
        room_name: PropTypes.string,
        quantity: PropTypes.number,
        price: PropTypes.number,
        guests: PropTypes.arrayOf(PropTypes.object),
        search_parameters: PropTypes.shape({
            rooms: PropTypes.arrayOf(PropTypes.object),
        }),
        by_request_status: PropTypes.string,
        created_at: PropTypes.string,
        booked_at: PropTypes.string,
        cancellation_confirmation: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(PropTypes.string),
        ]),
        status: PropTypes.string,
        by_request: PropTypes.bool,
    }).isRequired,
    bookingStatus: PropTypes.shape({
        buttonDisabled: PropTypes.bool,
        message: PropTypes.string,
        status: PropTypes.string,
    }).isRequired,
    onToggleModalConfirm: PropTypes.func.isRequired,
    onToggleModalCancel: PropTypes.func.isRequired,
    onToggleModalConfirmCancelation: PropTypes.func.isRequired,
    refreshVoucher: PropTypes.func.isRequired,
};

class VoucherPrint extends PureComponent {
    render() {
        const {
            bookingRequestData,
            bookingStatus,
            onToggleModalConfirm,
            onToggleModalCancel,
            onToggleModalConfirmCancelation,
            refreshVoucher,
        } = this.props;

        return (
            <ComponentToPrint
                bookingRequestData={bookingRequestData}
                bookingStatus={bookingStatus}
                onToggleModalConfirm={onToggleModalConfirm}
                onToggleModalCancel={onToggleModalCancel}
                onToggleModalConfirmCancelation={onToggleModalConfirmCancelation}
                refreshVoucher={refreshVoucher}
            />
        );
    }
}

VoucherPrint.propTypes = {
    bookingRequestData: PropTypes.shape({
        id: PropTypes.number,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        total_price: PropTypes.number,
        room_variant_data: {
            label: PropTypes.string,
        },
        accommodation: {
            city: {
                name: PropTypes.string,
            },
            company_name: PropTypes.string,
            document: PropTypes.string,
        },
        canceled_by: PropTypes.string,
        canceled_by_agent: PropTypes.string,
        date_cancel: PropTypes.string,
        agent_id: PropTypes.string,
        notes: PropTypes.string,
        room_name: PropTypes.string,
        quantity: PropTypes.number,
        price: PropTypes.number,
        guests: PropTypes.arrayOf(PropTypes.object),
        search_parameters: PropTypes.shape({
            rooms: PropTypes.arrayOf(PropTypes.object),
        }),
        by_request_status: PropTypes.string,
        created_at: PropTypes.string,
        booked_at: PropTypes.string,
    }).isRequired,
    bookingStatus: PropTypes.shape({
        buttonDisabled: PropTypes.bool,
        message: PropTypes.string,
        status: PropTypes.string,
    }).isRequired,
    onToggleModalConfirm: PropTypes.func.isRequired,
    onToggleModalCancel: PropTypes.func.isRequired,
    onToggleModalConfirmCancelation: PropTypes.func.isRequired,
    refreshVoucher: PropTypes.func.isRequired,
};

export default VoucherPrint;
