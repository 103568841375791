import React from 'react';
import PropTypes from 'prop-types';

import useUserPermission from '_hooks/useUserPermission';

import style from '_atoms/CalendarVariantDetails/index.module.scss';

const CalendarVariantDetails = ({ availability }) => {
    const { userIsStaff } = useUserPermission();

    const priceUSForBr = (value) => {
        if (value.toString().replace('.', ',').includes(',')) {
            return `${value.toString().replace('.', ',')}0`;
        }
        return value;
    };

    return (
        <div className={style.blockVariantDetails}>
            <p className={style.allotments}>{availability.allotments}</p>
            {availability.price.map((price) => (
                <>
                    <p key={`${availability.date}-${price.price}`} className={style.price}>
                        R${priceUSForBr(price.price)}
                    </p>
                    {userIsStaff && (
                        <p
                            key={`${availability.date}-${price.markup_price}`}
                            className={style.priceMarkup}
                        >
                            R${priceUSForBr(price.markup_price)}
                        </p>
                    )}
                </>
            ))}
        </div>
    );
};

CalendarVariantDetails.propTypes = {
    availability: PropTypes.shape({
        date: PropTypes.string,
        price: PropTypes.arrayOf(PropTypes.object),
        allotments: PropTypes.number,
    }),
};

CalendarVariantDetails.defaultProps = {
    availability: undefined,
};

export default CalendarVariantDetails;
