import React, { useCallback, useState, useRef } from 'react';
import TemplateDefault from '_templates/TemplateDefault';
import Table, { COLUMN_SIZE } from '_molecules/Table';
import Badge from '_atoms/Badge';
import { getAccommodationFares, deleteAccommodationFares } from '_services/requestFares';
import { useSelector } from 'react-redux';
import Button, { TYPE } from '_atoms/Button';
import { useHistory, Link } from 'react-router-dom';
import ModalAction from '_organisms/ModalAction';
import Alert from '_molecules/Alert';
import { MdEdit, MdClose } from 'react-icons/md';
import useUserPermission from '_hooks/useUserPermission';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_pages/tarifas/index.module.scss';

const Tarifas = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalDeleteFare, setModalDeleteFare] = useState(false);
    const [loadingModalDeleteFare, setLoadingModalDeleteFare] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const fareId = useRef(null);
    const currentPage = useRef();
    const [alert, setAlert] = useState(() => {
        if (!history.location.data) {
            return {};
        }
        return history.location.data;
    });
    const [errorTableData, setErrorTableData] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const { accommodation, authentication } = useSelector((state) => state);
    const { userIsSuperUser } = useUserPermission();

    const handleModalDeleteFare = useCallback(
        (id) => {
            fareId.current = id;
            setModalDeleteFare(!modalDeleteFare);
        },
        [modalDeleteFare]
    );

    const renderBadge = useCallback((tableParams) => {
        const { value } = tableParams;
        if (!value) return <Badge message="Desativada" status="danger" />;

        return <Badge message="Ativa" status="success" />;
    }, []);

    const renderOptions = useCallback(
        (tableParams) => {
            const { value } = tableParams;
            return (
                <>
                    <Link
                        to={`tarifa/${value}/edit`}
                        className={style.buttonOptions}
                        title="Editar tarifa"
                    >
                        <MdEdit className={style.icon} size={28} />
                    </Link>
                    <button
                        type="button"
                        onClick={() => handleModalDeleteFare(value)}
                        className={style.buttonOptions}
                        title="Remover tarifa"
                    >
                        <MdClose className={style.icon} size={28} />
                    </button>
                </>
            );
        },
        [handleModalDeleteFare]
    );

    const columns = [
        {
            Header: 'Nome',
            accessor: 'name',
        },
        {
            Header: 'Número de pessoas',
            accessor: 'max_places',
        },
        {
            Header: 'Dias para cancelar',
            accessor: 'days_to_cancel',
        },
        {
            Header: 'Status',
            accessor: 'is_active',
            renderColumn: renderBadge,
        },
        {
            Header: 'Opções',
            accessor: 'id',
            renderColumn: renderOptions,
        },
    ];

    const getFares = useCallback(
        async ({ pageSize, pageIndex }) => {
            if (accommodation.id) {
                const params = {
                    id: accommodation.id,
                    limit: pageSize,
                    offset: pageIndex * pageSize,
                };

                try {
                    setErrorTableData(false);
                    setErrorMessage({});
                    setLoading(true);
                    const jwt = authentication;
                    const { results, count } = await getAccommodationFares(params, jwt);

                    setData(results);
                    currentPage.current = pageIndex;
                    setPageNumber(Math.ceil(count / pageSize));
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    setErrorTableData(true);
                    setErrorMessage(error);
                }
            }
        },
        [accommodation, authentication]
    );

    const handleDeleteFare = useCallback(async () => {
        setLoadingModalDeleteFare(true);
        try {
            const jwt = authentication;
            await deleteAccommodationFares(accommodation.id, fareId.current, jwt);
            getFares({ pageSize: 10, pageIndex: currentPage.current });
            setAlert({
                show: true,
                message: 'Tarifa excluída com sucesso!',
                type: 'success',
            });
        } catch (error) {
            setAlert({
                show: true,
                message: 'Não foi possível excluir a tarifa.',
                type: 'danger',
            });
        }
        setModalDeleteFare(false);
        setLoadingModalDeleteFare(false);
    }, [fareId, getFares, accommodation, authentication]);

    const handleNewFare = () => {
        history.push('/tarifa/novo');
    };

    return (
        <TemplateDefault
            title="Tipos de tarifa"
            subtitle="Listagem de tipos de tarifa"
            buttons={
                userIsSuperUser && (
                    <Button type={TYPE.secondary} onClick={handleNewFare}>
                        Adicionar tarifa
                    </Button>
                )
            }
        >
            <div className={style.content}>
                {alert.show && (
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <Alert type={alert.type}>{alert.message}</Alert>
                        </div>
                    </div>
                )}
                {Object.values(errorMessage).length > 0 && (
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <ErrorHandler errors={errorMessage} />
                        </div>
                    </div>
                )}
                <Table
                    columns={columns}
                    data={data}
                    pageNumber={pageNumber}
                    fetchData={getFares}
                    loading={loading}
                    columnClassName={style.column}
                    columnSize={COLUMN_SIZE.small}
                    errorLoadingData={errorTableData}
                    rowsPerPage={50}
                    disableSortBy
                />
            </div>
            {modalDeleteFare && (
                <ModalAction
                    showModal={modalDeleteFare}
                    modalName="cancelBooking"
                    title="Deseja realmente deletar esta tarifa?"
                    type="danger"
                    submitLabel="Confirmar"
                    cancelLabel="Cancelar"
                    onCancel={handleModalDeleteFare}
                    onSubmit={handleDeleteFare}
                    onhandleCloseModal={handleModalDeleteFare}
                    closeOnOverlay
                    closeOnEsc
                    isLoading={loadingModalDeleteFare}
                />
            )}
        </TemplateDefault>
    );
};

export default Tarifas;
