import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Checkbox/index.module.scss';

export const CHECKBOX_SIZE = {
    normal: style.normal,
    small: style.small,
    large: style.large,
};

const Checkbox = ({ className, onChange, labelText, name, size, checked, isDisabled }) => {
    const classChecked = checked ? style.inputChecked : style.inputNoChecked;

    return (
        /* eslint-disable-next-line */
        <label
            className={classNames(style.label, size, { [style.disabled]: isDisabled }, className)}
        >
            <div className={classNames(style.blockCheckbox)}>
                <input
                    name={name}
                    type="checkbox"
                    onChange={onChange}
                    className={style.inputCheckbox}
                    disabled={isDisabled}
                />
                <div className={classNames(style.wrapperInput, classChecked)}>
                    <svg viewBox="0 0 24 24" className={classNames(style.svgArrow)}>
                        <polyline points="20 6 9 17 4 12" />
                    </svg>
                </div>
            </div>
            <span className={style.labelText}>{labelText}</span>
        </label>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    labelText: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

Checkbox.defaultProps = {
    className: undefined,
    onChange: undefined,
    labelText: '',
    name: '',
    size: CHECKBOX_SIZE.normal,
    checked: false,
    isDisabled: false,
};

export default Checkbox;
