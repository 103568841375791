import { SET_ACCOMMODATION, CLEAN_ACCOMMODATION } from '_redux/types';
import { decrypt } from '_utils/crypt';
import { getCookie } from '_utils/cookie';

const accommodation = decrypt(getCookie('portal_accommodation'));

const initialState = accommodation || {
    id: '',
    tradeMark: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOMMODATION:
            return { ...action.payload };
        case CLEAN_ACCOMMODATION:
            return {
                id: '',
                tradeMark: '',
            };
        default:
            return state;
    }
};
