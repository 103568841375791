import React from 'react';
import { Route as ReactRouterDomRoute, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Route = ({ isPrivate, component: Component, ...rest }) => {
    const { authentication, accommodation } = useSelector((state) => state);

    const handleRoute = (location) => {
        if (isPrivate && authentication) {
            if (
                !accommodation.id &&
                location.pathname !== '/escolher-hotel' &&
                location.pathname !== '/cadastrar-hotel'
            ) {
                return <Redirect to={{ pathname: '/escolher-hotel', state: { from: location } }} />;
            }

            return <Component />;
        }

        if (isPrivate) {
            return <Redirect to={{ pathname: '/entrar', state: { from: location } }} />;
        }

        if (!isPrivate && authentication) {
            if (!location.pathname.match('/recuperacao-de-senha/')) {
                return <Redirect to={{ pathname: '/', state: { from: location } }} />;
            }
            return <Component />;
        }

        return <Component />;
    };

    return <ReactRouterDomRoute {...rest} render={({ location }) => handleRoute(location)} />;
};

Route.propTypes = {
    component: PropTypes.elementType.isRequired,
    isPrivate: PropTypes.bool,
};

Route.defaultProps = {
    isPrivate: true,
};

export default Route;
