import React, { Component } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_organisms/Modal/index.module.scss';

ReactModal.setAppElement('body');

export const SIZE = {
    small: style.small,
    normal: style.normal,
    large: style.large,
};

export const INTERNAL_PADDING = {
    fit: style.fit,
    default: style.default,
    fat: style.fat,
};

class Modal extends Component {
    handleCloseModal() {
        const { onhandleCloseModal, modalName } = this.props;
        onhandleCloseModal(modalName);
    }

    render() {
        const {
            children,
            size,
            internalPadding,
            closeOnOverlay,
            showModal,
            buttonToClose,
            closeOnEsc,
        } = this.props;

        return (
            <ReactModal
                isOpen={showModal}
                onRequestClose={() => {
                    this.handleCloseModal();
                }}
                shouldCloseOnEsc={closeOnEsc}
                shouldCloseOnOverlayClick={closeOnOverlay}
                className={classNames('customModal', size, internalPadding)}
                overlayClassName="customOverlay"
            >
                {buttonToClose && (
                    /* eslint-disable jsx-a11y/control-has-associated-label */
                    <button
                        className={style.closeButton}
                        type="button"
                        onClick={() => {
                            this.handleCloseModal();
                        }}
                    />
                )}
                {children}
            </ReactModal>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.node,
    size: PropTypes.string,
    internalPadding: PropTypes.string,
    showModal: PropTypes.bool,
    onhandleCloseModal: PropTypes.func.isRequired,
    modalName: PropTypes.string.isRequired,
    closeOnOverlay: PropTypes.bool,
    buttonToClose: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
};

Modal.defaultProps = {
    children: undefined,
    size: SIZE.normal,
    internalPadding: INTERNAL_PADDING.default,
    showModal: false,
    closeOnOverlay: true,
    buttonToClose: true,
    closeOnEsc: true,
};

export default Modal;
