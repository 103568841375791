import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';

import TemplateDefault from '_templates/TemplateDefault';
import SectionBorder from '_molecules/SectionBorder';
import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import Select from '_atoms/Select/Select';
import Input from '_atoms/Input/Input';
import TextArea from '_atoms/TextArea/TextArea';
import Checkbox from '_atoms/Checkbox/Checkbox';
import LineInstruction, { MESSAGE_TYPE } from '_molecules/LineInstruction';
import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import {
    createAccommodationFares,
    getAccommodationFareById,
    editAccommodationFares,
    getCancelPlansAccommodation,
    saveCancelPlansAccommodation,
    updateCancelPlansAccommodation,
    removeCancelPlansAccommodation,
} from '_services/requestFares';
import TarifaShimmer from '_pages/tarifa/shimmer';
import ModalAction from '_organisms/ModalAction';
import style from '_pages/tarifa/index.module.scss';
import ErrorHandler from '_molecules/ErrorHandler';

const mealPlan = [
    { label: 'Café da manhã', value: 'breakfast' },
    { label: 'Café da manhã e almoço', value: 'breakfast_lunch' },
    { label: 'Café da manhã e jantar', value: 'breakfast_dinner' },
    { label: 'Almoço', value: 'lunch' },
    { label: 'Jantar', value: 'dinner' },
    { label: 'Almoço e jantar', value: 'lunch_dinner' },
    { label: 'All inclusive', value: 'all_inclusive' },
    { label: 'Pensão completa', value: 'full_board' },
];

const cancelMode = [
    { label: 'Primeira tarifa', value: 'first_fare' },
    { label: 'Porcentagem', value: 'percentage' },
];

const Tarifa = () => {
    const goOut = useRef(false);
    const cancelRuleId = useRef();

    const { accommodation, authentication: jwt } = useSelector((state) => state);

    const history = useHistory();
    const { id: fareId } = useParams();

    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [isLoading, setIsLoading] = useState(true);
    const [fare, setFare] = useState();
    const [cancelRules, setCancelRules] = useState([]);
    const [modalRemove, setModalRemove] = useState(false);
    const [loadingModalDelete, setLoadingModalDelete] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const rules = useMemo(
        () => [
            {
                remaining_days: 0,
                mode: 'first_fare',
                percentage: '0',
            },
        ],
        []
    );

    const initialValues = useMemo(
        () => ({
            name: fare ? fare.name : '',
            max_places: fare ? fare.max_places : 1,
            meal_plan: fare ? fare.meal_plan : 'breakfast',
            voucher_notes: fare ? fare.voucher_notes : '',
            is_active: fare ? fare.is_active : true,
            days_to_cancel: fare ? fare.days_to_cancel : 0,
            cancel_rules: cancelRules.length ? cancelRules : rules,
        }),
        [cancelRules, fare, rules]
    );

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Obrigatório'),
        max_places: Yup.number().required('Obrigatório'),
        meal_plan: Yup.string().required('Obrigatório'),
        voucher_notes: Yup.string().required('Obrigatório'),
        days_to_cancel: Yup.number().required('Obrigatório'),
        cancel_rules: Yup.array().of(
            Yup.object().shape({
                remaining_days: Yup.string().required('Obrigatório'),
                mode: Yup.string().required('Obrigatório'),
                percentage: Yup.string(),
            })
        ),
    });

    const validateValuesEquality = useCallback(
        async (values) => {
            const initialValuesCopy = { ...initialValues };
            const valuesCopy = { ...values };
            const initialRules = new Array(initialValuesCopy.cancel_rules);
            const valueCancelRules = new Array(valuesCopy.cancel_rules);

            delete initialValuesCopy.cancel_rules;
            delete valuesCopy.cancel_rules;

            return {
                valuesIsEqual: isEqual(initialValuesCopy, valuesCopy),
                rulesIsEqual: isEqual(initialRules, valueCancelRules),
            };
        },
        [initialValues]
    );

    const handleCancelRulesSubmit = useCallback(
        async (values) => {
            const rulesToSave = [];
            const rulesToUpdate = [];

            values.cancel_rules
                .map((rule) => ({
                    ...rule,
                    fare: fareId,
                }))
                .forEach((rule) => (rule.id ? rulesToUpdate.push(rule) : rulesToSave.push(rule)));

            try {
                if (rulesToUpdate.length) {
                    const responseUpdatedRules = await updateCancelPlansAccommodation(
                        accommodation.id,
                        rulesToUpdate,
                        jwt
                    );

                    setCancelRules(responseUpdatedRules);
                }

                if (rulesToSave.length) {
                    const responseNewRule = await saveCancelPlansAccommodation(
                        accommodation.id,
                        fareId,
                        rulesToSave,
                        jwt
                    );

                    setCancelRules([...cancelRules, ...responseNewRule]);
                }
            } catch (error) {
                setAlert({
                    show: true,
                    message: 'Não foi possível salvar regra de cancelamento',
                    type: 'danger',
                });
                setErrorMessage(error);
            }
        },
        [accommodation.id, fareId, jwt, cancelRules]
    );

    const onHandleSubmit = useCallback(
        async (values) => {
            const params = {
                id: accommodation.id,
                accommodation: accommodation.id,
                ...values,
            };

            delete params.cancel_rules;

            try {
                setIsLoading(true);
                if (fareId) {
                    const { valuesIsEqual, rulesIsEqual } = await validateValuesEquality(values);

                    if (!valuesIsEqual) {
                        const updatedFare = await editAccommodationFares(
                            accommodation.id,
                            fareId,
                            params,
                            jwt
                        );
                        setFare(updatedFare);
                    }

                    if (!rulesIsEqual) {
                        await handleCancelRulesSubmit(values);
                    }

                    if (goOut.current) {
                        history.push({
                            pathname: '/tarifas',
                            data: {
                                show: true,
                                message: 'Tipo de tarifa atualizada',
                                type: 'success',
                            },
                        });
                        return;
                    }

                    if (!valuesIsEqual || !rulesIsEqual) {
                        setAlert({
                            show: true,
                            message: 'Tipo de tarifa atualizada com sucesso!',
                            type: 'success',
                        });
                    }
                } else {
                    const newFare = await createAccommodationFares(params, jwt);
                    if (goOut.current) {
                        history.push({
                            pathname: '/tarifas',
                            data: {
                                show: true,
                                message: 'Tipo de tarifa cadastrada',
                                type: 'success',
                            },
                        });
                        return;
                    }
                    history.push(`/tarifa/${newFare.id}/edit`);
                    setAlert({
                        show: true,
                        message: 'Tipo de tarifa cadastrada com sucesso!',
                        type: 'success',
                    });
                }
            } catch (error) {
                setAlert({
                    show: true,
                    message: 'Ocorreu um erro ao cadastrar a tarifa.',
                    type: 'danger',
                });
                setErrorMessage(error);
            }
            setIsLoading(false);
        },
        [accommodation, history, jwt, fareId, handleCancelRulesSubmit, validateValuesEquality]
    );

    const handleCancel = useCallback(() => {
        history.push('/tarifas');
    }, [history]);

    const handleModalRemove = useCallback(
        (ruleId) => {
            cancelRuleId.current = ruleId;
            setModalRemove(!modalRemove);
        },
        [modalRemove]
    );

    const removeCancelRule = useCallback(async () => {
        setLoadingModalDelete(true);
        try {
            await removeCancelPlansAccommodation(
                accommodation.id,
                fareId,
                cancelRuleId.current,
                jwt
            );
            const totalRules = cancelRules.filter((rule) => rule.id !== cancelRuleId.current);
            setCancelRules(totalRules);

            setAlert({
                show: true,
                message: 'Regra de cancelamento excluída com sucesso!',
                type: 'success',
            });
        } catch (error) {
            setAlert({
                show: true,
                message: 'Ocorreu um erro ao remover a regra de cancelamento.',
                type: 'danger',
            });
            setErrorMessage(error);
        }
        setModalRemove(false);
        setLoadingModalDelete(false);
    }, [accommodation.id, fareId, jwt, cancelRules]);

    const removeLocalCancelRule = useCallback(
        (helper, rule, index) =>
            cancelRules.includes(rule) ? handleModalRemove(rule.id) : helper.remove(index),
        [cancelRules, handleModalRemove]
    );

    useEffect(() => {
        const getFare = async () => {
            try {
                const response = await getAccommodationFareById(accommodation.id, fareId, jwt);
                if (!response) {
                    history.push('/tarifas');
                }
                setFare(response);
            } catch (error) {
                setErrorMessage(error);
            }
        };

        const getCancelRules = async () => {
            try {
                const { results } = await getCancelPlansAccommodation(
                    accommodation.id,
                    fareId,
                    jwt
                );
                const cancelRulesList = results.map((rule) => {
                    if (Object.values(rule).some((item) => item === null || item === undefined)) {
                        const property = [Object.entries(rule).find(([, value]) => !value)[0]];

                        return {
                            ...rule,
                            [property]: '',
                        };
                    }

                    return rule;
                });

                setCancelRules(cancelRulesList);
            } catch (error) {
                setErrorMessage(error);
            }
        };

        const getData = async () => {
            if (fareId) {
                await getFare();
                await getCancelRules();
            }
            setIsLoading(false);
        };

        getData();
    }, [fareId, accommodation, jwt, history]);

    const onChangeCancelRules = (e, setFieldValue) => {
        const fieldIndex = e.target.name.split('.').slice(0, 2).join('.');
        setFieldValue(`${fieldIndex}.percentage`, '0');
    };

    const createFieldArray = (values, handleChange, handleBlur, setFieldValue) => (
        <FieldArray
            name="cancel_rules"
            render={(helpers) => (
                <>
                    {values.cancel_rules &&
                        values.cancel_rules.map((rule, idx) => {
                            const key = idx + 1;

                            return (
                                <div className={style.cancelBlock} key={key}>
                                    <div className={style.sectionTitle}>
                                        <Heading level={LEVEL.h6} className={style.header}>
                                            Regra de cancelamento: {key}
                                        </Heading>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xm-12 col-md-12">
                                            <Input
                                                label="Dias restantes"
                                                name={`cancel_rules.${idx}.remaining_days`}
                                                disabled={!fareId}
                                                inputMode="decimal"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                            <Select
                                                label="Modo"
                                                name={`cancel_rules.${idx}.mode`}
                                                quantityOptions={cancelMode}
                                                disabled={!fareId}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    onChangeCancelRules(e, setFieldValue);
                                                }}
                                            />
                                        </div>
                                        <div className="col-xm-6 col-md-6">
                                            {rule.mode === 'percentage' && (
                                                <Input
                                                    label="Valor"
                                                    name={`cancel_rules.${idx}.percentage`}
                                                    disabled={!fareId}
                                                    inputMode="decimal"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {fareId && (
                                        <div className="row justify-content-end">
                                            <Button
                                                name="removeRule"
                                                type={TYPE.link}
                                                onClick={() =>
                                                    removeLocalCancelRule(helpers, rule, idx)
                                                }
                                            >
                                                Remover regra
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    {fareId && (
                        <div className="row">
                            <div className="col-xm-12 col-md-12 d-flex justify-content-end">
                                <Button
                                    name="addRule"
                                    type={LINE_TYPE.secondary}
                                    size={BUTTON_SIZE.normal}
                                    onClick={() => helpers.push(...rules)}
                                    className={style.buttonAddRule}
                                >
                                    Adicionar regra
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        />
    );

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                    onHandleSubmit(values);
                    if (values.cancel_rules.length > 1) {
                        setFieldValue(
                            'cancel_rules',
                            values.cancel_rules.every((rule) => !rule.percentage)
                                ? rules
                                : values.cancel_rules.filter((rule) => rule.percentage)
                        );
                    }
                    setSubmitting(false);
                }}
            >
                {(formProps) => {
                    const {
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm,
                        values,
                        setFieldValue,
                    } = formProps;

                    return (
                        <form onSubmit={handleSubmit}>
                            {isLoading ? (
                                <TarifaShimmer />
                            ) : (
                                <TemplateDefault title="Novo Tipo de Tarifa">
                                    <div className={style.container}>
                                        {alert.show && (
                                            <div className="row mb-3">
                                                <div className="col-xl-12">
                                                    <Alert type={alert.type}>{alert.message}</Alert>
                                                </div>
                                            </div>
                                        )}
                                        {Object.values(errorMessage).length > 0 && (
                                            <div className="row mb-3">
                                                <div className="col-xl-12">
                                                    <ErrorHandler errors={errorMessage} />
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-xl-6 col-md-6">
                                                <SectionBorder>
                                                    <Heading
                                                        level={LEVEL.h6}
                                                        className={style.header}
                                                    >
                                                        Informações da tarifa
                                                    </Heading>
                                                    <div className="row mt-3">
                                                        <div className="col-xm-12 col-md-12">
                                                            <Input
                                                                label="Nome da tarifa"
                                                                name="name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-xm-12 col-md-12">
                                                            <Input
                                                                label="Número de pessoas (PAX)"
                                                                name="max_places"
                                                                inputMode="decimal"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                            <Select
                                                                label="Plano de refeição"
                                                                name="meal_plan"
                                                                quantityOptions={mealPlan}
                                                            />
                                                        </div>
                                                        <div className="col-xm-6 col-md-6">
                                                            <Input
                                                                label="Deadline"
                                                                name="days_to_cancel"
                                                                inputMode="decimal"
                                                            />
                                                            <LineInstruction
                                                                type={MESSAGE_TYPE.italic}
                                                            >
                                                                Dias para cancelar sem a cobrança da
                                                                taxa de cancelamento.
                                                            </LineInstruction>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-xm-12 col-md-12">
                                                            <TextArea
                                                                label="Observação do voucher"
                                                                name="voucher_notes"
                                                                rows={5}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-xm-12 col-md-12">
                                                            <Checkbox name="is_active">
                                                                Esta tarifa está ativa
                                                            </Checkbox>
                                                        </div>
                                                    </div>
                                                </SectionBorder>
                                            </div>

                                            <div className="col-xl-6 col-md-6">
                                                <SectionBorder>
                                                    <Heading
                                                        level={LEVEL.h6}
                                                        className={style.header}
                                                    >
                                                        Regras de cancelamento
                                                    </Heading>
                                                    {createFieldArray(
                                                        values,
                                                        handleChange,
                                                        handleBlur,
                                                        setFieldValue
                                                    )}
                                                </SectionBorder>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className=" col-sm-12  col-md-auto">
                                                <Button
                                                    type={TYPE.link}
                                                    size={BUTTON_SIZE.normal}
                                                    onClick={handleCancel}
                                                    className="button-auto-multi-submit mt-3"
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.normal}
                                                    className="button-auto-multi-submit mt-3"
                                                    onClick={() => {
                                                        goOut.current = true;
                                                        submitForm();
                                                    }}
                                                    disabled={isSubmitting}
                                                >
                                                    Salvar tipo de tarifa e voltar
                                                </Button>
                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.normal}
                                                    className="button-auto-multi-submit mt-3"
                                                    onClick={() => {}}
                                                    typeSubmit
                                                    disabled={isSubmitting}
                                                >
                                                    Salvar tipo de tarifa
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </TemplateDefault>
                            )}
                        </form>
                    );
                }}
            </Formik>
            {modalRemove && (
                <ModalAction
                    showModal={modalRemove}
                    modalName="cancelBooking"
                    title="Deseja realmente deletar esta regra de cancelamento?"
                    type="danger"
                    submitLabel="Confirmar"
                    cancelLabel="Cancelar"
                    onCancel={handleModalRemove}
                    onSubmit={removeCancelRule}
                    onhandleCloseModal={handleModalRemove}
                    closeOnOverlay
                    closeOnEsc
                    isLoading={loadingModalDelete}
                />
            )}
        </>
    );
};

export default Tarifa;
