import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '_atoms/Label';
import LineInstruction from '_molecules/LineInstruction';

import style from '_atoms/Input/index.module.scss';

export const INPUT_STATE = {
    default: style.default,
    success: style.success,
    warning: style.warning,
    danger: style.danger,
};

export const INPUT_SIZE = {
    normal: style.normal,
    small: style.small,
    large: style.large,
};

const Input = React.forwardRef((props, ref) => {
    const [field, meta] = useField(props);
    const { label, size, state, stylename, disabled } = props;

    return (
        <>
            {label && <Label>{label}</Label>}
            <input
                ref={ref}
                className={classNames(
                    style.input,
                    { [style.disabled]: disabled },
                    { [style.danger]: meta.touched && meta.error },
                    size,
                    state,
                    stylename
                )}
                type="text"
                {...field}
                {...props}
            />
            {meta.touched && meta.error && (
                <LineInstruction className={style.errorMessage}>{meta.error}</LineInstruction>
            )}
        </>
    );
});

Input.propTypes = {
    label: PropTypes.string,
    state: PropTypes.string,
    size: PropTypes.string,
    stylename: PropTypes.string,
    disabled: PropTypes.bool,
};

Input.defaultProps = {
    label: '',
    stylename: '',
    state: INPUT_STATE.default,
    size: INPUT_SIZE.normal,
    disabled: false,
};

export default Input;
