import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import TemplateDefault from '_templates/TemplateDefault';
import CardListUser from '_molecules/CardListUser';
import CardListUserShimmer from '_molecules/CardListUser/shimmer';
import ModalAction from '_organisms/ModalAction';
import ModalNewUser from '_organisms/ModalNewUser';
import Button, { LINE_TYPE, BUTTON_SIZE } from '_atoms/Button';
import Alert from '_molecules/Alert';
import { getUsers, deleteUser } from '_services/requestSession';

import style from '_pages/usuarios/index.module.scss';

const Usuarios = () => {
    const { authentication } = useSelector((state) => state);

    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
    const [showModalNewUser, setShowModalNewUser] = useState(false);
    const [users, setUsers] = useState([]);
    const [userToDelete, setUserToDelete] = useState();

    const onToggleModal = (id) => {
        setShowModalDeleteUser(!showModalDeleteUser);
        setUserToDelete(id);
    };

    const toggleModalNewUsewr = () => {
        setShowModalNewUser(!showModalNewUser);
    };

    const onHandleSubmit = (user) => {
        toggleModalNewUsewr();
        setAlert({ show: true, message: 'Usuário convidado com sucesso!', type: 'success' });
        setUsers([user, ...users]);
        document.querySelector('body').scrollTo({
            top: 0,
            left: 0,
        });
    };

    const onDelete = async () => {
        setShowModalDeleteUser(false);
        try {
            const jwt = authentication;
            await deleteUser(userToDelete, jwt);
            setUsers((stateUsers) => stateUsers.filter((user) => user.id !== userToDelete));
            setAlert({ show: true, message: ' Usuário removido!', type: 'success' });
        } catch (err) {
            setAlert({ show: true, message: ' Usuário não pode ser removido!', type: 'danger' });
        }
    };

    useEffect(() => {
        const getUser = async () => {
            const jwt = authentication;
            const response = await getUsers(jwt);
            setUsers(response.results);
        };

        getUser();
    }, [authentication]);

    return (
        <TemplateDefault
            title="Equipe"
            subtitle="Conceda os acessos às pessoas que administram a conta"
        >
            <div className={style.blockList}>
                {alert.show && <Alert type={alert.type}>{alert.message}</Alert>}
                {users.length ? (
                    users.map((user) => (
                        <CardListUser key={user.id} handleNext={onToggleModal} user={user} />
                    ))
                ) : (
                    <CardListUserShimmer />
                )}
                <div className="container">
                    <div className={classNames('row justify-content-end', style.blockButton)}>
                        <Button
                            type={LINE_TYPE.secondary}
                            size={BUTTON_SIZE.small}
                            onClick={toggleModalNewUsewr}
                        >
                            Convidar usuário
                        </Button>
                    </div>
                </div>
            </div>

            {showModalDeleteUser && (
                <ModalAction
                    showModal={showModalDeleteUser}
                    modalName="deleteUser"
                    title="Deseja excluir este usuário?"
                    type="danger"
                    submitLabel="Confirmar"
                    cancelLabel="Cancelar"
                    onCancel={onToggleModal}
                    onSubmit={onDelete}
                    onhandleCloseModal={onToggleModal}
                    closeOnOverlay
                    closeOnEsc
                />
            )}

            {showModalNewUser && (
                <ModalNewUser
                    showModal={showModalNewUser}
                    onCancel={toggleModalNewUsewr}
                    onSubmit={onHandleSubmit}
                    onhandleCloseModal={toggleModalNewUsewr}
                />
            )}
        </TemplateDefault>
    );
};

export default Usuarios;
