import React from 'react';
import PropTypes from 'prop-types';

import style from '_atoms/FilterBar/index.module.scss';

const FilterBar = ({ children }) => <div className={style.filterContainer}>{children}</div>;

FilterBar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object])
        .isRequired,
};
export default FilterBar;
