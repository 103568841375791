import {
    SET_CALENDAR_ROOMS,
    SET_CALENDAR_AVAILABILITY_ROOMS,
    SET_CALENDAR_VARIANTS_ROOMS,
    CLEAN_CALENDAR,
} from '_redux/types';

const setCalendarRooms = (room) => (dispatch) => {
    dispatch({ type: SET_CALENDAR_ROOMS, payload: room });
};

const setCalendarAvailabilityRooms = (roomAvailability) => (dispatch) => {
    dispatch({ type: SET_CALENDAR_AVAILABILITY_ROOMS, payload: roomAvailability });
};

const setCalendarVariantsRooms = (roomVariants) => (dispatch) => {
    dispatch({ type: SET_CALENDAR_VARIANTS_ROOMS, payload: roomVariants });
};

const cleanCalendar = () => (dispatch) => {
    dispatch({ type: CLEAN_CALENDAR });
};

export default {
    setCalendarRooms,
    setCalendarAvailabilityRooms,
    setCalendarVariantsRooms,
    cleanCalendar,
};
