import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Checkbox/index.module.scss';

export const CHECKBOX_SIZE = {
    normal: style.normal,
    small: style.small,
    large: style.large,
};

const Checkbox = ({ id, children, className, size, value: val, title, ...props }) => {
    const value = val ? val.toString() : val;
    const [field, meta] = useField({ ...props, value, type: 'checkbox' });
    const classChecked = field.checked ? style.inputChecked : style.inputNoChecked;

    return (
        <label
            title={title}
            htmlFor={id}
            className={classNames(
                style.label,
                size,
                className,
                { [style.disabled]: field.disabled },
                { [style.danger]: meta.touched && meta.error }
            )}
        >
            <div className={classNames(style.blockCheckbox)}>
                <input type="checkbox" className={style.inputCheckbox} {...field} {...props} />
                <div className={classNames(style.wrapperInput, classChecked)}>
                    <svg viewBox="0 0 24 24" className={classNames(style.svgArrow)}>
                        <polyline points="20 6 9 17 4 12" />
                    </svg>
                </div>
            </div>
            <span className={style.labelText}>{children}</span>
        </label>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Checkbox.defaultProps = {
    id: undefined,
    className: undefined,
    title: '',
    size: CHECKBOX_SIZE.normal,
    children: undefined,
    value: undefined,
};

export default Checkbox;
