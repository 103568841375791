import React, { useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';

const CheckBoxTable = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
    );
});

CheckBoxTable.propTypes = {
    indeterminate: PropTypes.bool.isRequired,
};

CheckBoxTable.defaultProps = {};

export default CheckBoxTable;
