import React from 'react';
import PropTypes from 'prop-types';
import ArrowIcon, { TYPE, COLOR } from '_atoms/ArrowIcon';
import CalendarSubtitle from '_atoms/CalendarSubtitle';
import { MdRefresh } from 'react-icons/md';

import style from '_molecules/CalendarFilter/index.module.scss';

const CalendarFilter = ({ currentMonth, onChangeMonth }) => (
    <div className={style.blockFilter}>
        <div className={style.blockDate}>
            <button
                className={style.button}
                type="button"
                onClick={() => onChangeMonth('subtract')}
            >
                <ArrowIcon type={TYPE.arrowLeft} color={COLOR.secondary} withBox />
            </button>
            <p className={style.date}>{currentMonth}</p>
            <button className={style.button} type="button" onClick={() => onChangeMonth('add')}>
                <ArrowIcon type={TYPE.arrowRight} color={COLOR.secondary} withBox />
            </button>
            <button className={style.blockRefresh} onClick={() => onChangeMonth()}>
                <MdRefresh size={25} />
            </button>
        </div>
        <CalendarSubtitle />
    </div>
);

CalendarFilter.propTypes = {
    currentMonth: PropTypes.string,
    onChangeMonth: PropTypes.func.isRequired,
};

CalendarFilter.defaultProps = {
    currentMonth: undefined,
};

export default CalendarFilter;
