import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import Dashboard from '_templates/Dashboard';
import Input from '_atoms/Input/Input';
import Button, { TYPE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import CardUserCompanion from '_molecules/CardUserCompanion';
import CardUserCompanionShimmer from '_molecules/CardUserCompanion/shimmer';
import actions from '_redux/actions';
import style from '_pages/escolher-hotel/index.module.scss';
import { useCacheRequest } from '_hooks/useSwr';
import { getCookie, removeCookie } from '_utils/cookie';

const EscolherHotel = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [accommodationsList, setAccommodationsList] = useState([]);
    const userName = useSelector((state) => state.profile.first_name);
    const { authentication } = useSelector((state) => state);
    const [globalParams, setGlobalParams] = useState({
        ordering: 'trade_mark',
        search: '',
        limit: 30,
    });

    const accommodations = useCacheRequest({
        method: 'GET',
        url: `/accommodations/`,
        headers: {
            Authorization: `JWT ${authentication}`,
            'Content-Type': 'application/json',
        },
        params: globalParams,
    });

    const onHandleNext = (hotel) => {
        dispatch(actions.setAccommodation(hotel));
        const destinationPath = getCookie('destinationPath');
        removeCookie('destinationPath');
        return destinationPath ? history.push(destinationPath) : history.push('/dashboard');
    };

    const getAccommodation = useCallback(async () => {
        setLoading(true);
        try {
            if (accommodations.data) {
                setLoading(false);
                setAccommodationsList(accommodations.data.results);
            }
        } catch (error) {
            setAccommodationsList([]);
        }
    }, [accommodations]);

    const onHandleSearch = useCallback(async ({ search }) => {
        setLoading(true);
        setAlert(false);
        const params = {
            ordering: 'trade_mark',
            search,
            limit: 30,
        };
        setGlobalParams(params);
    }, []);

    useEffect(() => {
        getAccommodation();
    }, [getAccommodation, accommodations]);

    return (
        <Dashboard>
            <div className={style.blockTitle}>
                <Heading level={LEVEL.h1} className={style.h1}>
                    Olá, {userName}
                </Heading>
                <Heading level={LEVEL.h5}>Qual hotel você deseja gerenciar?</Heading>
            </div>
            <div className={style.blockSearch}>
                <Formik
                    initialValues={{ search: '' }}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        onHandleSearch(values);
                        setSubmitting(false);
                    }}
                >
                    {(formProps) => {
                        const { isSubmitting, handleSubmit, submitForm } = formProps;

                        return (
                            <form onSubmit={handleSubmit}>
                                <div className={style.searchFields}>
                                    <Input placeholder="Nome do hotel" name="search" />
                                    <Button
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                        type={TYPE.secondary}
                                    >
                                        Buscar
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
            <div className={style.blockCards}>
                {loading ? (
                    <CardUserCompanionShimmer />
                ) : (
                    <>
                        {accommodationsList.length && !alert ? (
                            accommodationsList.map((hotel) => (
                                <CardUserCompanion
                                    key={hotel.id}
                                    handleNext={() => onHandleNext(hotel)}
                                    hotel={hotel}
                                />
                            ))
                        ) : (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type="message">Nenhum hotel foi encontrado.</Alert>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Dashboard>
    );
};

export default EscolherHotel;
