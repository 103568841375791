import React from 'react';
import InputShimmer from '_atoms/Input/shimmer';
import LabelShimmer from '_atoms/Label/shimmer';
import SectionBorder from '_molecules/SectionBorder';
import style from '_pages/tarifa-dia/shimmer.module.scss';

const TarifaDiaShimmerVariants = () => (
    <SectionBorder>
        <div className={style.cancelBlock}>
            <div className="row mt-3">
                <div className="col-xm-12 col-md-12">
                    <LabelShimmer />
                    <InputShimmer />
                </div>
            </div>
            <div className={style.blockCompanion} />
        </div>
    </SectionBorder>
);

export default TarifaDiaShimmerVariants;
