import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import style from '_atoms/RadioButton/index.module.scss';

export const RADIOBUTTON_SIZE = {
    normal: style.normal,
    small: style.small,
    large: style.large,
};

export const RADIOBUTTON_STATE = {
    default: style.default,
    danger: style.danger,
};

const RadioButton = ({ id, children, className, size, state, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'radio' });
    const classChecked = field.checked ? style.inputChecked : style.inputNoChecked;

    return (
        <>
            <label
                htmlFor={id}
                className={classNames(
                    style.label,
                    size,
                    { [style.disabled]: field.disabled },
                    className
                )}
            >
                <div className={style.blockRadioButton}>
                    <input type="radio" className={style.radiobutton} {...field} {...props} />
                    <div
                        className={classNames(style.wrapperInput, classChecked, state, {
                            [style.danger]: meta.touched && meta.error,
                        })}
                    >
                        <svg viewBox="0 0 24 24" className={classNames(style.svgArrow)}>
                            <circle cx="12" cy="12" r="3" fill="#fff" />
                        </svg>
                    </div>
                </div>
                <span className={style.labelText}>{children}</span>
            </label>
        </>
    );
};

RadioButton.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
    state: PropTypes.string,
};

RadioButton.defaultProps = {
    id: undefined,
    children: undefined,
    className: undefined,
    size: RADIOBUTTON_SIZE.normal,
    state: RADIOBUTTON_STATE.default,
};

export default RadioButton;
