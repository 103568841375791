import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Route from '_routes/route';
import Index from '_pages/index';
import Entrar from '_pages/entrar';
import Users from '_pages/users';
import User from '_pages/users/user';
import Hotel from '_pages/hotel';
import Disponibilidade from '_pages/disponibilidade';
import Disponibilidades from '_pages/disponibilidades';
import EscolherHotel from '_pages/escolher-hotel';
import EsqueciSenha from '_pages/esqueci-senha';
import Erro from '_pages/erro';
import MeuHotel from '_pages/meu-hotel';
import DadosCadastrais from '_pages/meu-hotel/dados-cadastrais';
import Contatos from '_pages/meu-hotel/contatos';
import PoliticasECondicoes from '_pages/meu-hotel/politicas-e-condicoes';
import Informacoes from '_pages/meu-hotel/informacoes';
import Galeria from '_pages/meu-hotel/galeria';
import DistanciaDaAcomodacao from '_pages/meu-hotel/distancias-da-acomodacao';
import DistanciaDaAcomodacaoCadastro from '_pages/meu-hotel/cadastro-distancia-da-acomodacao';
import Condicao from '_pages/meu-hotel/condicao';
import Quarto from '_pages/quarto';
import Quartos from '_pages/quartos';
import Variante from '_pages/quarto/cadastro-variante';
import Deadline from '_pages/quarto/cadastro-deadline';
import MinimoNoites from '_pages/quarto/cadastro-minimo-noites';
import RecuperacaoDeSenha from '_pages/recuperacao-de-senha';
import Reserva from '_pages/reserva';
import Reservas from '_pages/reservas';
import Tarifa from '_pages/tarifa';
import TarifaDia from '_pages/tarifa-dia';
import Tarifas from '_pages/tarifas';
import TarifasDia from '_pages/tarifas-dia';
import Usuarios from '_pages/usuarios';
import CadastrarHotel from '_pages/cadastrar-hotel';
import RoomingList from '_pages/rooming-list';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Index} />
                <Route exact path="/escolher-hotel" component={EscolherHotel} />
                <Route exact path="/esqueci-senha" component={EsqueciSenha} isPrivate={false} />
                <Route exact path="/cadastrar-hotel" component={CadastrarHotel} />
                <Route exact path="/dashboard" component={Index} />
                <Route exact path="/disponibilidade/novo" component={Disponibilidade} />
                <Route exact path="/disponibilidades" component={Disponibilidades} />
                <Route exact path="/entrar" component={Entrar} isPrivate={false} />
                <Route exact path="/hotel/:city/:state/:uniqueId" component={Hotel} />
                <Route exact path="/meu-hotel" component={MeuHotel} />
                <Route exact path="/meu-hotel/dados-cadastrais" component={DadosCadastrais} />
                <Route exact path="/meu-hotel/contatos" component={Contatos} />
                <Route exact path="/meu-hotel/galeria" component={Galeria} />
                <Route
                    exact
                    path="/meu-hotel/politicas-e-condicoes"
                    component={PoliticasECondicoes}
                />
                <Route exact path="/meu-hotel/informacoes" component={Informacoes} />
                <Route
                    exact
                    path="/meu-hotel/distancias-da-acomodacao"
                    component={DistanciaDaAcomodacao}
                />
                <Route
                    exact
                    path="/meu-hotel/distancia-da-acomodacao/novo"
                    component={DistanciaDaAcomodacaoCadastro}
                />
                <Route exact path="/meu-hotel/condicao/novo" component={Condicao} />
                <Route exact path="/quarto/novo" component={Quarto} />
                <Route exact path="/quarto/:id/informacoes-gerais" component={Quarto} />
                <Route exact path="/quarto/:id/caracteristicas" component={Quarto} />
                <Route exact path="/quarto/:id/galeria" component={Quarto} />
                <Route exact path="/quarto/:id/deadlines" component={Quarto} />
                <Route exact path="/quarto/:id/deadline/novo" component={Deadline} />
                <Route exact path="/quarto/:id/minimo-noites" component={Quarto} />
                <Route exact path="/quarto/:id/minimo-noite/novo" component={MinimoNoites} />
                <Route exact path="/quarto/:id/variantes" component={Quarto} />
                <Route exact path="/quarto/:id/variante/novo" component={Variante} />
                <Route exact path="/quartos" component={Quartos} />
                <Route
                    exact
                    path="/recuperacao-de-senha/:uid/:token"
                    component={RecuperacaoDeSenha}
                    isPrivate={false}
                />
                <Route exact path="/reserva/:id" component={Reserva} />
                <Route exact path="/reservas" component={Reservas} />
                <Route exact path="/rooming-list" component={RoomingList} />
                <Route exact path="/tarifa/novo" component={Tarifa} />
                <Route exact path="/tarifa/:id/edit" component={Tarifa} />
                <Route exact path="/tarifa-dia/novo" component={TarifaDia} />
                <Route exact path="/tarifas" component={Tarifas} />
                <Route exact path="/tarifas-dia" component={TarifasDia} />
                <Route exact path="/usuarios" component={Usuarios} />
                <Route exact path="/users/:userId" component={User} />
                <Route exact path="/users" component={Users} />
                <Route component={Erro} />
            </Switch>
        </Router>
    );
}
