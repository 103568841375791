import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_molecules/SectionBorder/index.module.scss';

export const BGCOLOR = {
    white: style.white,
    gray: style.gray,
};

const SectionBorder = ({ children, backgroundColor, className }) => (
    <div className={classNames(style.container, backgroundColor, className)}>{children}</div>
);

SectionBorder.propTypes = {
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
};

SectionBorder.defaultProps = {
    children: undefined,
    backgroundColor: BGCOLOR.white,
    className: undefined,
};
export default SectionBorder;
