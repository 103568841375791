import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Heading, { LEVEL } from '_atoms/Heading';

import style from '_molecules/SectionContent/index.module.scss';

const SectionContent = ({ title, children, border }) => (
    <div className={classNames(style.blockSection, { [style.border]: border })}>
        {title && <Heading level={LEVEL.h2}>{title}</Heading>}
        {children}
    </div>
);

SectionContent.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    border: PropTypes.bool,
};

SectionContent.defaultProps = {
    title: undefined,
    children: '',
    border: false,
};

export default SectionContent;
