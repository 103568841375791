import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import TemplateDefault from '_templates/TemplateDefault';
import Heading, { LEVEL } from '_atoms/Heading';
import BadgeCount from '_atoms/BadgeCount';
import StatusBall, { STATUS } from '_atoms/StatusBall';
import Button, { LINE_TYPE } from '_atoms/Button';
import SectionBorder from '_molecules/SectionBorder';
import Table, { COLUMN_SIZE } from '_molecules/Table';
import Line from '_molecules/Charts/Line';
import ShimmerChart from '_molecules/Charts/ShimmerChart';
import ShimmerCard from '_pages/index/shimmer';
import { createBookingObjectToTable } from '_utils/bookingInitialize';
import { useCacheRequest } from '_hooks/useSwr';
import Alert from '_molecules/Alert';

import style from '_pages/index/index.module.scss';

const renderBadge = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;

    const statusList = {
        Approved: { message: 'Aprovada', status: 'success' },
        Booked: { message: 'Confirmada', status: 'success' },
        Canceled: { message: 'Cancelada', status: 'danger' },
        Refused: { message: 'Reprovada', status: 'danger' },
        Wait: { message: 'Aguardando', status: 'default' },
    };

    if (!statusList[value]) return null;

    return (
        <StatusBall
            className={style.status}
            status={STATUS[statusList[value].status]}
            label={statusList[value].message}
        />
    );
};

const renderLink = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;
    return <Link to={`/reserva/${value}`}>{value}</Link>;
};

const createPresentDateList = (presentMonth, dates) =>
    dates.map((date) => {
        const sameDates = presentMonth.filter((booking) => booking.start_date === date);
        if (sameDates.length) return sameDates.length;

        return 0;
    });

const createPastDateList = (presentMonth, dates) =>
    dates.map((date) => {
        const sameDates = presentMonth.filter(
            (booking) =>
                booking.start_date === moment(date).subtract(1, 'month').format('YYYY-MM-DD')
        );
        if (sameDates.length) return sameDates.length;

        return 0;
    });

const renderDate = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;

    return moment(value).format('DD/MM/YYYY');
};

const Index = () => {
    const [data, setData] = useState([]);
    const [checkInToday, setCheckInToday] = useState();
    const [byRequestWait, setByRequestWait] = useState();
    const [byRequest, setByRequest] = useState();
    const [loading, setLoading] = useState(false);
    const [dataMonth, setDataMonth] = useState([]);
    const [labels, setLabels] = useState([]);
    const [totalCheckinMonth, setTotalCheckinMonth] = useState(0);
    const history = useHistory();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [errorTableData, setErrorTableData] = useState(false);

    const columns = [
        {
            Header: 'Número da reserva',
            accessor: 'id',
            renderColumn: renderLink,
        },
        {
            Header: 'Nome do hóspede',
            accessor: 'guests__name',
        },
        {
            Header: 'Data do checkin',
            accessor: 'start_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Data do checkout',
            accessor: 'end_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Status da reserva',
            accessor: 'status',
            renderColumn: renderBadge,
        },
        {
            Header: 'Status sob-consulta',
            accessor: 'by_request_status',
            renderColumn: renderBadge,
        },
    ];

    const startDateAfter = moment().format('YYYY-MM-DD');
    const startDateBefore = moment().add(30, 'days').format('YYYY-MM-DD');

    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const bookingsToCancel = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/`,
            headers,
            params: {
                ordering: '-created_at',
                limit: 10,
                offset: 0,
                room_variant__room__accommodation__id: accommodation.id,
                cancellation_confirmation: false,
                status: 'Canceled',
                by_request_status: 'Approved',
                start_date_after: startDateAfter,
                start_date_before: startDateBefore,
            },
        },
        null,
        true
    );

    const bookingsByRequest = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/`,
            headers,
            params: {
                ordering: '-created_at',
                limit: 10,
                offset: 0,
                room_variant__room__accommodation__id: accommodation.id,
                by_request: true,
            },
        },
        null,
        false
    );

    const lastBookings = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/`,
            headers,
            params: {
                ordering: '-updated_at',
                limit: 8,
                offset: 0,
                room_variant__room__accommodation__id: accommodation.id,
            },
        },
        null,
        false
    );

    const checkinToday = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/`,
            headers,
            params: {
                start_date_after: moment().format('YYYY-MM-DD'),
                start_date_before: moment().format('YYYY-MM-DD'),
                status: 'Booked',
                by_request_status: 'Approved',
                limit: 10,
                offset: 0,
                room_variant__room__accommodation__id: accommodation.id,
            },
        },
        null,
        false
    );

    const bookingsPresentMonth = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/`,
            headers,
            params: {
                status: 'Booked',
                by_request_status: 'Approved',
                limit: 9999,
                offset: 0,
                room_variant__room__accommodation__id: accommodation.id,
                start_date_after: moment().startOf('month').format('YYYY-MM-DD'),
                start_date_before: moment().endOf('month').format('YYYY-MM-DD'),
            },
        },
        null,
        false
    );

    const bookingsPastMonth = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/`,
            headers,
            params: {
                status: 'Booked',
                by_request_status: 'Approved',
                limit: 9999,
                offset: 0,
                room_variant__room__accommodation__id: accommodation.id,
                start_date_after: moment()
                    .startOf('month')
                    .subtract(1, 'month')
                    .format('YYYY-MM-DD'),
                start_date_before: moment()
                    .startOf('month')
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
            },
        },
        null,
        false
    );

    const getBookings = useCallback(async () => {
        try {
            setErrorTableData(false);
            setLoading(true);
            if (lastBookings.data) {
                const bookings = createBookingObjectToTable(lastBookings.data.results);
                setData(bookings);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setErrorTableData(true);
            console.error(error);
        }
    }, [lastBookings.data]);

    const getCheckInToday = useCallback(async () => {
        try {
            if (checkinToday.data) {
                const bookings = createBookingObjectToTable(checkinToday.data.results);
                setCheckInToday(bookings);
            }
        } catch (error) {
            setCheckInToday([]);
        }
    }, [checkinToday.data]);

    const createDataToChart = useCallback((presentMonth, pastMonth) => {
        const dates = [];
        let start = moment().startOf('month');

        while (start <= moment().endOf('month')) {
            dates.push(moment(start).format('YYYY-MM-DD'));
            start = moment(start).add(1, 'days');
        }

        const presentMonthData = createPresentDateList(presentMonth, dates);
        const pastMonthData = createPastDateList(pastMonth, dates);

        setTotalCheckinMonth(presentMonth.length);
        setLabels(dates);
        setDataMonth([
            {
                label: 'Mês atual',
                data: presentMonthData,
                borderColor: 'rgba(54, 162, 235, 1)',
            },
            {
                label: 'Mês passado',
                data: pastMonthData,
                borderColor: 'rgba(139, 156, 166, 1)',
                borderDash: [2],
            },
        ]);
    }, []);

    const requestChartMonths = useCallback(async () => {
        try {
            if (bookingsPresentMonth.data && bookingsPastMonth.data) {
                createDataToChart(
                    bookingsPresentMonth.data.results,
                    bookingsPastMonth.data.results
                );
            }
        } catch (err) {
            console.log(err);
        }
    }, [bookingsPastMonth.data, createDataToChart, bookingsPresentMonth.data]);

    const getByRequest = useCallback(async () => {
        try {
            if (bookingsByRequest.data) {
                const bookings = createBookingObjectToTable(bookingsByRequest.data.results);
                const today = moment().format('YYYY-MM-DD');
                setByRequest(
                    bookings.filter(
                        (booking) =>
                            booking.by_request_status !== 'Wait' ||
                            moment(booking.start_date).isBefore(today) ||
                            booking.status === 'Canceled'
                    )
                );

                setByRequestWait(
                    bookings.filter(
                        (booking) =>
                            booking.by_request_status === 'Wait' &&
                            booking.status !== 'Canceled' &&
                            moment(booking.start_date).isSameOrAfter(today)
                    )
                );
            }
        } catch (error) {
            setByRequest([]);
            setByRequestWait([]);
        }
    }, [bookingsByRequest.data]);

    useEffect(() => {
        getBookings();
    }, [getBookings]);

    useEffect(() => {
        getCheckInToday();
        getByRequest();
        requestChartMonths();
    }, [getCheckInToday, getByRequest, requestChartMonths]);

    return (
        <TemplateDefault title={accommodation.tradeMark}>
            <div className={style.content} data-testid="dashboard">
                {bookingsToCancel.data?.count > 0 && (
                    <div className={style.alertContainer}>
                        <Alert type="warning">
                            <>
                                Você tem {bookingsToCancel.data.count} reservas canceladas.{' '}
                                <Link
                                    to={`/reservas?status=Canceled&cancellation_confirmation=false&start_date_after=${startDateAfter}&start_date_before=${startDateBefore}&by_request_status_to_review=Approved`}
                                    className={style.alertLink}
                                >
                                    Clique para revisar.
                                </Link>
                            </>
                        </Alert>
                    </div>
                )}
                <div className="row">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <SectionBorder
                            className={classNames(style.contentList, style.chartContent)}
                        >
                            <div className={style.heading}>
                                <Heading className={style.title} level={LEVEL.h4}>
                                    Reservas neste mês
                                </Heading>
                                <BadgeCount message={totalCheckinMonth} className={style.badge} />
                            </div>
                            {!dataMonth.length ? (
                                <ShimmerChart />
                            ) : (
                                <Line labels={labels} data={dataMonth} />
                            )}
                        </SectionBorder>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-4">
                        <SectionBorder className={classNames(style.contentList, style.checkin)}>
                            <div className={style.heading}>
                                <Heading level={LEVEL.h4} className={style.title}>
                                    Check-in hoje
                                </Heading>
                                <BadgeCount message={checkInToday ? checkInToday.length : 0} />
                            </div>
                            <div className={style.body}>
                                <ul className={style.listCard}>
                                    {!checkInToday ? (
                                        <ShimmerCard />
                                    ) : (
                                        <>
                                            {checkInToday.length === 0 ? (
                                                <li className={style.listCardItem}>
                                                    <p>Nenhuma reserva encontrada</p>
                                                </li>
                                            ) : (
                                                checkInToday.map((booking) => (
                                                    <li
                                                        className={style.listCardItem}
                                                        key={booking.id}
                                                    >
                                                        <Link to={`/reserva/${booking.id}`}>
                                                            <div className={style.meta}>
                                                                <span className={style.info}>
                                                                    #{booking.id}
                                                                </span>
                                                            </div>
                                                            <p>{booking.guests__name}</p>
                                                        </Link>
                                                    </li>
                                                ))
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div className={style.footer}>
                                <Link
                                    to={`/reservas?start_date_after=${moment().format(
                                        'YYYY-MM-DD'
                                    )}&start_date_before=${moment().format('YYYY-MM-DD')}`}
                                    className={style.linkAllItems}
                                >
                                    Ver todas
                                </Link>
                            </div>
                        </SectionBorder>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-4">
                        <SectionBorder className={classNames(style.contentList, style.byRequest)}>
                            <div className={style.heading}>
                                <Heading level={LEVEL.h4} className={style.title}>
                                    Sob consulta
                                </Heading>
                                <BadgeCount
                                    message={
                                        byRequestWait && byRequest
                                            ? byRequestWait.length + byRequest.length
                                            : 0
                                    }
                                    className={style.badge}
                                />
                            </div>
                            <div className={style.body}>
                                {!byRequestWait ? (
                                    <ShimmerCard />
                                ) : (
                                    <>
                                        <Heading level={LEVEL.h5} className={style.titleSub}>
                                            Pendente
                                        </Heading>
                                        <ul className={style.listCard}>
                                            {byRequestWait.length === 0 ? (
                                                <li className={style.listCardItem}>
                                                    <p>Nenhuma reserva pendente</p>
                                                </li>
                                            ) : (
                                                byRequestWait.map((booking) => (
                                                    <li
                                                        className={style.listCardItem}
                                                        key={booking.id}
                                                    >
                                                        <Link to={`/reserva/${booking.id}`}>
                                                            <div className={style.meta}>
                                                                <span className={style.info}>
                                                                    #{booking.id}
                                                                </span>
                                                                <StatusBall
                                                                    className={style.status}
                                                                    status={STATUS.default}
                                                                    label="Aguardando"
                                                                />
                                                            </div>
                                                            <p>{booking.guests__name}</p>
                                                            <p>
                                                                {moment(booking.start_date).format(
                                                                    'DD/MM/YYYY'
                                                                )}{' '}
                                                                a{' '}
                                                                {moment(booking.end_date).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </p>
                                                        </Link>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </>
                                )}

                                <Heading level={LEVEL.h5} className={style.titleSub}>
                                    Respondido
                                </Heading>
                                <ul className={style.listCard}>
                                    {!byRequest ? (
                                        <ShimmerCard />
                                    ) : (
                                        <>
                                            {byRequest.length === 0 ? (
                                                <li className={style.listCardItem}>
                                                    <p>Nenhuma reserva pendente</p>
                                                </li>
                                            ) : (
                                                byRequest.map((booking) => (
                                                    <li
                                                        className={style.listCardItem}
                                                        key={booking.id}
                                                    >
                                                        <Link to={`/reserva/${booking.id}`}>
                                                            <div className={style.meta}>
                                                                <span className={style.info}>
                                                                    #{booking.id}
                                                                </span>
                                                                <StatusBall
                                                                    className={style.status}
                                                                    status={
                                                                        booking.by_request_status ===
                                                                            'Approved' &&
                                                                        booking.status !==
                                                                            'Canceled'
                                                                            ? STATUS.success
                                                                            : STATUS.danger
                                                                    }
                                                                    label={
                                                                        booking.by_request_status ===
                                                                            'Approved' &&
                                                                        booking.status !==
                                                                            'Canceled'
                                                                            ? 'Aprovada'
                                                                            : 'Cancelada'
                                                                    }
                                                                />
                                                            </div>
                                                            <p>{booking.guests__name}</p>
                                                            <p>
                                                                {moment(booking.start_date).format(
                                                                    'DD/MM/YYYY'
                                                                )}{' '}
                                                                a{' '}
                                                                {moment(booking.end_date).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </p>
                                                        </Link>
                                                    </li>
                                                ))
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div className={style.footer}>
                                <Link
                                    to={`/reservas?by_request_status=Wait&start_date_after=${moment().format(
                                        'YYYY-MM-DD'
                                    )}`}
                                    className={style.linkAllItems}
                                >
                                    Ver pendentes
                                </Link>
                                <Link to="/reservas?by_request=true" className={style.linkAllItems}>
                                    Ver todas
                                </Link>
                            </div>
                        </SectionBorder>
                    </div>
                </div>
                <div className="row mt-lg-4">
                    <div className="col-lg-12">
                        <SectionBorder>
                            <div className={style.heading}>
                                <Heading level={LEVEL.h4} className={style.title}>
                                    Últimas atualizações de reservas
                                </Heading>
                                <Button
                                    type={LINE_TYPE.secondary}
                                    onClick={() => history.push('/reservas')}
                                >
                                    Ver todas
                                </Button>
                            </div>
                            <Table
                                columns={columns}
                                data={data}
                                fetchData={() => {}}
                                loading={loading}
                                columnSize={COLUMN_SIZE.small}
                                columnClassName={style.linkTable}
                                errorLoadingData={errorTableData}
                                disableSortBy
                            />
                        </SectionBorder>
                    </div>
                </div>
            </div>
        </TemplateDefault>
    );
};

export default Index;
