import React from 'react';

export const renderOptions = (options) =>
    options.map((item) => (
        <option key={item.value} value={item.value}>
            {item.label}
        </option>
    ));

export const renderOptionsWithGroups = (options) =>
    Object.keys(options).map((group) => (
        <optgroup key={group} label={group}>
            {renderOptions(options[group])}
        </optgroup>
    ));

export const generateArrayToRender = (maxValue) => {
    const array = [];
    let i = 0;

    for (; i <= maxValue; ) {
        array.push({ label: i, value: i });
        i += 1;
    }
    return array;
};
