import React from 'react';
import ReactDOM from 'react-dom';
import '_styles/style.scss';
import Routes from '_routes/routes';
import { Provider } from 'react-redux';
import store from '_redux/';
import * as Sentry from '@sentry/react';
import '_utils/sentry';

const setUserInSentry = () => {
    const user = store.getState().profile;
    Sentry.configureScope((scope) => {
        scope.setUser({ ...user });
    });
};

ReactDOM.render(
    <Provider store={store}>
        {setUserInSentry()}
        <React.StrictMode>
            <Routes />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
