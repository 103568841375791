import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import Heading, { LEVEL } from '_atoms/Heading';
import Button, { LINE_TYPE } from '_atoms/Button';
import { getAccommodationRooms } from '_services/requestRooms';
import TemplateDefault from '_templates/TemplateDefault';
import QuartosShimmer from '_pages/quartos/shimmer';

import style from '_pages/quartos/index.module.scss';

const Quartos = () => {
    const { accommodation, authentication } = useSelector((state) => state);
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const getRooms = async () => {
            try {
                const jwt = authentication;
                const { results } = await getAccommodationRooms(accommodation.id, jwt);
                const roomsResult = results.map((room) => ({
                    ...room,
                    coverImg: room.images.find((image) => image.is_cover),
                }));

                setRooms(roomsResult);
                setIsLoading(false);
            } catch {
                setRooms([]);
            }
        };

        getRooms();
    }, [accommodation, authentication]);
    return (
        <TemplateDefault title="Quartos">
            <div className={style.content}>
                {isLoading ? (
                    <>
                        <QuartosShimmer />
                        <QuartosShimmer />
                        <QuartosShimmer />
                        <QuartosShimmer />
                    </>
                ) : (
                    <>
                        {rooms.map((room) => (
                            <div className={style.bedroom} key={room.id}>
                                <div className={style.bedroomImage}>
                                    <Link to={`/quarto/${room.id}/informacoes-gerais`}>
                                        {!room.coverImg ? (
                                            <div className={style.defaultImage} />
                                        ) : (
                                            <img src={room.coverImg.url} alt={room.name} />
                                        )}
                                    </Link>
                                </div>
                                <Heading level={LEVEL.h4} className={style.title}>
                                    <Link to={`/quarto/${room.id}/informacoes-gerais`}>
                                        {room.name}
                                    </Link>
                                </Heading>
                            </div>
                        ))}
                        <div className={classNames(style.bedroom, style.bedroomAddButton)}>
                            <Button
                                onClick={() => {
                                    history.push('/quarto/novo');
                                }}
                                type={LINE_TYPE.secondary}
                            >
                                Adicionar
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </TemplateDefault>
    );
};

export default Quartos;
