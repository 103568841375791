import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Label from '_atoms/Label';
import LineInstruction from '_molecules/LineInstruction';
import { renderOptions } from '_utils/render-select-options';
import { INPUT_STATE as SELECT_STATE, INPUT_SIZE as SELECT_SIZE } from '_atoms/Input';

import inputStyles from '_atoms/Input/index.module.scss';
import style from '_atoms/Select/index.module.scss';

export const STATE = SELECT_STATE;

export const SIZE = SELECT_SIZE;

const Select = ({ label, className, size, state, disabled, quantityOptions, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            {label && <Label>{label}</Label>}
            <div className={style.container}>
                <select
                    className={classNames(
                        inputStyles.input,
                        style.select,
                        { [inputStyles.disabled]: disabled },
                        { [inputStyles.danger]: meta.touched && meta.error },
                        size,
                        state,
                        className
                    )}
                    disabled={disabled}
                    {...field}
                    {...props}
                >
                    {renderOptions(quantityOptions)}
                </select>
            </div>
            {meta.touched && meta.error && (
                <LineInstruction className={inputStyles.errorMessage}>{meta.error}</LineInstruction>
            )}
        </>
    );
};

Select.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    state: PropTypes.string,
    quantityOptions: PropTypes.arrayOf(PropTypes.object),
};

Select.defaultProps = {
    label: '',
    className: undefined,
    name: undefined,
    size: SELECT_SIZE.normal,
    state: SELECT_STATE.default,
    disabled: false,
    quantityOptions: [],
};

export default Select;
