import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MdLockOutline } from 'react-icons/md';

import style from '_atoms/CalendarDay/index.module.scss';

const CalendarDay = ({ availability, room, handleShowModal }) => {
    const blockedDay = () =>
        availability.booked + availability.free + availability.booked_by_request;

    return (
        <div className={style.blockDays}>
            <button
                type="button"
                className={style.blockStatus}
                onClick={() => blockedDay() && handleShowModal(availability, room)}
            >
                {!!availability.booked_by_request && (
                    <div
                        className={classNames(style.status, style.byRequest)}
                        style={{ flex: availability.booked_by_request }}
                    >
                        <p className={style.p}>{availability.booked_by_request}</p>
                    </div>
                )}

                {!!availability.booked && (
                    <div
                        className={classNames(style.status, style.booked)}
                        style={{ flex: availability.booked }}
                    >
                        <p className={style.p}>{availability.booked}</p>
                    </div>
                )}

                {!!availability.free && (
                    <div
                        className={classNames(style.status, style.free)}
                        style={{ flex: availability.free }}
                    >
                        <p className={style.p}>{availability.free}</p>
                    </div>
                )}

                {!blockedDay() && (
                    <div className={classNames(style.status, style.blocked)}>
                        <MdLockOutline size={22} />
                    </div>
                )}
            </button>
        </div>
    );
};

CalendarDay.propTypes = {
    availability: PropTypes.shape({
        booked: PropTypes.number,
        free: PropTypes.number,
        booked_by_request: PropTypes.number,
        date: PropTypes.string,
    }),
    room: PropTypes.shape({
        name: PropTypes.string,
    }),
    handleShowModal: PropTypes.func.isRequired,
};

CalendarDay.defaultProps = {
    availability: undefined,
    room: undefined,
};

export default CalendarDay;
