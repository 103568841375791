import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from '_molecules/Table/shimmer.module.scss';

export const COLUMN_SIZE = {
    small: style.small,
    normal: style.normal,
    large: style.large,
};

const TableShimmer = ({ columnSize, shimmerSize }) => {
    const createRows = () => {
        const total = [];
        for (let i = 0; i < shimmerSize; i += 1) {
            total.push(
                <div className={style.row} key={i}>
                    <div className={classNames(style.loading, columnSize)} />
                </div>
            );
        }

        return total;
    };
    return <>{createRows()}</>;
};

TableShimmer.propTypes = {
    shimmerSize: PropTypes.number.isRequired,
    columnSize: PropTypes.string,
};

TableShimmer.defaultProps = {
    columnSize: COLUMN_SIZE.normal,
};

export default TableShimmer;
