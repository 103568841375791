import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Heading, { LEVEL } from '_atoms/Heading';

import style from '_molecules/MyPersonalCard/index.module.scss';

const MyPersonalCard = ({ icon, name, description, href }) => (
    <Link to={`/meu-hotel/${href}`} className={style.card}>
        <img className={style.icon} src={require(`_images/${icon}`).default} alt={name} />
        <div className={style.blockText}>
            <Heading className={style.cardTitle} level={LEVEL.h3}>
                {name}
            </Heading>
            <p className={style.description}>{description}</p>
        </div>
    </Link>
);

MyPersonalCard.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    href: PropTypes.string,
};

MyPersonalCard.defaultProps = {
    icon: '',
    name: '',
    description: '',
    href: '',
};

export default MyPersonalCard;
