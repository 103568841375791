import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import Input from '_atoms/Input/Input';
import ButtonShimmer from '_atoms/Button/shimmer';
import Alert from '_molecules/Alert';
import { DATE_MASK } from '_helpers/inputMasks';
import { removeRoomMinNights, getRoomMinNights, updateRoomMinNights } from '_services/requestRooms';
import ModalAction from '_organisms/ModalAction';
import CollapsibleContent from '_molecules/CollapsibleContent';
import CollapsibleContentShimmer from '_molecules/CollapsibleContent/shimmer';
import ErrorHandler from '_molecules/ErrorHandler';

const validationSchema = Yup.object().shape({
    minNights: Yup.array().of(
        Yup.object().shape({
            value: Yup.number()
                .integer('Valor inválido')
                .min(0, 'Valor inválido')
                .typeError('Apenas números')
                .required('Obrigatório'),
            periodLower: Yup.string()
                .required('Obrigatório')
                .test('startDate', 'Data inválida', (value) =>
                    moment(value, 'DD-MM-YYYY').isValid()
                ),
            periodUpper: Yup.string()
                .required('Obrigatório')
                .test('endDate', 'Data inválida', (value) => moment(value, 'DD-MM-YYYY').isValid())
                .test('validate', 'Data inferior a data inicial', function validateAfterDate(date) {
                    const startDate = moment(this.parent.periodLower, 'DD/MM/YYYY');
                    const endDate = moment(date, 'DD/MM/YYYY');

                    return moment(endDate).isSameOrAfter(startDate);
                }),
        })
    ),
});

const MainContentQuartoMinimoNoites = ({ room, updateRoom }) => {
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const { id: roomId } = useParams();
    const [modalRemove, setModalRemove] = useState(false);
    const [loadingModalDelete, setLoadingModalDelete] = useState(false);
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const refMinNightsId = useRef();
    const [minNights, setMinNights] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        minNights: minNights || [],
    };

    const handleRemove = useCallback(async () => {
        setLoadingModalDelete(true);
        setErrorMessage({});
        try {
            await removeRoomMinNights(accommodation.id, room.id, refMinNightsId.current, jwt);
            const newMinNightsList = minNights.filter(
                (night) => night.id !== refMinNightsId.current
            );
            setMinNights(newMinNightsList);
            setAlert({
                show: true,
                message: 'Mínimo de noites excluído com sucesso!',
                type: 'success',
            });
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível excluir o valor de mínimo de noites.',
                type: 'danger',
            });
        }
        updateRoom();
        setModalRemove(false);
        setLoadingModalDelete(false);
    }, [accommodation.id, jwt, room, updateRoom, minNights]);

    const handleModalRemove = useCallback(
        (minNightId) => {
            refMinNightsId.current = minNightId;
            setModalRemove(!modalRemove);
        },
        [modalRemove]
    );

    const handleUpdate = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});
            let minNightValues = values.minNights.find(
                (minNight) => minNight.id === refMinNightsId.current
            );

            minNightValues = {
                room: roomId,
                value: minNightValues.value,
                period: {
                    lower: moment(minNightValues.periodLower, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    upper: moment(minNightValues.periodUpper, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                },
            };

            try {
                await updateRoomMinNights(
                    accommodation.id,
                    roomId,
                    refMinNightsId.current,
                    minNightValues,
                    jwt
                );

                setAlert({
                    show: true,
                    message: 'Mínimo de noites atualizada com sucesso!',
                    type: 'success',
                });
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível atualizar o valor de mínimo de noites.',
                    type: 'danger',
                });
            }
            setLoading(false);
            updateRoom();
        },
        [accommodation.id, roomId, jwt, updateRoom]
    );

    useEffect(() => {
        const getMinNights = async () => {
            const { results } = await getRoomMinNights(accommodation.id, roomId, jwt);
            const minNightsList = results.map((night) => ({
                ...night,
                periodLower: moment(night.period.lower).format('DD/MM/YYYY'),
                periodUpper: moment(night.period.upper).format('DD/MM/YYYY'),
            }));

            if (!results.length) {
                setAlert({
                    show: true,
                    message: 'Quarto não possui mínimo de noites cadastrado.',
                    type: 'message',
                });
            }
            setMinNights(minNightsList);
            setLoading(false);
        };

        getMinNights();
    }, [accommodation.id, jwt, roomId]);

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }
        return () => setAlert({});
    }, [history.location.data]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                handleUpdate(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, handleChange, handleBlur, values } = formProps;

                return (
                    <>
                        <section className="col-xl-8 col-md-12">
                            {alert.show && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <Alert type={alert.type}>{alert.message}</Alert>
                                    </div>
                                </div>
                            )}
                            {Object.values(errorMessage).length > 0 && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <ErrorHandler errors={errorMessage} />
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                {loading ? (
                                    <>
                                        <CollapsibleContentShimmer />
                                    </>
                                ) : (
                                    <>
                                        {values.minNights.map((minNight, idx) => (
                                            <CollapsibleContent
                                                key={minNight.id}
                                                title="Mínimo de noites"
                                                message={`${minNight.periodLower} até ${minNight.periodUpper}`}
                                            >
                                                <div className="row mt-3">
                                                    <div className="col-xl-6 col-md-6">
                                                        <Input
                                                            label="Mínimo de noites"
                                                            name={`minNights.${idx}.value`}
                                                            placeholder="Valor"
                                                            inputMode="decimal"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                        <MaskedInput
                                                            label="Data inicial"
                                                            mask={DATE_MASK}
                                                            name={`minNights.${idx}.periodLower`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCorrect="off"
                                                            placeholder="Data inicial"
                                                            inputMode="decimal"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <MaskedInput
                                                            label="Data final"
                                                            mask={DATE_MASK}
                                                            name={`minNights.${idx}.periodUpper`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCorrect="off"
                                                            placeholder="Data final"
                                                            inputMode="decimal"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3 justify-content-betwen">
                                                    <div className="col d-flex justify-content-end">
                                                        <Button
                                                            type={TYPE.link}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() =>
                                                                handleModalRemove(minNight.id)
                                                            }
                                                        >
                                                            Remover mínimo de noite
                                                        </Button>
                                                        <Button
                                                            type={TYPE.secondary}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() => {
                                                                refMinNightsId.current =
                                                                    minNight.id;
                                                                return refMinNightsId.current;
                                                            }}
                                                            typeSubmit
                                                            disabled={isSubmitting}
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </CollapsibleContent>
                                        ))}
                                        <div className="container">
                                            <div
                                                className={`row ${
                                                    values.minNights.length
                                                        ? 'justify-content-end'
                                                        : ''
                                                }`}
                                            >
                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        {room.isLoading ? (
                                                            <ButtonShimmer />
                                                        ) : (
                                                            <Button
                                                                type={LINE_TYPE.secondary}
                                                                size={BUTTON_SIZE.normal}
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/quarto/${room.id}/minimo-noite/novo`
                                                                    )
                                                                }
                                                                isBlock
                                                            >
                                                                Criar mínimo de noite
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        </section>
                        {modalRemove && (
                            <ModalAction
                                showModal={modalRemove}
                                modalName="cancelBooking"
                                title="Deseja realmente deletar o mínimo de noite?"
                                type="danger"
                                submitLabel="Confirmar"
                                cancelLabel="Cancelar"
                                onCancel={handleModalRemove}
                                onSubmit={handleRemove}
                                onhandleCloseModal={handleModalRemove}
                                closeOnOverlay
                                closeOnEsc
                                isLoading={loadingModalDelete}
                            />
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

MainContentQuartoMinimoNoites.propTypes = {
    room: PropTypes.shape({
        id: PropTypes.number,
        isLoading: PropTypes.bool,
        min_nights: PropTypes.arrayOf(PropTypes.object),
    }),
    updateRoom: PropTypes.func,
};

MainContentQuartoMinimoNoites.defaultProps = {
    room: {},
    updateRoom: undefined,
};

export default MainContentQuartoMinimoNoites;
