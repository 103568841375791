import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Autosuggest from 'react-autosuggest';

import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import MaskedInput from 'react-text-mask';
import { CEP_MASK, numberOnly } from '_utils/inputMasks';
import Input from '_atoms/Input/Input';
import Checkbox from '_atoms/Checkbox/Checkbox';
import LabelShimmer from '_atoms/Label/shimmer';
import InputShimmer from '_atoms/Input/shimmer';
import SectionBorder, { BGCOLOR } from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentMeuHotelDadosCadastraisShimmer from '_organisms/MainContentMeuHotelDadosCadastrais/shimmer';
import ButtonShimmer from '_atoms/Button/shimmer';
import actions from '_redux/actions';
import requestZipCode from '_services/requestZipCode';
import useAccommodation from '_hooks/useAccommodation';
import useUserPermission from '_hooks/useUserPermission';
import ErrorHandler from '_molecules/ErrorHandler';
import { searchCities } from '_services/requestCities';
import Label from '_atoms/Label';
import Select from '_atoms/Select/Select';
import OPTIONS_STATES from '_config/mocks-states';
import LineInstruction from '_molecules/LineInstruction';

import style from '_organisms/MainContentMeuHotelDadosCadastrais/index.module.scss';

const MainContentMeuHotelDadosCadastrais = () => {
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const [accommodationDetails, isLoading] = useAccommodation(accommodation.id, jwt);
    const { userIsSuperUser } = useUserPermission();
    const isCepValid = useRef(true);
    const [errorMessage, setErrorMessage] = useState({});
    const [isFieldDisabled, setIsFieldDisabled] = useState(true);
    const [searchCityValue, setSearchCityValue] = useState('');
    const [cityData, setCityData] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [noSuggestions, setNoSuggestions] = useState(false);

    const initValues = {
        is_active: accommodationDetails.is_active || false,
        trade_mark: accommodationDetails.trade_mark || '',
        company_name: accommodationDetails.company_name || '',
        document: accommodationDetails.document || '',
        state_register: accommodationDetails.state_register || '',
        municipal_register: accommodationDetails.municipal_register || '',
        zipcode: accommodationDetails.zipcode || '',
        street: accommodationDetails.street || '',
        number: accommodationDetails.number || '',
        neighborhood: accommodationDetails.neighborhood || '',
        complement: accommodationDetails.complement || '',
        city: accommodationDetails.city?.name || '',
        state: accommodationDetails.city?.state || '',
        days_to_pay_after_checkout: accommodationDetails.days_to_pay_after_checkout || '',
    };

    const validationSchema = Yup.object().shape({
        trade_mark: Yup.string().required('Obrigatório'),
        company_name: Yup.string().required('Obrigatório'),
        document: Yup.string().required('Obrigatório'),
        zipcode: Yup.string()
            .min(8, 'Cep inválido')
            .required('Obrigatório')
            .test('minValue', 'Cep deve possuir 8 dígitos', (value) => {
                const cepDigit = numberOnly(value);
                return cepDigit.length === 8;
            })
            .test('isCepValid', 'CEP inválido', () => isCepValid.current),
        street: Yup.string().required('Obrigatório'),
        number: Yup.number().typeError('Apenas número').required('Obrigatório'),
        neighborhood: Yup.string().required('Obrigatório'),
        days_to_pay_after_checkout: Yup.number()
            .positive('Valor inválido')
            .integer('Valor inválido')
            .typeError('Apenas número')
            .required('Obrigatório'),
        city: Yup.string().test('cityname', 'Obrigatório', () => searchCityValue),
    });

    const onHandleSubmit = async (values) => {
        const params = {
            ...values,
            city: cityData.id ? cityData.id : accommodationDetails.city.id,
            zipcode: numberOnly(values.zipcode),
        };

        setLoading(true);
        setErrorMessage({});
        try {
            await dispatch(actions.updateAccommodation(accommodation.id, jwt, params));
            setAlert({
                show: true,
                message: 'As informações do hotel foram atualizadas!',
                type: 'success',
            });
            setIsFieldDisabled(true);
        } catch (error) {
            setAlert({
                show: true,
                message: 'Não foi possível atualizar as informações.',
                type: 'danger',
            });
            setErrorMessage(error);
        }
        setLoading(false);
    };

    const handleCepBlur = (e, setFieldTouched, setFieldError) => {
        const { value } = e.target;

        setFieldTouched('zipcode', true, false);

        if (!value) {
            setFieldError('zipcode', 'Obrigatório');
        }
    };

    const handleCep = async (e, setFieldValue, setFieldTouched, setFieldError) => {
        const { name, value } = e.target;
        const cepDigit = numberOnly(value);
        setFieldValue(name, value);
        setIsFieldDisabled(true);

        if (cepDigit.length === 8) {
            try {
                setErrorMessage({});
                const response = await requestZipCode(numberOnly(value), jwt);
                const zipAddress = {
                    number: '',
                    ...response,
                    city: response.city.name,
                    state: response.city.state,
                };
                isCepValid.current = true;

                Object.entries(zipAddress).forEach(([fieldName, fieldValue]) => {
                    setFieldValue(fieldName, fieldValue);
                });
                setCityData(response.city);
                setSearchCityValue(response.city.name);
            } catch (error) {
                if (
                    error.response.data.error &&
                    error.response.data.error[0] === 'zipcode not exists'
                ) {
                    isCepValid.current = false;
                    setFieldTouched('zipcode', true, false);
                    setFieldError('zipcode', 'CEP inválido');
                    setErrorMessage(error);
                } else {
                    isCepValid.current = true;
                    setErrorMessage({});

                    setSearchCityValue('');
                    setCityData({});
                    setFieldValue('street', '');
                    setFieldValue('number', '');
                    setFieldValue('neighborhood', '');

                    setIsFieldDisabled(false);
                }
            }
        }
    };

    useEffect(() => setLoading(isLoading), [isLoading]);

    const handleChangeSearchCity = useCallback((event, { newValue }) => {
        if (!newValue) setCityData('');

        setSearchCityValue(newValue);
    }, []);

    const handleBlurSearchCity = useCallback((event, { highlightedSuggestion }) => {
        if (!highlightedSuggestion) return;
        setSearchCityValue(highlightedSuggestion.name);
        setCityData(highlightedSuggestion);
    }, []);

    const handleSuggestionsFetchRequested = useCallback(
        async ({ value }) => {
            try {
                const response = await searchCities(value, jwt);
                const cities = response.map((city) => city);
                setSuggestions(cities);
                setNoSuggestions(value && cities.length === 0);
            } catch (error) {
                setNoSuggestions(false);
                setSuggestions([]);
            }
        },
        [jwt]
    );

    const handleSuggestionsClearRequested = useCallback(() => {
        setNoSuggestions(false);
        setSuggestions([]);
    }, []);

    const getSuggestionValue = useCallback((suggestion) => {
        setCityData(suggestion);

        return suggestion.name;
    }, []);

    const renderSuggestion = useCallback(
        (suggestion) => (
            <p>
                <span className={style.destinationName}>{suggestion.name}</span>
            </p>
        ),
        []
    );

    useEffect(() => {
        setSearchCityValue(accommodationDetails.city?.name);
    }, [accommodationDetails.city]);

    const inputProps = useMemo(
        () => ({
            placeholder: 'Cidade',
            name: 'city',
            value: searchCityValue,
            onChange: handleChangeSearchCity,
            onBlur: handleBlurSearchCity,
            autoComplete: 'off',
            disabled: isFieldDisabled,
        }),
        [handleBlurSearchCity, handleChangeSearchCity, isFieldDisabled, searchCityValue]
    );

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                onHandleSubmit(values, setFieldError);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const {
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    errors,
                } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <SectionBorder>
                                <div>
                                    <Heading level={LEVEL.h6} className={style.sectionTitle}>
                                        Dados cadastrais
                                    </Heading>
                                    {loading ? (
                                        <MainContentMeuHotelDadosCadastraisShimmer />
                                    ) : (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Checkbox name="is_active">
                                                        Hotel ativo
                                                    </Checkbox>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="Nome fantasia"
                                                        name="trade_mark"
                                                        placeholder="Digite o nome fantasia"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="Razão social"
                                                        name="company_name"
                                                        placeholder="Digite a razão social"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 mt-3">
                                                    <Input
                                                        label="CNPJ"
                                                        name="document"
                                                        placeholder="Digite o CNPJ"
                                                        inputMode="decimal"
                                                    />
                                                </div>
                                                <div className="col-xl-4 col-md-4 mt-3">
                                                    <Input
                                                        label="Inscrição estadual"
                                                        name="state_register"
                                                        placeholder="Digite a inscrição estadual"
                                                    />
                                                </div>
                                                <div className="col-xl-4 col-md-4 mt-3">
                                                    <Input
                                                        label="Inscrição municipal"
                                                        name="municipal_register"
                                                        placeholder="Digite a inscrição municipal"
                                                        inputMode="decimal"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 mt-3">
                                                    <MaskedInput
                                                        label="CEP"
                                                        mask={CEP_MASK}
                                                        placeholder="Digite o CEP"
                                                        name="zipcode"
                                                        inputMode="decimal"
                                                        autoCorrect="off"
                                                        onChange={(e) =>
                                                            handleCep(
                                                                e,
                                                                setFieldValue,
                                                                setFieldTouched,
                                                                setFieldError
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            handleCepBlur(
                                                                e,
                                                                setFieldTouched,
                                                                setFieldError
                                                            )
                                                        }
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xl-6 col-md-6 mt-3">
                                                    <Input
                                                        label="Logradouro"
                                                        name="street"
                                                        placeholder="Rua, Av, Servidão... "
                                                    />
                                                </div>
                                                <div className="col-xl-2 col-md-2 mt-3">
                                                    <Input
                                                        label="Nº"
                                                        name="number"
                                                        placeholder="Digite o número"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 mt-3">
                                                    <Input
                                                        label="Complemento"
                                                        name="complement"
                                                        placeholder="Digite o complemento"
                                                    />
                                                </div>
                                                <div className="col-xl-6 col-md-6 mt-3">
                                                    <Input
                                                        label="Bairro"
                                                        name="neighborhood"
                                                        placeholder="Digite o bairro"
                                                        disabled={isFieldDisabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 mt-3">
                                                    <div className={style.searchInputBox}>
                                                        <Label>Cidade</Label>
                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={
                                                                handleSuggestionsFetchRequested
                                                            }
                                                            onSuggestionsClearRequested={
                                                                handleSuggestionsClearRequested
                                                            }
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            theme={{
                                                                ...style,
                                                                input: `${style.contentSearch}`,
                                                            }}
                                                            highlightFirstSuggestion
                                                        />
                                                        {noSuggestions && (
                                                            <div className={style.noSuggestions}>
                                                                Nenhuma cidade foi encontrada
                                                            </div>
                                                        )}
                                                        {!searchCityValue && errors.city && (
                                                            <LineInstruction
                                                                className={classNames(
                                                                    style.errorMessage
                                                                )}
                                                            >
                                                                {errors.city}
                                                            </LineInstruction>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-md-6 mt-3">
                                                    <Select
                                                        label="Estado"
                                                        name="state"
                                                        quantityOptions={OPTIONS_STATES}
                                                        disabled={isFieldDisabled}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </SectionBorder>
                            {userIsSuperUser && (
                                <SectionBorder backgroundColor={BGCOLOR.gray}>
                                    <div>
                                        <Heading level={LEVEL.h6}>Uso interno</Heading>
                                        <div className="row mt-3">
                                            <div className="col-xl-6 col-md-6">
                                                {loading ? (
                                                    <>
                                                        <LabelShimmer />
                                                        <InputShimmer />
                                                    </>
                                                ) : (
                                                    <Input
                                                        label="Dias para pagamento"
                                                        name="days_to_pay_after_checkout"
                                                        placeholder="Digite número de dias para pagamento"
                                                        inputMode="decimal"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </SectionBorder>
                            )}

                            <div className="container">
                                <div
                                    className={classNames(
                                        'row justify-content-end',
                                        style.blockButton
                                    )}
                                >
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            {loading ? (
                                                <ButtonShimmer />
                                            ) : (
                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.normal}
                                                    onClick={() => {}}
                                                    typeSubmit
                                                    isBlock
                                                    disabled={isSubmitting}
                                                >
                                                    Salvar
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelDadosCadastrais;
