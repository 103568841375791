import React from 'react';
import { PROFILE_ITEMS } from '_config/settings';
import { useSelector } from 'react-redux';
import TemplateDefault from '_templates/TemplateDefault';
import MyPersonalCard from '_molecules/MyPersonalCard';

const MeuHotel = () => {
    const { accommodation } = useSelector((state) => state);

    return (
        <TemplateDefault title={accommodation.tradeMark}>
            {PROFILE_ITEMS.map((item) => (
                <MyPersonalCard key={item.id} {...item} />
            ))}
        </TemplateDefault>
    );
};

export default MeuHotel;
