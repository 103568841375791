import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from '_molecules/DropdownMenu/index.module.scss';

export const DIRECTION = {
    left: style.dropdownMenuLeft,
    right: style.dropdownMenuRight,
};

export const POSITION = {
    top: style.dropdownMenuTop,
    bottom: style.dropdownMenuBottom,
};

class DropdownMenu extends Component {
    constructor() {
        super();

        this.state = {
            showMenu: false,
            direction: DIRECTION.left,
        };
    }

    componentDidMount() {
        this.verifyHash();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeMenu);
    }

    verifyHash = () => {
        const hash = window.location.hash.replace(/^#\/?|\/$/g, '').split('/');
        const { hashUrl } = this.props;
        if (hash[0] === hashUrl) {
            return this.toggleShowState(true);
        }
        return this.toggleShowState(false);
    };

    toggleShowState = (value) => {
        if (value) {
            this.setState({ showMenu: value }, () => {
                document.addEventListener('click', this.closeMenu);
            });
        } else {
            this.setState({ showMenu: value }, () => {
                document.removeEventListener('click', this.closeMenu);
            });
        }
    };

    showMenu = (event) => {
        event.preventDefault();
        this.handleDirection();
        this.toggleShowState(true);
    };

    closeMenu = (event) => {
        if (this.dropdownMenu) {
            /* istanbul ignore next */
            if (
                !this.dropdownMenu.contains(event.target) ||
                event.target.parentNode.getAttribute('data-dropdown') === 'close'
            ) {
                this.toggleShowState(false);
            }
        } else {
            this.toggleShowState(false);
        }
    };

    handleDirection = () => {
        const dropdown = this.containerDropdown;
        const dropdownPosition = dropdown.getBoundingClientRect();
        const width = window.innerWidth;

        if (dropdownPosition.x > width / 2) {
            this.setState({ direction: DIRECTION.right });
        }
    };

    render() {
        const { showMenu, direction } = this.state;
        const { children, position, keepOnClickInside, trigger, className } = this.props;

        return (
            <div
                className={classNames(style.triggerDropdownMenu)}
                onClick={this.showMenu}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
            >
                {trigger}
                <div
                    ref={(element) => {
                        this.containerDropdown = element;
                        if (keepOnClickInside) {
                            this.dropdownMenu = element;
                        }
                    }}
                    className={classNames(style.dropdownMenu, direction, position, className, {
                        [style.isOpen]: showMenu,
                    })}
                >
                    {children}
                </div>
            </div>
        );
    }
}

DropdownMenu.propTypes = {
    children: PropTypes.node,
    trigger: PropTypes.node,
    className: PropTypes.string,
    position: PropTypes.string,
    keepOnClickInside: PropTypes.bool,
    hashUrl: PropTypes.string,
};

DropdownMenu.defaultProps = {
    children: undefined,
    trigger: undefined,
    className: undefined,
    position: POSITION.bottom,
    keepOnClickInside: false,
    hashUrl: undefined,
};

export default DropdownMenu;
