import { SET_AUTHENTICATION, SET_LOGOUT } from '_redux/types';
import { decrypt } from '_utils/crypt';
import { getCookie } from '_utils/cookie';

const token = decrypt(getCookie('portal_session'));
const initialState = token || null;

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return action.payload.token;

        case SET_LOGOUT:
            return null;

        default:
            return state;
    }
};
