import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Input from '_atoms/Input/Input';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Alert from '_molecules/Alert';
import actions from '_redux/actions';
import LoginFormShimmer from '_organisms/LoginForm/shimmer';
import { setCookie } from '_utils/cookie';

const LoginForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [alert, setAlert] = useState({
        show: false,
        message: '',
        type: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const initValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Obrigatório').email('Digite um e-mail válido.'),
        password: Yup.string().required('Obrigatório'),
    });

    useEffect(() => {
        if (
            history.location.state &&
            history.location.state.from.pathname !== '/' &&
            history.location.state.from.pathname !== '/sair'
        ) {
            setCookie('destinationPath', history.location.state.from.pathname);
        }
    }, [history.location.state]);

    const onHandleSubmit = useCallback(
        async (values, resetForm) => {
            setIsLoading(true);
            try {
                setAlert({ show: false, message: '', type: '' });
                await dispatch(actions.authenticate(values));
                history.push('/escolher-hotel');
            } catch (error) {
                resetForm();
                setIsLoading(false);
                setAlert({
                    show: true,
                    message: 'Erro ao realizar login. E-mail ou senha incorretos.',
                    type: 'danger',
                });
            }
        },
        [dispatch, history]
    );

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onHandleSubmit(values, resetForm);
                setSubmitting(false);
            }}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit } = formProps;

                return (
                    <>
                        {alert.show && (
                            <div className="row mt-3 mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            {isLoading ? (
                                <LoginFormShimmer />
                            ) : (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            <Input
                                                label="E-mail"
                                                name="email"
                                                placeholder="Digite seu e-mail"
                                                inputMode="email"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            <Input
                                                label="Senha"
                                                name="password"
                                                placeholder="Digite sua senha"
                                                inputMode="password"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            <Button
                                                type={TYPE.secondary}
                                                size={BUTTON_SIZE.large}
                                                onClick={() => {}}
                                                typeSubmit
                                                isBlock
                                                disabled={isSubmitting}
                                            >
                                                Entrar
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            <Button
                                                type={TYPE.link}
                                                size={BUTTON_SIZE.large}
                                                onClick={() => history.push('/esqueci-senha')}
                                                isBlock
                                            >
                                                Esqueci minha senha
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                    </>
                );
            }}
        </Formik>
    );
};

export default LoginForm;
