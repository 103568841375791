import React from 'react';
import classNames from 'classnames';

import style from '_atoms/CalendarSubtitle/index.module.scss';

const CalendarSubtitle = () => (
    <div className={style.blockSubtitle}>
        <div className={style.subtitle}>
            <div className={classNames(style.square, style.free)} />
            <p className={style.label}>Disponíveis</p>
        </div>
        <div className={style.subtitle}>
            <div className={classNames(style.square, style.booked)} />
            <p className={style.label}>Reservados</p>
        </div>
    </div>
);

export default CalendarSubtitle;
