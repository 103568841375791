import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Loading/index.module.scss';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }

    render() {
        const { visible, className } = this.props;

        return ReactDOM.createPortal(
            <div className={classNames({ [style.blockLoading]: visible, className })}>
                <div className={style.loaderBalls}>
                    <div className={style.item} />
                    <div className={style.item} />
                    <div className={style.item} />
                </div>
            </div>,
            this.el
        );
    }
}

Loading.propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
};

Loading.defaultProps = {
    visible: false,
    className: undefined,
};

export default Loading;
