import React from 'react';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentMeuHotel from '_organisms/SideContentMeuHotel';
import MainContentMeuHotelDistanciasAcomodacao from '_organisms/MainContentMeuHotelDistanciasAcomodacao';
import { PROFILE_ITEMS } from '_config/settings';
import useMobileNavigation from '_hooks/useMobileNavigation';

const DistanciaDaAcomodacao = () => {
    const MobileNavigationComponent = useMobileNavigation(PROFILE_ITEMS);

    return (
        <TemplateMeuHotelContent
            title="Distâncias da acomodação"
            main={
                <>
                    <MobileNavigationComponent />
                    <MainContentMeuHotelDistanciasAcomodacao />
                </>
            }
            side={<SideContentMeuHotel currentPath="/meu-hotel/distancias-da-acomodacao" />}
            linkBack
        />
    );
};

export default DistanciaDaAcomodacao;
