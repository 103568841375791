import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import Input from '_atoms/Input/Input';
import TextArea from '_atoms/TextArea/TextArea';
import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import SectionBorder from '_molecules/SectionBorder';
import ModalAction from '_organisms/ModalAction';
import {
    removeAccommodationConditions,
    updateAccommodationConditions,
    getAccommodationConditions,
} from '_services/requestAccommodations';
import { HOUR_MASK } from '_utils/inputMasks';
import actions from '_redux/actions';
import MainContentMeuHotelPoliticasShimmer from '_organisms/MainContentMeuHotelPoliticas/shimmer';
import useAccommodation from '_hooks/useAccommodation';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentMeuHotelPoliticas/index.module.scss';

const MainContentMeuHotelPoliticas = () => {
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [modalRemove, setModalRemove] = useState(false);
    const [loadingModalDelete, setLoadingModalDelete] = useState(false);
    const refConditionId = useRef();
    const [conditions, setConditions] = useState([]);
    const dispatch = useDispatch();
    const [accommodationDetails, isLoading] = useAccommodation(accommodation.id, jwt);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        conditions,
        check_in: accommodationDetails.check_in || '',
        check_out: accommodationDetails.check_out || '',
    };

    const validationSchema = Yup.object().shape({
        conditions: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required('Obrigatório'),
            })
        ),
        check_in: Yup.string().required('Obrigatório'),
        check_out: Yup.string().required('Obrigatório'),
    });

    const handleRemove = useCallback(async () => {
        setLoadingModalDelete(true);
        setErrorMessage({});
        try {
            await removeAccommodationConditions(accommodation.id, refConditionId.current, jwt);

            const listConditions = conditions.filter(
                (condition) => condition.id !== refConditionId.current
            );
            setConditions(listConditions);
            setAlert({
                show: true,
                message: 'Condição removida com sucesso!',
                type: 'success',
            });
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível remover a condição.',
                type: 'danger',
            });
        }
        setLoadingModalDelete(false);
        setModalRemove(false);
    }, [accommodation.id, jwt, conditions]);

    const handleModalRemove = useCallback(
        (conditionId) => {
            refConditionId.current = conditionId;
            setModalRemove(!modalRemove);
        },
        [modalRemove]
    );

    const handleUpdateCondition = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});
            const condition = values.conditions.find(
                (conditionIt) => conditionIt.id === refConditionId.current
            );

            const params = {
                accommodation: accommodation.id,
                condition: condition.condition.id,
                description: condition.description,
            };

            try {
                await updateAccommodationConditions(accommodation.id, condition.id, params, jwt);

                setAlert({
                    show: true,
                    message: 'Condição atualizada com sucesso!',
                    type: 'success',
                });
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível atualizar a condição.',
                    type: 'danger',
                });
            }
            setLoading(false);
        },
        [accommodation.id, jwt]
    );

    const handleSaveHour = useCallback(
        async (values) => {
            const params = {
                check_in: values.check_in,
                check_out: values.check_out,
            };

            setLoading(true);
            setErrorMessage({});

            try {
                await dispatch(actions.updateAccommodation(accommodation.id, jwt, params));
                setAlert({
                    show: true,
                    message: 'As informações do hotel foram atualizadas!',
                    type: 'success',
                });
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível atualizar as informações.',
                    type: 'danger',
                });
            }
            setLoading(false);
        },
        [accommodation.id, jwt, dispatch]
    );

    useEffect(() => {
        const getListConditions = async () => {
            setLoading(true);
            setErrorMessage({});
            try {
                const { results } = await getAccommodationConditions(accommodation.id, jwt);
                if (!results.length) {
                    setAlert({
                        show: true,
                        message: 'Não existem condições cadastradas.',
                        type: 'message',
                    });
                    setLoading(false);
                    return;
                }
                setConditions(results);
            } catch (error) {
                setErrorMessage(error);
            }
            setLoading(false);
        };

        getListConditions();
    }, [accommodation.id, jwt]);

    useEffect(() => setLoading(isLoading), [isLoading]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                handleUpdateCondition(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleChange, handleBlur, handleSubmit, values } = formProps;

                return (
                    <>
                        <section className="col-xl-8 col-md-12">
                            {alert.show && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <Alert type={alert.type}>{alert.message}</Alert>
                                    </div>
                                </div>
                            )}
                            {Object.values(errorMessage).length > 0 && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <ErrorHandler errors={errorMessage} />
                                    </div>
                                </div>
                            )}
                            {loading ? (
                                <>
                                    <MainContentMeuHotelPoliticasShimmer />
                                    <MainContentMeuHotelPoliticasShimmer />
                                    <MainContentMeuHotelPoliticasShimmer />
                                </>
                            ) : (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        {values.conditions.map((condition, idx) => (
                                            <SectionBorder key={condition.id}>
                                                <Heading
                                                    level={LEVEL.h5}
                                                    className={style.sectionTitle}
                                                >
                                                    Condição #{idx + 1}
                                                </Heading>

                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        <Input
                                                            label="Condição"
                                                            name={`conditions.${idx}.condition.title`}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        <TextArea
                                                            label="Descrição"
                                                            name={`conditions.${idx}.description`}
                                                            placeholder="Descrição"
                                                            rows={5}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3 justify-content-betwen">
                                                    <div className="col d-flex justify-content-end">
                                                        <Button
                                                            type={TYPE.link}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() =>
                                                                handleModalRemove(condition.id)
                                                            }
                                                        >
                                                            Remover condição
                                                        </Button>
                                                        <Button
                                                            type={TYPE.secondary}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() => {
                                                                refConditionId.current =
                                                                    condition.id;
                                                                return refConditionId.current;
                                                            }}
                                                            typeSubmit
                                                            disabled={isSubmitting}
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </SectionBorder>
                                        ))}
                                    </form>
                                    <form>
                                        <SectionBorder className="mt-4">
                                            <Heading
                                                level={LEVEL.h6}
                                                className={style.sectionTitle}
                                            >
                                                Horário de check-in e check-out
                                            </Heading>
                                            <div className="row mt-3">
                                                <div className="col-xl-4 col-md-4">
                                                    <MaskedInput
                                                        label="Check-in"
                                                        mask={HOUR_MASK}
                                                        placeholder="Digite o horário de check-in"
                                                        name="check_in"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        autoCorrect="off"
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-4 col-md-4">
                                                    <MaskedInput
                                                        label="Check-out"
                                                        mask={HOUR_MASK}
                                                        placeholder="Digite o horário de check-out"
                                                        name="check_out"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        autoCorrect="off"
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3 justify-content-betwen">
                                                <div className="col d-flex justify-content-end">
                                                    <Button
                                                        type={TYPE.secondary}
                                                        size={BUTTON_SIZE.normal}
                                                        onClick={() => handleSaveHour(values)}
                                                        disabled={isSubmitting}
                                                    >
                                                        Salvar
                                                    </Button>
                                                </div>
                                            </div>
                                        </SectionBorder>
                                        <div className="container">
                                            <div
                                                className={classNames(
                                                    'row justify-content-end mt-3',
                                                    style.blockButton
                                                )}
                                            >
                                                <div className="d-flex">
                                                    <Button
                                                        type={LINE_TYPE.secondary}
                                                        size={BUTTON_SIZE.normal}
                                                        onClick={() =>
                                                            history.push(`/meu-hotel/condicao/novo`)
                                                        }
                                                    >
                                                        Criar condição
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )}
                        </section>
                        {modalRemove && (
                            <ModalAction
                                showModal={modalRemove}
                                modalName="cancelBooking"
                                title="Deseja realmente deletar esta condição?"
                                type="danger"
                                submitLabel="Confirmar"
                                cancelLabel="Cancelar"
                                onCancel={handleModalRemove}
                                onSubmit={handleRemove}
                                onhandleCloseModal={handleModalRemove}
                                closeOnOverlay
                                closeOnEsc
                                isLoading={loadingModalDelete}
                            />
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelPoliticas;
