import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Checkbox from '_atoms/Checkbox';
import FilterBar from '_atoms/FilterBar';
import DropDownContainer from '_atoms/DropDownContainer';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import TableSelectionRow, {
    COLUMN_SIZE as TABLESELECTION_COLUMN_SIZE,
} from '_molecules/Table/TableSelectionRow';
import Table, { COLUMN_SIZE } from '_molecules/Table';
import DropdownWithAction from '_molecules/DropdownWithAction';
import DropDownDateRange from '_molecules/DropDownDateRange';
import DropDownFilterList from '_molecules/DropDownFilterList';
import Modal, { SIZE, INTERNAL_PADDING } from '_organisms/Modal';
import {
    getAccommodationRoomsAvailabilityDay,
    getAccommodationRooms,
} from '_services/requestRooms';
import { MdCheckCircle } from 'react-icons/md';
import { deleteAvailabilityByMultiplesIds } from '_services/requestAvailability';
import { useCheckBoxId, useCheckBox } from '_hooks/checkBoxHooks';
import useUserPermission from '_hooks/useUserPermission';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_pages/disponibilidades/list.module.scss';

const createVariantsString = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;

    const stringVariants = value
        .map((variant) => variant.label)
        .toString()
        .replace(/,/g, ', ');

    return stringVariants;
};

const createAvailableString = (tableParams) => {
    const { value } = tableParams;
    switch (value) {
        case true:
            return <MdCheckCircle className={style.icon} size={22} />;
        default:
            return '-';
    }
};

const List = () => {
    const { accommodation, authentication } = useSelector((state) => state);
    const history = useHistory();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [date, setDate] = useState([]);
    const [rowSelected, setRowSelected] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [alert, setAlert] = useState(() => {
        if (!history.location.data) {
            return {};
        }
        return history.location.data;
    });
    const [rooms, setRooms] = useState([]);
    const [variants, setVariants] = useState([]);
    const [errorTableData, setErrorTableData] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const [roomIds, setRoomIds] = useCheckBoxId();
    const [variantIds, setVariantIds] = useCheckBoxId();
    const [checkBoxRooms, setCheckBoxRooms] = useCheckBox();
    const [checkBoxVariants, setCheckBoxVariants] = useCheckBox();
    const totalItens = useRef();
    const pageIndexReference = useRef();
    const { userIsSuperUser } = useUserPermission();

    const columns = [
        {
            Header: 'Quarto',
            accessor: 'room.name',
        },
        {
            Header: 'Reserva do quarto',
            accessor: 'room_booking',
        },
        {
            Header: 'Data',
            accessor: 'dateConverted',
        },
        {
            Header: 'Bloqueio',
            accessor: 'available',
            renderColumn: createAvailableString,
        },
        {
            Header: 'Variantes do quarto',
            accessor: 'room.variants',
            renderColumn: createVariantsString,
        },
    ];

    const getAvailability = useCallback(
        async ({ pageSize, pageIndex }) => {
            const params = {
                limit: pageSize,
                offset: pageIndex * pageSize,
                date_after: date[0] || '',
                date_before: date[1] || '',
                room_id: roomIds.join(',') || '',
                variant_id: variantIds.join(',') || '',
            };

            try {
                setErrorTableData(false);
                setErrorMessage({});
                setLoading(true);
                const jwt = authentication;
                const { results, count } = await getAccommodationRoomsAvailabilityDay(
                    accommodation.id,
                    params,
                    jwt
                );

                const availabilities = results.map((availability) => {
                    const dateConverted = moment(availability.date, 'YYYY-MM-DD').format(
                        'DD/MM/YYYY'
                    );

                    return { ...availability, dateConverted };
                });

                setData(availabilities);
                setPageNumber(Math.ceil(count / pageSize));
                totalItens.current = count;
                pageIndexReference.current = pageIndex;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorTableData(true);
                setErrorMessage(error);
            }
        },
        [accommodation.id, authentication, date, roomIds, variantIds]
    );

    const selectedRow = useCallback(
        (row) => {
            const rows = row
                .map((item) => data.filter((value) => value.id === Number(item)))
                .flat();

            setRowSelected(rows);
        },
        [data]
    );

    const onDeleteAvailability = async () => {
        const rowsId = rowSelected.map((row) => row.id);

        setLoading(true);
        try {
            const jwt = authentication;
            await deleteAvailabilityByMultiplesIds(accommodation.id, rowsId, jwt);
            setShowModal(false);
            setAlert({
                show: true,
                message: 'Disponibilidades excluídas com sucesso!',
                type: 'success',
            });
            await getAvailability({ pageSize: 10, pageIndex: pageIndexReference.current });
        } catch (err) {
            setAlert({
                show: true,
                message: 'Não foi possível excluir as disponibilidades selecionadas.',
                type: 'danger',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        const filterRoom = async () => {
            try {
                const jwt = authentication;
                const { results } = await getAccommodationRooms(accommodation.id, jwt);
                const roomName = [];
                const variantName = [];

                results.forEach((room) => {
                    roomName.push({ id: room.id, name: room.name });
                    variantName.push(
                        ...room.variants.map((variant) => ({ id: variant.id, name: variant.label }))
                    );
                });

                setRooms(roomName);
                setVariants(variantName);
            } catch {
                setRooms([]);
                setVariants([]);
            }
        };

        filterRoom();
    }, [accommodation.id, authentication]);

    return (
        <>
            <div className={style.section}>
                <FilterBar>
                    <div />
                    <DropDownContainer>
                        <DropDownDateRange label="Data" handleDate={(dates) => setDate(dates)} />
                        <DropDownFilterList
                            label="Quarto"
                            onHandleFilterList={() => setRoomIds(checkBoxRooms)}
                            onResetFilter={() => {
                                setCheckBoxRooms([]);
                                setRoomIds([]);
                            }}
                            itemSelected={roomIds.length}
                        >
                            {rooms.map((room) => (
                                <Checkbox
                                    key={room.id}
                                    name="room"
                                    labelText={room.name}
                                    onChange={() => setCheckBoxRooms(room)}
                                    checked={checkBoxRooms.some((item) => item.id === room.id)}
                                />
                            ))}
                        </DropDownFilterList>
                        <DropDownFilterList
                            label="Variante"
                            onHandleFilterList={() => setVariantIds(checkBoxVariants)}
                            onResetFilter={() => {
                                setCheckBoxVariants([]);
                                setVariantIds([]);
                            }}
                            itemSelected={variantIds.length}
                        >
                            {variants.map((variant) => (
                                <Checkbox
                                    key={variant.id}
                                    name="variant"
                                    labelText={variant.name}
                                    onChange={() => setCheckBoxVariants(variant)}
                                    checked={checkBoxVariants.some(
                                        (item) => item.id === variant.id
                                    )}
                                />
                            ))}
                        </DropDownFilterList>
                    </DropDownContainer>
                </FilterBar>
                {userIsSuperUser && (
                    <DropdownWithAction
                        value={
                            rowSelected.length > 1
                                ? `${rowSelected.length} disponibilidades selecionadas`
                                : `${rowSelected.length} disponibilidade selecionada`
                        }
                        className={style.dropDownContainer}
                    >
                        <button
                            type="button"
                            onClick={() => {
                                setShowModal(true);
                                setAlert({ show: false, message: '', type: '' });
                            }}
                            disabled={!rowSelected.length}
                        >
                            Excluir disponibilidade
                        </button>
                    </DropdownWithAction>
                )}
                {alert.show && (
                    <div className="row mb-4">
                        <div className="col-xl-12">
                            <Alert type={alert.type}>{alert.message}</Alert>
                        </div>
                    </div>
                )}
                {Object.values(errorMessage).length > 0 && (
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <ErrorHandler errors={errorMessage} />
                        </div>
                    </div>
                )}
            </div>
            <TableSelectionRow
                columns={columns}
                data={data}
                pageNumber={pageNumber}
                fetchData={getAvailability}
                loading={loading}
                columnClassName={style.column}
                selectedRow={selectedRow}
                columnSize={TABLESELECTION_COLUMN_SIZE.small}
                errorLoadingData={errorTableData}
                rowsPerPage={50}
                disableSortBy
            />
            <Modal
                modalName="deleteConfirmation"
                onhandleCloseModal={() => setShowModal(false)}
                showModal={showModal}
                size={SIZE.large}
                internalPadding={INTERNAL_PADDING.fat}
            >
                <div className={style.containerModal}>
                    <div className="mb-3">
                        <Heading level={LEVEL.h2} className={style.header}>
                            Deseja excluir?
                        </Heading>
                        <p className={style.text}>
                            Tem certeza de que deseja apagar as Disponibilidades selecionadas? Todos
                            os seguintes objetos e seus itens relacionados serão removidos:
                        </p>
                    </div>
                    <div className="mb-3">
                        <Heading level={LEVEL.h4}>Resumo:</Heading>
                        <p>Disponibilidades: {rowSelected.length}</p>
                    </div>
                    <div className="mb-3">
                        <Heading level={LEVEL.h4}>Detalhe:</Heading>
                        <div className={style.containerTable}>
                            <Table
                                columns={columns}
                                data={rowSelected}
                                pageNumber={rowSelected.length}
                                fetchData={() => {}}
                                loading={loading}
                                pagination={false}
                                columnSize={COLUMN_SIZE.small}
                                disableSortBy
                            />
                        </div>
                    </div>
                    <div className={classNames('row justify-content-end mt-3', style.blockButton)}>
                        <div className="col-auto">
                            <Button
                                type={TYPE.link_danger}
                                size={BUTTON_SIZE.normal}
                                onClick={() => setShowModal(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="col-auto pr-0">
                            <Button
                                type={TYPE.danger}
                                size={BUTTON_SIZE.normal}
                                onClick={onDeleteAvailability}
                                typeSubmit
                            >
                                Sim, excluir disponibilidades
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default List;
