import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Heading, { LEVEL } from '_atoms/Heading';
import { MdLaunch } from 'react-icons/md';
import Table, { COLUMN_SIZE, SHIMMER_SIZE } from '_molecules/Table';
import moment from 'moment';
import 'moment/locale/pt-br';
import { splitBookings } from '_utils/bookingInitialize';
import Modal, { SIZE, INTERNAL_PADDING } from '_organisms/Modal';

import style from '_organisms/CalendarModal/index.module.scss';
import { useEffect } from 'react';

const renderDate = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;

    return moment(value).format('DD/MM/YYYY');
};

const CalendarModal = ({ calendarModal, loading, showModal, setShowModal }) => {
    const [bookingsBooked, setBookingsBooked] = useState([]);
    const [error, setError] = useState(false);

    const createDateToshow = () =>
        calendarModal.title.date ? moment(calendarModal.title.date).format('LL') : '';

    const onChangeBookings = useCallback(async () => {
        setBookingsBooked([]);
        setError(false);

        try {
            const splitedBookings = await splitBookings(calendarModal.bookings);
            setBookingsBooked(splitedBookings.booked);
        } catch (err) {
            setError(true);
            console.error(err);
        }
    }, [calendarModal.bookings]);

    const renderOptions = useCallback((tableParams) => {
        const { value } = tableParams;
        return (
            <Link to={`reserva/${value}`} title="Abrir reserva em outra página" target="blank">
                <MdLaunch className={style.icon} size={22} />
            </Link>
        );
    }, []);

    const columns = [
        {
            Header: 'Reserva',
            accessor: 'id',
        },
        {
            Header: 'Nome do hóspede',
            accessor: 'guests__name',
        },
        {
            Header: 'Data do checkin',
            accessor: 'start_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Data do checkout',
            accessor: 'end_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Opções',
            accessor: 'row_id',
            renderColumn: renderOptions,
        },
    ];

    useEffect(() => {
        onChangeBookings();
    }, [onChangeBookings]);

    return (
        <>
            {showModal && (
                <Modal
                    size={SIZE.large}
                    internalPadding={INTERNAL_PADDING.fat}
                    showModal={showModal}
                    modalName="calendarBookingDay"
                    onhandleCloseModal={() => {
                        setShowModal(false);
                    }}
                >
                    <div className={style.contentModal}>
                        <Heading level={LEVEL.h2}>{createDateToshow()}</Heading>
                        <p>{calendarModal.title.room}</p>
                        <div className={style.sectionData}>
                            <Heading className={style.header} level={LEVEL.h4}>
                                Reservas confirmadas
                            </Heading>
                            <Table
                                columns={columns}
                                data={bookingsBooked}
                                fetchData={() => {}}
                                loading={loading}
                                pagination={false}
                                columnSize={COLUMN_SIZE.small}
                                shimmerSize={SHIMMER_SIZE.small}
                                errorLoadingData={error}
                                disableSortBy
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

CalendarModal.propTypes = {
    calendarModal: PropTypes.shape({
        bookings: PropTypes.arrayOf(PropTypes.object),
        title: PropTypes.shape({
            room: PropTypes.string,
            date: PropTypes.string,
        }),
    }),
    loading: PropTypes.bool,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func.isRequired,
};

CalendarModal.defaultProps = {
    showModal: false,
    calendarModal: undefined,
    loading: false,
};

export default CalendarModal;
