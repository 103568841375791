import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import TemplateDefault from '_templates/TemplateDefault';
import Badge from '_atoms/Badge';
import ModalAction from '_organisms/ModalAction';
import Button, { TYPE } from '_atoms/Button';
import Alert from '_molecules/Alert';
import { editBookingByRequestStatus, cancellationConfirmation } from '_services/requestBooking';
import { createStatusVoucher } from '_utils/createStatus';
import { useCacheRequest } from '_hooks/useSwr';
import Erro from '_pages/erro';
import ReservaShimmer from '_pages/reserva/shimmer';
import VoucherPrint from '_pages/reserva/VoucherPrint';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_pages/reserva/index.module.scss';

const Reserva = () => {
    const { id } = useParams();
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [showModalConfirmCancel, setShowModalConfirmCancel] = useState(false);
    const [bookingStatus, setBookingStatus] = useState({ message: '', status: '' });
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({
        show: false,
        message: '',
        type: '',
    });
    const [errorMessage, setErrorMessage] = useState({});

    const history = useHistory();

    const { authentication } = useSelector((state) => state);

    const componentPrintRef = useRef(null);

    const handlePrintVoucher = useReactToPrint({
        content: () => componentPrintRef.current,
    });

    const {
        data: bookingRequestData,
        error: bookingRequestError,
        mutate,
    } = useCacheRequest(
        {
            method: 'GET',
            url: `/booking/${id}/`,
            headers: {
                Authorization: `JWT ${authentication}`,
                'Content-Type': 'application/json',
            },
        },
        null
    );

    const onToggleModalConfirm = () => {
        setShowModalConfirm(!showModalConfirm);
    };

    const onToggleModalCancel = () => {
        setShowModalCancel(!showModalCancel);
    };

    const onToggleModalConfirmCancelation = () => {
        setShowModalConfirmCancel(!showModalConfirmCancel);
    };

    const handleStatus = ({
        status,
        start_date: startDate,
        by_request_status: byRequestStatus,
    }) => {
        setBookingStatus(createStatusVoucher({ status, startDate, byRequestStatus }));
    };

    const updateByRequestStatus = async (type) => {
        setLoading(true);
        const data = {
            by_request_status: type,
            canceled_by: 'Hotel',
        };
        const jwt = authentication;
        setShowModalCancel(false);
        setShowModalConfirm(false);
        try {
            const byRequestStatus = await editBookingByRequestStatus(
                bookingRequestData.id,
                data,
                jwt
            );
            if (Object.prototype.hasOwnProperty.call(byRequestStatus, 'message')) {
                setAlert({
                    show: true,
                    message: 'Não foi possível atualizar o status da reserva!',
                    type: 'danger',
                });
                setLoading(false);
                return;
            }

            await mutate(() => {
                const changedBooking = { ...bookingRequestData, by_request_status: type };
                handleStatus(changedBooking);
                return changedBooking;
            });

            setAlert({
                show: true,
                message: 'Status da reserva atualizado!',
                type: 'success',
            });
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                message: 'Não foi possível atualizar o status da reserva!',
                type: 'danger',
            });
            setLoading(false);
        }
    };

    const getBooking = useCallback(async () => {
        try {
            if (bookingRequestData) {
                handleStatus(bookingRequestData);
                setLoading(false);
                return;
            }
            if (bookingRequestError) {
                history.push('/reservas');
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }, [bookingRequestData, bookingRequestError, history]);

    useEffect(() => {
        setTimeout(() => {}, 5000);
        getBooking();
    }, [getBooking]);

    const confirmCancelReservation = async () => {
        try {
            setLoading(true);
            await cancellationConfirmation(bookingRequestData.id, authentication);
            await mutate();
        } catch (error) {
            setErrorMessage(error);
        } finally {
            setLoading(false);
            setShowModalConfirmCancel(false);
        }
    };

    return (
        <>
            {!bookingRequestData && !loading && <Erro />}
            {loading && <ReservaShimmer />}

            {!loading && bookingRequestData && (
                <TemplateDefault
                    title={`Reserva #${id}`}
                    badge={
                        bookingRequestData ? (
                            <Badge message={bookingStatus.message} status={bookingStatus.status} />
                        ) : (
                            ''
                        )
                    }
                    buttons={
                        <Button type={TYPE.secondary} onClick={handlePrintVoucher}>
                            Imprimir
                        </Button>
                    }
                >
                    <div className={style.content}>
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}

                        <div ref={componentPrintRef}>
                            <VoucherPrint
                                bookingRequestData={bookingRequestData}
                                bookingStatus={bookingStatus}
                                onToggleModalConfirm={onToggleModalConfirm}
                                onToggleModalCancel={onToggleModalCancel}
                                onToggleModalConfirmCancelation={onToggleModalConfirmCancelation}
                                refreshVoucher={mutate}
                            />
                        </div>
                    </div>

                    {showModalConfirm && (
                        <ModalAction
                            showModal={showModalConfirm}
                            modalName="ConfirmBooking"
                            title="Confirmar disponibilidade para reserva sob-consulta?"
                            type="success"
                            submitLabel="Confirmar"
                            cancelLabel="Cancelar"
                            onCancel={onToggleModalConfirm}
                            onSubmit={() => updateByRequestStatus('Approved')}
                            onhandleCloseModal={onToggleModalConfirm}
                            closeOnOverlay
                            closeOnEsc
                        />
                    )}

                    {showModalCancel && (
                        <ModalAction
                            showModal={showModalCancel}
                            modalName="cancelBooking"
                            title="Recusar disponibilidade para reserva sob-consulta?"
                            type="danger"
                            submitLabel="Confirmar"
                            cancelLabel="Cancelar"
                            onCancel={onToggleModalCancel}
                            onSubmit={() => updateByRequestStatus('Refused')}
                            onhandleCloseModal={onToggleModalCancel}
                            closeOnOverlay
                            closeOnEsc
                        />
                    )}

                    {showModalConfirmCancel && (
                        <ModalAction
                            showModal={showModalConfirmCancel}
                            modalName="cancelBooking"
                            title="Confirmar cancelamento da reserva?"
                            type="success"
                            submitLabel="Confirmar"
                            cancelLabel="Cancelar"
                            onCancel={onToggleModalConfirmCancelation}
                            onSubmit={confirmCancelReservation}
                            onhandleCloseModal={onToggleModalConfirmCancelation}
                            closeOnOverlay
                            closeOnEsc
                        />
                    )}
                </TemplateDefault>
            )}
        </>
    );
};

export default Reserva;
