import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js';

import style from '_molecules/Charts/Line/index.module.scss';

const Line = ({ data, labels }) => {
    const chartRef = useRef();

    useEffect(() => {
        const handleChart = () => {
            const chart = new Chart(chartRef.current, {
                type: 'line',
                data: {
                    labels,
                    datasets: data,
                },
                options: {
                    maintainAspectRatio: false,
                    elements: {
                        line: {
                            borderWidth: 1,
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            tension: 0,
                        },
                        point: {
                            pointStyle: 'circle',
                            pointRadius: 0,
                            radius: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                        },
                    },
                    legend: {
                        align: 'end',
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    tooltips: {
                        enabled: false,
                    },
                    scales: {
                        yAxes: [
                            {
                                gridLines: {
                                    drawBorder: false,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1,
                                    padding: 10,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                type: 'time',
                                time: {
                                    displayFormats: {
                                        day: 'DD MMM',
                                    },
                                },
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    padding: 5,
                                    maxRotation: 0,
                                },
                            },
                        ],
                    },
                },
            });

            return chart;
        };
        handleChart();
    }, [labels, data]);

    return (
        <div className={style.chartContainer}>
            <canvas ref={chartRef} />
        </div>
    );
};

Line.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Line;
