import { portalAdmin } from '_services/apiConfig';

export const getFaredayByPeriod = async ({ accommodationId, ...params }, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fareday = await portalAdmin.get(`/accommodations/${accommodationId}/fare_days/`, {
        headers,
        params,
    });

    return fareday.data;
};

export const createFaredayByPeriod = async ({ accommodationId, roomId, ...params }, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fareday = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/${roomId}/fares_days/create_multiple/`,

        params,
        { headers }
    );

    return fareday.data;
};

export const editFaredayByPeriod = async (accommodationId, roomId, variantId, data, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fareday = await portalAdmin.patch(
        `/accommodations/${accommodationId}/rooms/${roomId}/variants/${variantId}/fare_days/update_multiples/`,
        data,
        { headers }
    );

    return fareday.data;
};

export const deleteFaredayByPeriod = async (accommodationId, roomId, variantId, data, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fareday = await portalAdmin.delete(
        `/accommodations/${accommodationId}/rooms/${roomId}/variants/${variantId}/fare_days/destroy_range/`,
        { data },
        { headers }
    );

    return fareday.data;
};

export const deleteFaredayByMultiplesIds = async (accommodationId, ids, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `accommodations/${accommodationId}/fare_days/destroy_multiples/`,
        {
            headers,
            data: { ids },
        }
    );

    return response.data;
};

export default {
    getFaredayByPeriod,
    createFaredayByPeriod,
    editFaredayByPeriod,
    deleteFaredayByPeriod,
    deleteFaredayByMultiplesIds,
};
