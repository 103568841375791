import React, { useEffect, useState, useCallback } from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import {
    CalendarioDisponibilidadeShimmer,
    CalendarioDisponibilidadeVariantsShimmer,
} from '_pages/disponibilidades/shimmer';
import CalendarDay from '_atoms/CalendarDay';
import CalendarDayShimmer from '_atoms/CalendarDay/shimmer';
import CalendarHeaderDay from '_atoms/CalendarHeaderDay';
import CalendarHeaderDayShimmer from '_atoms/CalendarHeaderDay/shimmer';
import CalendarDescriptionDay from '_atoms/CalendarDescriptionDay';
import CalendarDescriptionDayShimmer from '_atoms/CalendarDescriptionDay/shimmer';
import CalendarVariantDetails from '_atoms/CalendarVariantDetails';
import CalendarFilter from '_molecules/CalendarFilter';
import CalendarModal from '_organisms/CalendarModal';
import actions from '_redux/actions';
import { createBookingObjectToTable } from '_utils/bookingInitialize';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBookings } from '_services/requestBooking';
import {
    getCalendarRooms,
    getCalendarAvailability,
    getCalendarVariants,
} from '_services/requestRooms';
import axios from 'axios';
import ErrorHandler from '_molecules/ErrorHandler';
import Button, { TYPE } from '_atoms/Button/index';
import useUserPermission from '_hooks/useUserPermission';

import style from '_pages/disponibilidades/calendar.module.scss';

const CalendarioDisponibilidade = () => {
    const { accommodation, calendarAvailability, calendarModal, authentication } = useSelector(
        (state) => state
    );

    const [currentMonth, setCurrentMonth] = useState(moment(new Date()));
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availabilityRequest, setAvailabilityRequest] = useState([]);
    const [variantsRequest, setVariantsRequest] = useState([]);
    const [roomRequest, setRoomRequest] = useState(undefined);
    const [stateRoom, setStateRoom] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});

    const dispatch = useDispatch();

    const { userIsStaff } = useUserPermission();

    const defineTotalAllotments = (availability) =>
        availability.free +
        availability.booked_by_request +
        (availability.booked - availability.extra_allotments);

    const onlyHaveAvilabilityFree = (availability) =>
        availability.booked + availability.booked_by_request;

    const handleChangeMonth = (type) => {
        setStateRoom([]);
        dispatch(actions.cleanCalendar());

        switch (type) {
            case 'add':
                setCurrentMonth(moment(currentMonth).add(1, 'months'));
                break;
            case 'subtract':
                setCurrentMonth(moment(currentMonth).subtract(1, 'months'));
                break;

            default:
                setCurrentMonth(moment(currentMonth));
                break;
        }
    };

    const onHandleShowModal = useCallback(
        async (availability, room) => {
            try {
                setShowModal(true);
                setErrorMessage({});
                setLoading(true);
                dispatch(actions.setModalTitle({ date: availability.date, room: room.name }));
                if (
                    availability.date !== calendarModal.title.date ||
                    room.name !== calendarModal.title.room
                ) {
                    dispatch(actions.setModalBookings([]));
                    if (onlyHaveAvilabilityFree(availability)) {
                        const params = {
                            room_variant__room__accommodation__id: accommodation.id,
                            in_date: availability.date,
                            limit: 999,
                        };
                        const jwt = authentication;
                        const responseBookings = await getAllBookings(params, jwt);
                        const responseBookingRoomId = responseBookings.results.filter(
                            (item) => item.room_id === room.id
                        );
                        const bookings = createBookingObjectToTable(responseBookingRoomId);
                        dispatch(actions.setModalBookings(bookings));
                    }
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorMessage(error);
            }
        },
        [dispatch, accommodation.id, authentication, calendarModal.title]
    );

    const validShowAvailabilities = (room) =>
        Object.prototype.hasOwnProperty.call(room, 'availability');

    const createParams = useCallback(
        () => ({
            year: moment(currentMonth).year(),
            month: moment(currentMonth).month() + 1,
        }),
        [currentMonth]
    );

    const makeAvailabilitiesRequest = useCallback(
        async (rooms) => {
            const params = createParams();
            rooms.forEach(async (room) => {
                const roomAvailabilityRequest = axios.CancelToken.source();
                setAvailabilityRequest((availability) => [
                    ...availability,
                    roomAvailabilityRequest,
                ]);
                const jwt = authentication;
                const availability = await getCalendarAvailability(
                    accommodation.id,
                    room.id,
                    params,
                    roomAvailabilityRequest.token,
                    jwt
                );
                if (availability) {
                    await dispatch(actions.setCalendarAvailabilityRooms(availability));
                }
            });
        },
        [dispatch, accommodation.id, authentication, createParams]
    );

    const killAvailabilitiesRequest = useCallback(() => {
        availabilityRequest.forEach((availability) => {
            availability.cancel();
        });
        setAvailabilityRequest([]);
    }, [availabilityRequest]);

    const makeVariantsRequest = useCallback(
        async (rooms) => {
            const params = createParams();
            rooms.forEach(async (room) => {
                const roomVariantRequest = axios.CancelToken.source();
                setVariantsRequest((variants) => [...variants, roomVariantRequest]);
                const jwt = authentication;
                const variants = await getCalendarVariants(
                    accommodation.id,
                    room.id,
                    params,
                    roomVariantRequest.token,
                    jwt
                );
                if (variants) {
                    await dispatch(actions.setCalendarVariantsRooms(variants));
                }
            });
        },
        [dispatch, accommodation.id, authentication, createParams]
    );

    const killVariantsRequest = useCallback(() => {
        variantsRequest.forEach((variant) => {
            variant.cancel();
        });
        setVariantsRequest([]);
    }, [variantsRequest]);

    const killRoomRequest = useCallback(() => {
        if (roomRequest) {
            roomRequest.cancel();
        }
    }, [roomRequest]);

    const getRooms = useCallback(async () => {
        try {
            setErrorMessage({});
            const jwt = authentication;
            const cancelRequest = axios.CancelToken.source();
            setRoomRequest(cancelRequest);
            const rooms = await getCalendarRooms(accommodation.id, cancelRequest.token, jwt);
            setStateRoom(rooms.results);
            dispatch(actions.setCalendarRooms(rooms.results));
        } catch (error) {
            if (axios.isCancel(error)) {
                setErrorMessage(error);
            }
        }
    }, [dispatch, accommodation.id, authentication]);

    useEffect(() => {
        getRooms();
        return () => {
            dispatch(actions.setCalendarRooms([]));
        };
    }, [dispatch, getRooms, currentMonth]);

    useEffect(
        () => () => {
            killRoomRequest();
        },
        [killRoomRequest, currentMonth]
    );

    useEffect(() => {
        if (stateRoom.length > 0) {
            makeAvailabilitiesRequest(stateRoom);
        }
    }, [makeAvailabilitiesRequest, stateRoom]);

    useEffect(
        () => () => {
            if (stateRoom.length === 0 && availabilityRequest.length > 0) {
                killAvailabilitiesRequest();
            }
        },
        [killAvailabilitiesRequest, stateRoom, availabilityRequest]
    );

    useEffect(() => {
        if (stateRoom.length > 0) {
            makeVariantsRequest(stateRoom);
        }
    }, [makeVariantsRequest, stateRoom]);

    useEffect(
        () => () => {
            if (stateRoom.length === 0 && variantsRequest.length > 0) {
                killVariantsRequest();
            }
        },
        [killVariantsRequest, stateRoom, variantsRequest]
    );

    return (
        <>
            {Object.values(errorMessage).length > 0 && (
                <div className="row mb-3">
                    <div className="col-xl-12">
                        <ErrorHandler errors={errorMessage} />
                    </div>
                </div>
            )}

            <CalendarFilter
                currentMonth={currentMonth.format('MMMM/YYYY')}
                onChangeMonth={handleChangeMonth}
            />
            {calendarAvailability.length ? (
                calendarAvailability.map((room) => (
                    <section key={room.id} className={style.calendar}>
                        <div className={style.headerLine}>
                            <div className={style.header} />
                            {validShowAvailabilities(room) ? (
                                room.availability.map((availability) => (
                                    <CalendarHeaderDay
                                        key={availability.date}
                                        availability={availability}
                                    />
                                ))
                            ) : (
                                <CalendarHeaderDayShimmer />
                            )}
                        </div>
                        <div className={style.calendarLine}>
                            <div className={style.sectionAvailabilities}>
                                <div className={style.blockAvailability}>
                                    <div className={style.header}>
                                        <div className={style.blockRoomName}>{room.name}</div>
                                    </div>
                                    {validShowAvailabilities(room) ? (
                                        room.availability.map((availability) => (
                                            <CalendarDay
                                                key={availability.date}
                                                availability={availability}
                                                room={room}
                                                handleShowModal={onHandleShowModal}
                                            />
                                        ))
                                    ) : (
                                        <CalendarDayShimmer />
                                    )}
                                </div>
                                <div className={style.blockAvailabilityDescription}>
                                    <div className={style.header}>
                                        <p className={style.titleDescription}>Allotments</p>
                                    </div>
                                    {validShowAvailabilities(room) ? (
                                        room.availability.map((availability) => (
                                            <CalendarDescriptionDay
                                                key={availability.date}
                                                type="Allotments"
                                                value={defineTotalAllotments(availability)}
                                            />
                                        ))
                                    ) : (
                                        <CalendarDescriptionDayShimmer />
                                    )}
                                </div>
                                <div className={style.blockAvailabilityDescription}>
                                    <div className={style.header}>
                                        <p className={style.titleDescription}>Extra Allotments</p>
                                    </div>
                                    {validShowAvailabilities(room) ? (
                                        room.availability.map((availability) => (
                                            <CalendarDescriptionDay
                                                key={availability.date}
                                                type="Extra Allotments"
                                                value={availability.extra_allotments}
                                            />
                                        ))
                                    ) : (
                                        <CalendarDescriptionDayShimmer />
                                    )}
                                </div>
                                <div className={style.blockAvailabilityDescription}>
                                    <div className={style.header}>
                                        <p className={style.titleDescription}>Reservados</p>
                                    </div>
                                    {validShowAvailabilities(room) ? (
                                        room.availability.map((availability) => (
                                            <CalendarDescriptionDay
                                                key={availability.date}
                                                type="Reservados"
                                                value={availability.booked}
                                            />
                                        ))
                                    ) : (
                                        <CalendarDescriptionDayShimmer />
                                    )}
                                </div>
                                <div className={style.blockAvailabilityDescription}>
                                    <div className={style.header}>
                                        <p className={style.titleDescription}>Disponíveis</p>
                                    </div>
                                    {validShowAvailabilities(room) ? (
                                        room.availability.map((availability) => (
                                            <CalendarDescriptionDay
                                                key={availability.date}
                                                type="Disponíveis"
                                                value={availability.free}
                                            />
                                        ))
                                    ) : (
                                        <CalendarDescriptionDayShimmer />
                                    )}
                                </div>
                                <div className={style.blockAvailabilityDescription}>
                                    <div className={style.header}>
                                        <p className={style.titleDescription}>
                                            Sob consulta pendente
                                        </p>
                                    </div>
                                    {validShowAvailabilities(room) ? (
                                        room.availability.map((availability) => (
                                            <CalendarDescriptionDay
                                                key={availability.date}
                                                type="Sob consulta pendente"
                                                value={availability.booked_by_request}
                                            />
                                        ))
                                    ) : (
                                        <CalendarDescriptionDayShimmer />
                                    )}
                                </div>
                            </div>
                            <div className={style.buttonEditStopSale}>
                                <Button
                                    type={TYPE.link}
                                    onClick={() => window.open('https://tk37bkw6.paperform.co/')}
                                >
                                    EDITAR STOP DE VENDAS
                                </Button>
                            </div>

                            {userIsStaff && (
                                <div className={style.containerPriceMarkup}>
                                    <div className={style.contentPriceMarkup}>
                                        <div className={style.priceMarkup} />
                                        <p className={style.label}>Preço com markup</p>
                                    </div>
                                </div>
                            )}

                            {Object.prototype.hasOwnProperty.call(room, 'variants') ? (
                                <div className={style.blockVariants}>
                                    {room.variants.map((variant) => (
                                        <div key={variant.id} className={style.blockVariantDetail}>
                                            <div className={style.header}>
                                                <p className={style.variantName}>{variant.name}</p>
                                            </div>
                                            {variant.availability.map((availability) => (
                                                <CalendarVariantDetails
                                                    key={availability.date}
                                                    availability={availability}
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <CalendarioDisponibilidadeVariantsShimmer />
                            )}
                        </div>
                    </section>
                ))
            ) : (
                <CalendarioDisponibilidadeShimmer />
            )}

            <CalendarModal
                calendarModal={calendarModal}
                loading={loading}
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </>
    );
};

export default CalendarioDisponibilidade;
