const controlDropDownPosition = (dropDownElement) => {
    const areaWidthSize = document.body.clientWidth;

    if (dropDownElement) {
        const dropDownPositionX = dropDownElement.getBoundingClientRect().right;

        if (Math.floor(dropDownPositionX) > areaWidthSize) {
            return true;
        }
    }

    return false;
};

export default controlDropDownPosition;
