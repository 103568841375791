import React from 'react';
import InputShimmer from '_atoms/Input/shimmer';
import LabelShimmer from '_atoms/Label/shimmer';
import SectionBorder from '_molecules/SectionBorder';

const MainContentQuartoCadastroMinimoNoitesShimmer = () => (
    <SectionBorder>
        <div className="row mt-3">
            <div className="col-xl-6 col-md-6">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-6 col-md-6">
                <LabelShimmer />
                <InputShimmer />
            </div>
            <div className="col-xl-6 col-md-6">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
    </SectionBorder>
);

export default MainContentQuartoCadastroMinimoNoitesShimmer;
