import React from 'react';
import LabelShimmer from '_atoms/Label/shimmer';
import InputShimmer from '_atoms/Input/shimmer';

const MainContentMeuHotelDadosContatoShimmer = () => (
    <>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
    </>
);

export default MainContentMeuHotelDadosContatoShimmer;
