import React, { useState, useCallback, useMemo, useRef, PureComponent } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';

import TemplateDefault from '_templates/TemplateDefault';
import Button, { TYPE } from '_atoms/Button';
import Table, { COLUMN_SIZE } from '_molecules/Table';
import { getAllBookings } from '_services/requestBooking';
import { createBookingObjectToTable } from '_utils/bookingInitialize';
import style from '_pages/rooming-list/index.module.scss';

import logo from '_images/logo-viajar-faz-bem.svg';

class RoomingListPrint extends PureComponent {
    render() {
        const { columns, data, fetchData, loading, errorTableData } = this.props;
        return (
            <div className={style.content}>
                <div className={style.logo}>
                    <img src={logo} alt="viajar faz bem" />
                </div>
                <Table
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    columnClassName={style.column}
                    columnSize={COLUMN_SIZE.small}
                    errorLoadingData={errorTableData}
                    rowClassName={style.row}
                    disableSortBy
                />
            </div>
        );
    }
}

const RoomingList = () => {
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const { accommodation, authentication: jwt } = useSelector((state) => state);

    const [errorTableData, setErrorTableData] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const renderDate = (tableParams) => {
        const { value } = tableParams;
        if (!value) return null;

        return moment(value).format('DD/MM/YYYY');
    };

    const columns = [
        {
            Header: 'Tipo de reserva',
            accessor: 'reservation_type',
        },
        {
            Header: 'Reserva',
            accessor: 'id',
        },
        {
            Header: 'Hóspede',
            accessor: 'guests__name',
        },
        {
            Header: 'Checkin',
            accessor: 'start_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Checkout',
            accessor: 'end_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Dias',
            accessor: 'total_days',
        },
        {
            Header: 'Aptos',
            accessor: 'quantity',
        },
        {
            Header: 'Adultos',
            accessor: `search_parameters.rooms[0].adults`,
        },
        {
            Header: 'Crianças',
            accessor: 'search_parameters.rooms[0].children',
        },
        {
            Header: 'Quarto',
            accessor: 'room_name',
        },
        {
            Header: 'Tipo de quarto',
            accessor: 'room_variant_data.label',
        },
        {
            Header: 'Plano de refeição',
            accessor: 'meal_plan',
        },
    ];

    const startDateToSearchTo = useMemo(() => moment().format('DD/MM/YYYY'), []);
    const endDateToSearchTo = useMemo(() => moment().add(14, 'days').format('DD/MM/YYYY'), []);

    const getBookings = useCallback(async () => {
        const params = {
            limit: 9999,
            offset: 0,
            start_date_after: moment().format('YYYY-MM-DD'),
            start_date_before: moment().add(14, 'days').format('YYYY-MM-DD'),
            room_variant__room__accommodation__id: accommodation.id,
            ordering: 'start_date',
            status: 'Booked',
        };

        try {
            setErrorTableData(false);
            setLoading(true);
            const { results } = await getAllBookings(params, jwt);
            const bookings = createBookingObjectToTable(results);
            setData(bookings);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorTableData(true);
            console.error(error);
        }
    }, [accommodation.id, jwt]);

    return (
        <TemplateDefault title="Rooming list">
            <div className={style.container}>
                <div className={style.header}>
                    <p className={style.dateSearch}>
                        {startDateToSearchTo} a {endDateToSearchTo}
                    </p>
                    <Button
                        type={TYPE.secondary}
                        onClick={handlePrint}
                        className={style.buttonPrint}
                    >
                        Imprimir
                    </Button>
                </div>
                <div ref={componentRef}>
                    <RoomingListPrint
                        dateSearched={`${startDateToSearchTo} a ${endDateToSearchTo}`}
                        columns={columns}
                        data={data}
                        fetchData={getBookings}
                        loading={loading}
                        errorTableData={errorTableData}
                    />
                </div>
            </div>
        </TemplateDefault>
    );
};

RoomingListPrint.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorTableData: PropTypes.bool.isRequired,
};

export default RoomingList;
