import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Badge, { BADGE_SIZE } from '_atoms/Badge';

import style from '_molecules/HotelNameBadge/index.module.scss';

const HotelNameBadgeMobile = () => {
    const { id: hotelId, tradeMark } = useSelector((state) => ({
        ...state.accommodation,
    }));

    return (
        hotelId && (
            <div className={classNames(style.navbarHotelNameBadgeMobile)}>
                <Badge
                    badgeSize={BADGE_SIZE.big}
                    status="info"
                    message={`${tradeMark} - ${hotelId}`}
                />
            </div>
        )
    );
};

export default HotelNameBadgeMobile;
