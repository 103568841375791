import { portalAdmin } from '_services/apiConfig';

export const getAllCities = async (params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const cities = await portalAdmin
        .get(`/cities/`, { params, headers })
        .then((response) => response.data)
        .catch((err) => {
            throw new Error(err);
        });

    return cities;
};

export const searchCities = async (cityName, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const cities = await portalAdmin.get(`/cities/search/?term=${cityName}`, { headers });

    return cities.data;
};

export default {
    getAllCities,
};
