import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import Input from '_atoms/Input/Input';
import ButtonShimmer from '_atoms/Button/shimmer';
import Alert from '_molecules/Alert';
import { removeRoomVariants, updateRoomVariants } from '_services/requestRooms';
import ModalAction from '_organisms/ModalAction';
import CollapsibleContent from '_molecules/CollapsibleContent';
import CollapsibleContentShimmer from '_molecules/CollapsibleContent/shimmer';
import ErrorHandler from '_molecules/ErrorHandler';

import { useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({
    variants: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required('Obrigatório'),
            places: Yup.number()
                .integer('Valor inválido')
                .min(0, 'Valor inválido')
                .typeError('Apenas números')
                .required('Obrigatório'),
            single_beds: Yup.number()
                .integer('Valor inválido')
                .min(0, 'Valor inválido')
                .typeError('Apenas números')
                .required('Obrigatório'),
            double_beds: Yup.number()
                .integer('Valor inválido')
                .min(0, 'Valor inválido')
                .typeError('Apenas números')
                .required('Obrigatório'),
            sofa_beds: Yup.number()
                .integer('Valor inválido')
                .min(0, 'Valor inválido')
                .typeError('Apenas números')
                .required('Obrigatório'),
            children: Yup.number()
                .integer('Valor inválido')
                .min(0, 'Valor inválido')
                .typeError('Apenas números')
                .required('Obrigatório'),
        })
    ),
});

const MainContentQuartoVariantes = ({ room, updateRoom }) => {
    const [alert, setAlert] = useState({});
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [modalRemoveVariant, setModalRemoveVariant] = useState(false);
    const [loadingModalDeleteVariant, setLoadingModalDeleteVariant] = useState(false);
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const refVariantId = useRef();
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        variants: room.variants || [],
    };

    const handleUpdateVariant = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});
            let variantValues = values.variants.find(
                (variant) => variant.id === refVariantId.current
            );

            variantValues = {
                ...variantValues,
                room: room.id,
            };

            try {
                await updateRoomVariants(
                    accommodation.id,
                    room.id,
                    refVariantId.current,
                    variantValues,
                    jwt
                );
                setAlert({
                    show: true,
                    message: 'Variante atualizada com sucesso!',
                    type: 'success',
                });
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível atualizar a variante.',
                    type: 'danger',
                });
            }
            setLoading(false);
            updateRoom();
        },
        [accommodation.id, room.id, jwt, updateRoom]
    );

    const handleRemoveVariant = useCallback(async () => {
        setLoadingModalDeleteVariant(true);
        setErrorMessage({});
        try {
            await removeRoomVariants(accommodation.id, room.id, refVariantId.current, jwt);
            setAlert({
                show: true,
                message: 'Variante excluída com sucesso!',
                type: 'success',
            });
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível excluir a variante.',
                type: 'danger',
            });
        }
        updateRoom();
        setModalRemoveVariant(false);
        setLoadingModalDeleteVariant(false);
    }, [accommodation.id, jwt, room, updateRoom]);

    const handleModalRemoveVariant = useCallback(
        (variantId) => {
            refVariantId.current = variantId;
            setModalRemoveVariant(!modalRemoveVariant);
        },
        [modalRemoveVariant]
    );

    useEffect(() => {
        if (Object.keys(room).length) setLoading(false);
        if (room.variants && !room.variants.length) {
            setAlert({
                show: true,
                message: 'Quarto não possui variantes cadastradas.',
                type: 'message',
            });
        }
    }, [room]);

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }
        return () => setAlert({});
    }, [history.location.data]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                handleUpdateVariant(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, values } = formProps;

                return (
                    <>
                        <section className="col-xl-8 col-md-12">
                            {alert.show && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <Alert type={alert.type}>{alert.message}</Alert>
                                    </div>
                                </div>
                            )}
                            {Object.values(errorMessage).length > 0 && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <ErrorHandler errors={errorMessage} />
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                {loading ? (
                                    <>
                                        <CollapsibleContentShimmer />
                                    </>
                                ) : (
                                    <>
                                        {values.variants.map((variant, idx) => (
                                            <CollapsibleContent
                                                key={variant.id}
                                                title={variant.label}
                                            >
                                                <div className="row">
                                                    <div className="col-xl-12 col-md-12">
                                                        <Input
                                                            label="Nome da variante"
                                                            name={`variants.${idx}.label`}
                                                            placeholder="Digite o nome da variante"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xl-6 col-md-6">
                                                        <Input
                                                            label="Cama simples"
                                                            name={`variants.${idx}.single_beds`}
                                                            placeholder="Quantidade de camas simples"
                                                            inputMode="decimal"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-md-6">
                                                        <Input
                                                            label="Cama casal"
                                                            name={`variants.${idx}.double_beds`}
                                                            placeholder="Quantidade de camas de casal"
                                                            inputMode="decimal"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xl-6 col-md-6">
                                                        <Input
                                                            label="Berço"
                                                            name={`variants.${idx}.sofa_beds`}
                                                            placeholder="Quantidade de berços"
                                                            inputMode="decimal"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-md-6">
                                                        <Input
                                                            label="Max. crianças"
                                                            name={`variants.${idx}.children`}
                                                            placeholder="Quantidade de crianças"
                                                            inputMode="decimal"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3 justify-content-betwen">
                                                    <div className="col col-xl-5 col-md-4 col-sm-3 col-12 d-flex align-items-center">
                                                        <p>Lugares: {variant.places}</p>
                                                    </div>
                                                    <div className="col col-xl-7 col-md-8 col-sm-9 col-12">
                                                        <div className="row">
                                                            <div className="col d-flex justify-content-end">
                                                                <Button
                                                                    type={TYPE.link}
                                                                    size={BUTTON_SIZE.normal}
                                                                    onClick={() =>
                                                                        handleModalRemoveVariant(
                                                                            variant.id
                                                                        )
                                                                    }
                                                                >
                                                                    Remover variante
                                                                </Button>
                                                                <Button
                                                                    type={TYPE.secondary}
                                                                    size={BUTTON_SIZE.normal}
                                                                    onClick={() => {
                                                                        refVariantId.current =
                                                                            variant.id;
                                                                        return refVariantId.current;
                                                                    }}
                                                                    typeSubmit
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Salvar
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsibleContent>
                                        ))}
                                        <div className="container">
                                            <div
                                                className={`row ${
                                                    values.variants.length
                                                        ? 'justify-content-end'
                                                        : ''
                                                }`}
                                            >
                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        {room.isLoading ? (
                                                            <ButtonShimmer />
                                                        ) : (
                                                            <Button
                                                                type={LINE_TYPE.secondary}
                                                                size={BUTTON_SIZE.normal}
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/quarto/${room.id}/variante/novo`
                                                                    )
                                                                }
                                                                isBlock
                                                            >
                                                                Criar variante
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        </section>
                        {modalRemoveVariant && (
                            <ModalAction
                                showModal={modalRemoveVariant}
                                modalName="cancelBooking"
                                title="Deseja realmente deletar esta variante?"
                                type="danger"
                                submitLabel="Confirmar"
                                cancelLabel="Cancelar"
                                onCancel={handleModalRemoveVariant}
                                onSubmit={handleRemoveVariant}
                                onhandleCloseModal={handleModalRemoveVariant}
                                closeOnOverlay
                                closeOnEsc
                                isLoading={loadingModalDeleteVariant}
                            />
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

MainContentQuartoVariantes.propTypes = {
    room: PropTypes.shape({
        id: PropTypes.number,
        isLoading: PropTypes.bool,
        variants: PropTypes.arrayOf(PropTypes.object),
    }),
    updateRoom: PropTypes.func,
};

MainContentQuartoVariantes.defaultProps = {
    room: {},
    updateRoom: undefined,
};

export default MainContentQuartoVariantes;
