import React from 'react';

import style from '_pages/index/shimmer.module.scss';

const ShimmerCard = () => (
    <>
        <div className={style.cardItem} />
        <div className={style.cardItem} />
        <div className={style.cardItem} />
        <div className={style.cardItem} />
        <div className={style.cardItem} />
    </>
);

export default ShimmerCard;
