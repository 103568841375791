import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Label from '_atoms/Label';
import LineInstruction from '_molecules/LineInstruction';
import { INPUT_STATE as TEXTAREA_STATE, INPUT_SIZE as TEXTAREA_SIZE } from '_atoms/Input';

import inputStyles from '_atoms/Input/index.module.scss';
import style from '_atoms/TextArea/index.module.scss';

export const STATE = TEXTAREA_STATE;

export const SIZE = TEXTAREA_SIZE;

const TextArea = ({ label, className, size, state, disabled, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            {label && <Label>{label}</Label>}
            <div className={style.container}>
                <textarea
                    className={classNames(
                        inputStyles.input,
                        style.select,
                        { [inputStyles.disabled]: disabled },
                        { [inputStyles.danger]: meta.touched && meta.error },
                        size,
                        state,
                        className
                    )}
                    {...field}
                    {...props}
                />
            </div>
            {meta.touched && meta.error && (
                <LineInstruction className={inputStyles.errorMessage}>{meta.error}</LineInstruction>
            )}
        </>
    );
};

TextArea.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    state: PropTypes.string,
};

TextArea.defaultProps = {
    label: '',
    className: undefined,
    name: undefined,
    size: TEXTAREA_SIZE.normal,
    state: TEXTAREA_STATE.default,
    disabled: false,
};

export default TextArea;
