import React from 'react';
import classNames from 'classnames';
import InputShimmer from '_atoms/Input/shimmer';
import LabelShimmer from '_atoms/Label/shimmer';
import ButtonShimmer from '_atoms/Button/shimmer';
import TemplateDefault from '_templates/TemplateDefault';
import SectionBorder from '_molecules/SectionBorder';
import TarifaDiaShimmerVariants from '_pages/tarifa-dia/shimmerVariants';
import style from '_pages/tarifa-dia/shimmer.module.scss';

const TarifaDiaShimmer = () => (
    <TemplateDefault title="Novo tarifário">
        <div className={style.container}>
            <div className="row">
                <div className="col-xl-6 col-md-6">
                    <SectionBorder>
                        <div className="row mt-3">
                            <div className="col-xm-12 col-md-12">
                                <LabelShimmer />
                                <InputShimmer />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-xm-12 col-md-12">
                                <LabelShimmer />
                                <InputShimmer />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                <LabelShimmer />
                                <InputShimmer />
                            </div>
                            <div className="col-xm-6 col-md-6">
                                <LabelShimmer />
                                <InputShimmer />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-xm-12 col-md-12">
                                <LabelShimmer />
                                <InputShimmer />
                            </div>
                        </div>
                    </SectionBorder>
                </div>

                <div className="col-xl-6 col-md-6">
                    <TarifaDiaShimmerVariants />
                </div>

                <div className={classNames('row justify-content-end', style.blockButton)}>
                    <div className="row mt-3">
                        <div className="col-xl-12 col-md-12">
                            <ButtonShimmer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TemplateDefault>
);

export default TarifaDiaShimmer;
