import axios from 'axios';

export const portalAdmin = axios.create({
    baseURL: `${process.env.REACT_APP_URL_API_PORTAL_ADMIN}`,
    headers: {
        Authorization: `Token ${process.env.REACT_APP_TOKEN_API_PORTAL_ADMIN}`,
        'Content-Type': 'application/json',
    },
});

export const portal = axios.create({
    baseURL: `${process.env.REACT_APP_URL_API_PORTAL}`,
    headers: {
        Authorization: `Token ${process.env.REACT_APP_TOKEN_API_PORTAL}`,
        'Content-Type': 'application/json',
    },
});

export const auth = axios.create({
    baseURL: `${process.env.REACT_APP_URL_API_PORTAL_AUTH}`,
});

export default {
    portalAdmin,
    portal,
};
