import React from 'react';
import TemplateLogin from '_templates/TemplateLogin';
import PasswordCreate from '_organisms/PasswordCreate';

const RecuperacaoDeSenha = () => (
    <TemplateLogin title="Recuperação de senha">
        <PasswordCreate />
    </TemplateLogin>
);

export default RecuperacaoDeSenha;
