import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '_redux/actions';
import useUserPermission from '_hooks/useUserPermission';

import style from '_atoms/Dropdown/index.module.scss';

const Dropdown = ({ itens }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { userIsSuperUser } = useUserPermission();

    const logout = (href) => {
        if (href === '/sair') {
            dispatch(actions.cleanAccommodation());
            dispatch(actions.logout());
            history.push('/entrar');
        }
    };

    return (
        <nav className={style.items}>
            <ul className={style.ul}>
                {itens.map((item) => {
                    if (!userIsSuperUser && item.href === '/tarifas') {
                        return '';
                    }

                    return (
                        <li className={style.navItem} key={item.text}>
                            <Link
                                to={item.href}
                                className={style.navLink}
                                onClick={() => logout(item.href)}
                            >
                                {item.text}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

Dropdown.propTypes = {
    itens: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Dropdown;
