import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Input/index.module.scss';

export const INPUT_STATE = {
    default: style.default,
    success: style.success,
    warning: style.warning,
    danger: style.danger,
};

export const INPUT_SIZE = {
    normal: style.normal,
    small: style.small,
    large: style.large,
};

const Input = ({
    id,
    className,
    type,
    name,
    value,
    state,
    placeholder,
    size,
    onChange,
    isDisabled,
    inputRefCallback,
    isReadyOnly,
    onFocus,
    onBlur,
    maxLength,
    inputMode,
    autoCorrect,
}) => (
    <input
        id={id}
        type={type}
        ref={inputRefCallback}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isDisabled}
        className={classNames(
            style.input,
            { [style.disabled]: isDisabled },
            state,
            size,
            className
        )}
        readOnly={isReadyOnly}
        maxLength={maxLength}
        inputMode={inputMode}
        autoCorrect={autoCorrect}
    />
);

Input.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    state: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isDisabled: PropTypes.bool,
    inputRefCallback: PropTypes.func,
    isReadyOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    inputMode: PropTypes.string,
    autoCorrect: PropTypes.string,
};

Input.defaultProps = {
    id: undefined,
    className: undefined,
    type: 'text',
    name: undefined,
    value: undefined,
    state: INPUT_STATE.default,
    size: INPUT_SIZE.normal,
    placeholder: undefined,
    isDisabled: false,
    inputRefCallback: () => {},
    onFocus: () => {},
    onBlur: () => {},
    isReadyOnly: false,
    maxLength: undefined,
    inputMode: undefined,
    autoCorrect: undefined,
};

export default Input;
