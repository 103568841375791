import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import iconArrowDouble from '_images/icon-seta-dupla.svg';
import iconArrow from '_images/icon-seta.svg';

import style from '_molecules/PaginationTable/index.module.scss';

const PaginationTable = ({
    active,
    pages,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
}) => (
    <div className={style.pagination}>
        {canPreviousPage && (
            <button
                type="button"
                className={classNames(style.item, style.iconInvert)}
                onClick={() => {
                    gotoPage(0);
                }}
            >
                <img src={iconArrowDouble} alt="" />
            </button>
        )}
        {pages.length > 0 && (
            <button
                type="button"
                className={classNames(style.item, style.iconInvert, {
                    [style.disabledIcon]: !canPreviousPage,
                })}
                onClick={() => {
                    previousPage();
                }}
                disabled={!canPreviousPage}
            >
                <img src={iconArrow} alt="" />
            </button>
        )}

        {pages.map((page) => {
            if (page >= active - 1 && page <= active + 1) {
                return (
                    <button
                        type="button"
                        className={classNames(style.item, { [style.active]: active === page })}
                        onClick={() => {
                            gotoPage(page);
                        }}
                        key={page}
                    >
                        {page + 1}
                    </button>
                );
            }
            if (page >= active - 2 && page <= active + 2) {
                return (
                    <button
                        type="button"
                        className={classNames(
                            style.item,
                            { [style.active]: active === page },
                            style.hiddenMobile
                        )}
                        onClick={() => {
                            gotoPage(page);
                        }}
                        key={page}
                    >
                        {page + 1}
                    </button>
                );
            }

            return true;
        })}
        {pages.length > 3 && pages.length - 3 > active && canNextPage && (
            <button
                type="button"
                className={classNames(style.item, style.hiddenMobile)}
                onClick={() => {}}
            >
                ...
            </button>
        )}
        {canNextPage && active < pages.length - 4 && (
            <button
                type="button"
                className={classNames(style.item, style.hiddenMobile)}
                onClick={() => {
                    gotoPage(pages.length - 1);
                }}
            >
                {pages.length}
            </button>
        )}

        {pages.length > 0 && (
            <button
                type="button"
                className={classNames(style.item, { [style.disabledIcon]: !canNextPage })}
                onClick={() => {
                    nextPage();
                }}
                disabled={!canNextPage}
            >
                <img src={iconArrow} alt="" />
            </button>
        )}

        {canNextPage && (
            <button
                type="button"
                className={classNames(style.item)}
                onClick={() => {
                    gotoPage(pages.length - 1);
                }}
            >
                <img src={iconArrowDouble} alt="" />
            </button>
        )}
    </div>
);

PaginationTable.propTypes = {
    active: PropTypes.number.isRequired,
    pages: PropTypes.arrayOf(PropTypes.number).isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    gotoPage: PropTypes.func.isRequired,
    canPreviousPage: PropTypes.bool.isRequired,
    canNextPage: PropTypes.bool.isRequired,
};

export default PaginationTable;
