import { portalAdmin } from '_services/apiConfig';

export const getAccommodationDistance = async (accommodationId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .get(`/accommodations/${accommodationId}/distances/`, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const saveAccommodationDistance = async (accommodationId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .post(`/accommodations/${accommodationId}/distances/`, params, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const updateAccommodationDistance = async (accommodationId, distanceId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .patch(`/accommodations/${accommodationId}/distances/${distanceId}/`, params, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const removeAccommodationDistance = async (accommodationId, distanceId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .delete(`/accommodations/${accommodationId}/distances/${distanceId}/`, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};
