import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/RadioButton/index.module.scss';

export const RADIOBUTTON_SIZE = {
    normal: style.normal,
    small: style.small,
    large: style.large,
};

export const RADIOBUTTON_STATE = {
    default: style.default,
    danger: style.danger,
};

const RadioButton = ({
    name,
    labelText,
    id,
    value,
    className,
    size,
    onClick,
    checked,
    isDisabled,
    state,
}) => {
    const classChecked = checked ? style.inputChecked : style.inputNoChecked;

    return (
        <label
            htmlFor={id}
            className={classNames(style.label, size, { [style.disabled]: isDisabled }, className)}
        >
            <div className={style.blockRadioButton}>
                <input
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    className={style.radiobutton}
                    onClick={onClick}
                    disabled={isDisabled}
                />
                <div className={classNames(style.wrapperInput, classChecked, state)}>
                    <svg viewBox="0 0 24 24" className={classNames(style.svgArrow)}>
                        <circle cx="12" cy="12" r="3" fill="#fff" />
                    </svg>
                </div>
            </div>
            <span className={style.labelText}>{labelText}</span>
        </label>
    );
};

RadioButton.propTypes = {
    name: PropTypes.string,
    labelText: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    state: PropTypes.string,
};

RadioButton.defaultProps = {
    name: '',
    labelText: '',
    id: undefined,
    value: undefined,
    className: undefined,
    size: RADIOBUTTON_SIZE.normal,
    onClick: undefined,
    checked: false,
    isDisabled: false,
    state: RADIOBUTTON_STATE.default,
};

export default RadioButton;
