import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Alert from '_molecules/Alert';
import { errorHandler } from '_utils/errorHandler';

const ErrorHandler = ({ errors }) => {
    const errorResponse = useMemo(() => errorHandler(errors), [errors]);

    return (
        <Alert type="danger">
            <ul>
                {errorResponse.map((error) => (
                    <li key={error}>{error}</li>
                ))}
            </ul>
        </Alert>
    );
};

ErrorHandler.propTypes = {
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ErrorHandler;
