import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import { useSelector } from 'react-redux';
import moment from 'moment';

import TemplateDefault from '_templates/TemplateDefault';
import Badge from '_atoms/Badge';
import RadioButton from '_atoms/RadioButton';
import FilterBar from '_atoms/FilterBar';
import DropDownContainer from '_atoms/DropDownContainer';
import Table, { COLUMN_SIZE } from '_molecules/Table';
import DropDownDateRange from '_molecules/DropDownDateRange';
import DropDownFilterList from '_molecules/DropDownFilterList';
import { getAllBookings } from '_services/requestBooking';
import { createBookingObjectToTable } from '_utils/bookingInitialize';
import { useRadio } from '_hooks/checkBoxHooks';
import { useQueryString } from '_hooks/queryHooks';
import { useCacheRequest } from '_hooks/useSwr';
import Button, { TYPE } from '_atoms/Button';
import InputWithButton from '_molecules/InputWithButton';
import Alert from '_molecules/Alert';

import style from '_pages/reservas/index.module.scss';

const renderBadge = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;

    const statusList = {
        Approved: { message: 'Aprovada', status: 'success' },
        Booked: { message: 'Confirmada', status: 'success' },
        Canceled: { message: 'Cancelada', status: 'danger' },
        Refused: { message: 'Reprovada', status: 'danger' },
        Wait: { message: 'Aguardando', status: 'default' },
    };

    if (!statusList[value]) return null;

    return <Badge message={statusList[value].message} status={statusList[value].status} />;
};

const RenderOptions = (tableParams) => {
    const { authentication } = useSelector((state) => state);
    const { value } = tableParams;

    useCacheRequest(
        {
            method: 'GET',
            url: `/booking/${value}/`,
            headers: {
                Authorization: `JWT ${authentication}`,
                'Content-Type': 'application/json',
            },
        },
        {
            revalidateOnFocus: false,
        }
    );

    return (
        <Link
            to={`/reserva/${value}`}
            className={style.buttonOptions}
            title="Ver detalhes da reserva"
        >
            <MdRemoveRedEye className={style.icon} size={28} />
        </Link>
    );
};

const renderDate = (tableParams) => {
    const { value } = tableParams;
    if (!value) return null;

    return moment(value).format('DD/MM/YYYY');
};

const Reservas = () => {
    const { accommodation, authentication } = useSelector((state) => state);
    const history = useHistory();

    const [queryLimit] = useQueryString('limit');
    const [queryStartDateAfter, setQueryStartDateAfter] = useQueryString('start_date_after');
    const [queryStartDateBefore, setQueryStartDateBefore] = useQueryString('start_date_before');
    const [queryEndDateAfter, setQueryEndDateAfter] = useQueryString('end_date_after');
    const [queryEndDateBefore, setQueryEndDateBefore] = useQueryString('end_date_before');
    const [queryStatus, setQueryStatus] = useQueryString('status');
    const [queryByRequest] = useQueryString('by_request');
    const [queryByRequestStatus, setQueryByRequestStatus] = useQueryString('by_request_status');
    const [queryByRequestStatusToReview, setQueryByRequestStatusToReview] = useQueryString(
        'by_request_status_to_review'
    );
    const [queryCancellationConfirmation, setQueryCancellationConfirmation] = useQueryString(
        'cancellation_confirmation'
    );
    const [errorTableData, setErrorTableData] = useState(false);
    const [totalBookingsWaitingToConfirmation, setTotalBookingsWaitingToConfirmation] = useState(
        []
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [checkInDate, setCheckInDate] = useState([]);
    const [checkOutDate, setCheckOutDate] = useState([]);
    const [statusSend, setStatusSend] = useState([]);
    const [byRequestStatusSend, setByRequestStatusSend] = useState([]);

    const [checkBoxByRequestStatus, setCheckBoxByRequestStatus] = useRadio(() => {
        if (queryByRequestStatus && queryByRequestStatus.length) {
            return queryByRequestStatus.split(',');
        }
        return [];
    });

    const [checkBoxStatus, setCheckBoxStatus] = useRadio(() => {
        if (queryStatus && queryStatus.length) {
            return queryStatus.split(',');
        }
        return [];
    });

    const searchValue = useRef('');

    const columns = [
        {
            Header: 'Número da reserva',
            accessor: 'id',
        },
        {
            Header: 'Nome do hóspede',
            accessor: 'guests__name',
        },
        {
            Header: 'Data do checkin',
            accessor: 'start_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Data do checkout',
            accessor: 'end_date',
            renderColumn: renderDate,
        },
        {
            Header: 'Status da reserva',
            accessor: 'status',
            renderColumn: renderBadge,
        },
        {
            Header: 'Status sob-consulta',
            accessor: 'by_request_status',
            renderColumn: renderBadge,
        },
        {
            Header: 'Opções',
            accessor: 'row_id',
            renderColumn: RenderOptions,
            defaultCanSort: false,
            disableSortBy: true,
        },
    ];

    const getBookings = useCallback(
        async ({ pageSize = 10, pageIndex = 0, sortBy = [] }) => {
            const [sort] = sortBy.length ? sortBy : [{ id: 'created_at', desc: true }];

            const ordering = sort?.desc ? `-${sort.id}` : sort.id;

            const params = {
                limit: pageSize,
                offset: pageIndex * pageSize || '',
                start_date_after: queryStartDateAfter || checkInDate[0] || '',
                end_date_after: queryEndDateAfter || checkOutDate[0] || '',
                start_date_before: queryStartDateBefore || checkInDate[1] || '',
                end_date_before: queryEndDateBefore || checkOutDate[1] || '',
                room_variant__room__accommodation__id: accommodation.id,
                status: queryStatus || statusSend.join(','),
                ordering,
                by_request: queryByRequest,
                by_request_status:
                    queryByRequestStatusToReview ||
                    queryByRequestStatus ||
                    byRequestStatusSend.join(','),
                search: searchValue.current.value || '',
                cancellation_confirmation: queryCancellationConfirmation || '',
            };

            try {
                setErrorTableData(false);
                setLoading(true);
                const jwt = authentication;
                const { results, count } = await getAllBookings(params, jwt);
                const bookings = createBookingObjectToTable(results);
                setData(bookings);
                setPageNumber(Math.ceil(count / pageSize));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorTableData(true);
            }
        },
        [
            queryStartDateAfter,
            checkInDate,
            queryEndDateAfter,
            checkOutDate,
            queryStartDateBefore,
            queryEndDateBefore,
            accommodation.id,
            queryStatus,
            statusSend,
            queryByRequest,
            queryByRequestStatusToReview,
            queryByRequestStatus,
            byRequestStatusSend,
            queryCancellationConfirmation,
            authentication,
        ]
    );

    useEffect(() => {
        const getBookingsWaitingToCancelationConfirmation = async () => {
            try {
                const params = {
                    ordering: '-created_at',
                    limit: 10,
                    offset: 0,
                    room_variant__room__accommodation__id: accommodation.id,
                    cancellation_confirmation: false,
                    status: 'Canceled',
                    by_request_status: 'Approved',
                    start_date_after: moment().format('YYYY-MM-DD'),
                    start_date_before: moment().add(30, 'days').format('YYYY-MM-DD'),
                };

                const jwt = authentication;
                const bookings = await getAllBookings(params, jwt);
                setTotalBookingsWaitingToConfirmation(bookings);
            } catch {
                setTotalBookingsWaitingToConfirmation([]);
            }
        };

        getBookingsWaitingToCancelationConfirmation();
    }, [authentication, accommodation.id, setQueryStartDateAfter, setQueryStartDateBefore]);

    const CancelationConfirmation = () => {
        setQueryCancellationConfirmation('false');
        setQueryStatus('Canceled');
        setStatusSend(['Canceled']);
        setQueryStartDateAfter(moment().format('YYYY-MM-DD'));
        setQueryStartDateBefore(moment().add(30, 'days').format('YYYY-MM-DD'));
        setQueryByRequestStatusToReview('Approved');
    };

    const handleCheckIn = (dates) => {
        setCheckOutDate([]);
        setQueryStartDateAfter(dates[0]);
        setQueryStartDateBefore(dates[1]);
        setCheckInDate(dates);
    };

    const handleCheckOut = (dates) => {
        setCheckInDate([]);
        setQueryEndDateAfter(dates[0]);
        setQueryEndDateBefore(dates[1]);
        setCheckOutDate(dates);
    };

    const parseStatusName = useCallback(() => {
        if (checkBoxStatus[0] === 'Cancelation Confirmation') {
            setQueryCancellationConfirmation('false');
            setQueryStatus('Canceled');
            setStatusSend(['Canceled']);
            setQueryStartDateAfter(moment().format('YYYY-MM-DD'));
            setQueryStartDateBefore(moment().add(30, 'days').format('YYYY-MM-DD'));
            setQueryByRequestStatusToReview('Approved');

            return;
        }

        setQueryStatus(checkBoxStatus.join(','));
        setStatusSend(checkBoxStatus);
        setQueryCancellationConfirmation();
    }, [
        checkBoxStatus,
        setQueryStatus,
        setQueryCancellationConfirmation,
        setQueryByRequestStatusToReview,
        setQueryStartDateAfter,
        setQueryStartDateBefore,
    ]);

    const parseByRequestStatusName = useCallback(() => {
        setQueryByRequestStatus(checkBoxByRequestStatus.join(','));
        setByRequestStatusSend(checkBoxByRequestStatus);
    }, [checkBoxByRequestStatus, setQueryByRequestStatus]);

    const handlePageRoomingList = () => {
        history.push('/rooming-list');
    };

    const showBookingWaitingtoConfirmationAlert = useMemo(
        () =>
            totalBookingsWaitingToConfirmation?.count > 0 &&
            queryCancellationConfirmation !== 'false',
        [queryCancellationConfirmation, totalBookingsWaitingToConfirmation]
    );

    return (
        <TemplateDefault
            title="Reservas"
            subtitle="Listagem de reservas"
            buttons={
                <Button type={TYPE.secondary} onClick={handlePageRoomingList}>
                    Rooming list
                </Button>
            }
        >
            <div className={style.content}>
                {showBookingWaitingtoConfirmationAlert && (
                    <div className={style.alertContainer}>
                        <Alert type="warning">
                            <>
                                Você tem {totalBookingsWaitingToConfirmation.count} reservas
                                canceladas.{' '}
                                <button
                                    onClick={CancelationConfirmation}
                                    className={style.alertLink}
                                >
                                    Clique aqui para revisar.
                                </button>
                            </>
                        </Alert>
                    </div>
                )}

                <FilterBar>
                    <div>
                        <InputWithButton
                            placeholder="Busque por hóspede"
                            handleSearch={getBookings}
                            ref={searchValue}
                        />
                    </div>
                    <DropDownContainer>
                        <DropDownDateRange
                            label="Check-in"
                            handleDate={(dates) => handleCheckIn(dates)}
                            initialActiveState={queryStartDateAfter || queryStartDateBefore}
                        />
                        <DropDownDateRange
                            label="Check-out"
                            handleDate={(dates) => handleCheckOut(dates)}
                            initialActiveState={queryEndDateAfter || queryEndDateBefore}
                        />
                        <DropDownFilterList
                            label="Status"
                            onHandleFilterList={parseStatusName}
                            onResetFilter={() => {
                                setCheckBoxStatus([]);
                                setQueryStatus();
                                setStatusSend([]);
                                setQueryCancellationConfirmation();
                                setQueryStartDateAfter();
                                setQueryStartDateBefore();
                                setQueryByRequestStatusToReview();
                            }}
                            itemSelected={queryStatus && queryStatus.split(',').length}
                        >
                            <RadioButton
                                name="reserva"
                                labelText="Reservado"
                                checked={checkBoxStatus.some((item) => item === 'Booked')}
                                onChange={() => setCheckBoxStatus('Booked')}
                                value="Booked"
                            />
                            <RadioButton
                                name="reserva"
                                labelText="Cancelado"
                                checked={checkBoxStatus.some((item) => item === 'Canceled')}
                                onChange={() => setCheckBoxStatus('Canceled')}
                                value="Canceled"
                            />
                            <RadioButton
                                name="reserva"
                                labelText="A revisar"
                                checked={checkBoxStatus.some(
                                    (item) => item === 'Cancelation Confirmation'
                                )}
                                onChange={() => setCheckBoxStatus('Cancelation Confirmation')}
                                value="Cancelation Confirmation"
                            />
                        </DropDownFilterList>
                        <DropDownFilterList
                            label="Sob consulta"
                            onHandleFilterList={parseByRequestStatusName}
                            onResetFilter={() => {
                                setCheckBoxByRequestStatus([]);
                                setQueryByRequestStatus();
                                setByRequestStatusSend([]);
                            }}
                            itemSelected={
                                queryByRequestStatus && queryByRequestStatus.split(',').length
                            }
                        >
                            <RadioButton
                                name="byrequest"
                                checked={checkBoxByRequestStatus.some(
                                    (item) => item === 'Approved'
                                )}
                                labelText="Aprovado"
                                onChange={() => {
                                    setCheckBoxByRequestStatus('Approved');
                                }}
                                value="Approved"
                            />
                            <RadioButton
                                name="byrequest"
                                checked={checkBoxByRequestStatus.some((item) => item === 'Wait')}
                                labelText="Aguardando"
                                onChange={() => {
                                    setCheckBoxByRequestStatus('Wait');
                                }}
                                value="Wait"
                            />
                            <RadioButton
                                name="byrequest"
                                checked={checkBoxByRequestStatus.some((item) => item === 'Refused')}
                                labelText="Cancelada"
                                onChange={() => {
                                    setCheckBoxByRequestStatus('Refused');
                                }}
                                value="Refused"
                            />
                        </DropDownFilterList>
                    </DropDownContainer>
                </FilterBar>
                <Table
                    columns={columns}
                    data={data}
                    pageNumber={pageNumber}
                    fetchData={getBookings}
                    loading={loading}
                    columnClassName={style.column}
                    columnSize={COLUMN_SIZE.small}
                    rowsPerPage={parseInt(queryLimit, 10) || 10}
                    errorLoadingData={errorTableData}
                />
            </div>
        </TemplateDefault>
    );
};

export default Reservas;
