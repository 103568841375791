import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import SelectDropdown from '_molecules/SelectDropdown';
import Label from '_atoms/Label';

import style from '_hooks/useMobileNavigation.module.scss';

const useMobileNavigation = (menuArray) => {
    const history = useHistory();
    const [menuName, setMenuName] = useState('');

    useEffect(() => {
        const pageUrl = history.location.pathname.split('/').pop();
        const navItem = menuArray.find((item) => item.href === pageUrl);
        setMenuName(navItem.name);
    }, [history, menuArray]);

    const handleMobileNavigation = useCallback(
        (value) => {
            setMenuName(value.name);
            history.push(`${value.href}`);
        },
        [history]
    );

    const MobileNavigationComponent = useCallback(
        () => (
            <div className={style.navigationMenu}>
                <Label>Menu</Label>
                <SelectDropdown
                    data={menuArray}
                    value={menuName}
                    placeholder="Selecione uma opção"
                    onChange={() => {}}
                    onClickSelect={handleMobileNavigation}
                />
            </div>
        ),
        [menuArray, handleMobileNavigation, menuName]
    );

    return MobileNavigationComponent;
};

export default useMobileNavigation;
