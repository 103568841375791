import React from 'react';
import PropTypes from 'prop-types';
import Heading, { LEVEL } from '_atoms/Heading';
import Button, { TYPE } from '_atoms/Button';
import StatusBall, { STATUS } from '_atoms/StatusBall';
import { defineActiveOrInactive, defineLabelActiveOrInactive } from '_utils/createStatus';

import style from '_molecules/CardUserCompanion/index.module.scss';

const CardUserCompanion = ({ hotel, handleNext }) => (
    <div className={style.blockCompanion}>
        <Heading level={LEVEL.h5} className={style.blockTitle}>
            <div className={style.title}>
                <strong>{hotel.trade_mark || hotel.company_name}</strong>
                <span>{`${hotel.city.name} - ${hotel.city.state}`}</span>
            </div>
            <StatusBall
                className={style.status}
                status={STATUS[defineActiveOrInactive(hotel.is_active)]}
                label={defineLabelActiveOrInactive(hotel.is_active)}
            />
        </Heading>
        <Button className={style.button} type={TYPE.link} onClick={() => handleNext()}>
            Acessar
        </Button>
    </div>
);

CardUserCompanion.propTypes = {
    hotel: PropTypes.shape({
        company_name: PropTypes.string,
        trade_mark: PropTypes.string,
        city: PropTypes.shape({
            name: PropTypes.string,
            state: PropTypes.string,
        }),
        is_active: PropTypes.bool,
        id: PropTypes.number,
    }),
    handleNext: PropTypes.func,
};

CardUserCompanion.defaultProps = {
    hotel: {},
    handleNext: () => {},
};

export default CardUserCompanion;
