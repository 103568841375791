import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccommodationRoomById } from '_services/requestRooms';

const useQueryRoom = () => {
    const { id } = useParams();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [room, setRoom] = useState({});

    const getRoom = useCallback(async () => {
        if (!id) return;

        const roomResponse = await getAccommodationRoomById(accommodation.id, id, jwt);

        setRoom(roomResponse);
    }, [accommodation.id, id, jwt]);

    useEffect(() => {
        getRoom();
    }, [getRoom]);

    return [room, getRoom];
};

export default useQueryRoom;
