import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import TemplateDefault from '_templates/TemplateDefault';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Checkbox from '_atoms/Checkbox';
import RadioButton from '_atoms/RadioButton';
import FilterBar from '_atoms/FilterBar';
import DropDownContainer from '_atoms/DropDownContainer';
import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import TableSelectionRow, {
    COLUMN_SIZE as TABLESELECTION_COLUMN_SIZE,
} from '_molecules/Table/TableSelectionRow';
import Table, { COLUMN_SIZE } from '_molecules/Table';
import DropdownWithAction from '_molecules/DropdownWithAction';
import { getFaredayByPeriod, deleteFaredayByMultiplesIds } from '_services/requestFareday';
import { getAccommodationFares } from '_services/requestFares';
import DropDownDateRange from '_molecules/DropDownDateRange';
import DropDownFilterList from '_molecules/DropDownFilterList';
import Modal, { SIZE, INTERNAL_PADDING } from '_organisms/Modal';
import { getAccommodationRooms } from '_services/requestRooms';
import { useCheckBoxId, useCheckBox, useRadio } from '_hooks/checkBoxHooks';
import { formatCurrencyToShowInField } from '_helpers/inputMasks';
import useUserPermission from '_hooks/useUserPermission';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_pages/tarifas-dia/index.module.scss';

const TarifasDia = () => {
    const { accommodation, authentication } = useSelector((state) => state);
    const history = useHistory();
    const { userIsSuperUser } = useUserPermission();

    const [data, setData] = useState([]);
    const [date, setDate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [rooms, setRooms] = useState([]);
    const [variants, setVariants] = useState([]);
    const [fares, setFares] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [errorTableData, setErrorTableData] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const [alert, setAlert] = useState(() => {
        if (!history.location.data) {
            return {};
        }
        return history.location.data;
    });

    const [roomIds, setRoomIds] = useCheckBoxId();
    const [variantIds, setVariantIds] = useCheckBoxId();
    const [checkBoxRooms, setCheckBoxRooms] = useCheckBox();
    const [checkBoxVariants, setCheckBoxVariants] = useCheckBox();
    const [fareIds, setFareIds] = useCheckBoxId();
    const [checkBoxFares, setCheckBoxFares] = useRadio([]);
    const [rowSelected, setRowSelected] = useState([]);
    const totalItens = useRef();
    const pageIndexReference = useRef();

    const columns = [
        {
            Header: 'Quarto',
            accessor: `room_variant.room.name`,
        },
        {
            Header: 'Variante',
            accessor: `room_variant.label`,
        },
        {
            Header: 'Tipo de tarifa',
            accessor: 'fare.name',
        },
        {
            Header: 'Preço',
            accessor: 'price',
        },
        {
            Header: 'Data',
            accessor: 'dateConverted',
        },
    ];

    const getFareday = useCallback(
        async ({ pageSize, pageIndex }) => {
            const params = {
                limit: pageSize,
                offset: pageIndex * pageSize,
                start_date: date[0] || '',
                stop_date: date[1] || '',
                accommodationId: accommodation.id,
                room_variant__room_id: roomIds.join(',') || '',
                room_variant_id: variantIds.join(',') || '',
                fare_id: fareIds.join(',') || '',
            };

            try {
                setErrorTableData(false);
                setErrorMessage({});
                setLoading(true);
                const jwt = authentication;
                const { results, count } = await getFaredayByPeriod(params, jwt);
                const faresConverted = results.map((fare) => ({
                    ...fare,
                    dateConverted: moment(fare.date).format('DD/MM/YYYY'),
                    price: formatCurrencyToShowInField(fare.price),
                }));
                setData(faresConverted);
                setPageNumber(Math.ceil(count / pageSize));
                totalItens.current = count;
                pageIndexReference.current = pageIndex;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorTableData(true);
                setErrorMessage(error);
            }
        },
        [accommodation.id, authentication, date, roomIds, variantIds, fareIds]
    );

    const handleNewFare = () => {
        history.push('/tarifa-dia/novo');
    };

    const selectedRow = useCallback(
        (row) => {
            const rows = row
                .map((item) => data.filter((value) => value.id === Number(item)))
                .flat();

            setRowSelected(rows);
        },
        [data]
    );

    const onDeleteFareday = async () => {
        const rowsId = rowSelected.map((row) => row.id);

        setLoading(true);
        try {
            const jwt = authentication;
            await deleteFaredayByMultiplesIds(accommodation.id, rowsId, jwt);
            setShowModal(false);
            setAlert({
                show: true,
                message: 'Tarifas excluídas com sucesso!',
                type: 'success',
            });
            await getFareday({ pageSize: 10, pageIndex: pageIndexReference.current });
        } catch (err) {
            setShowModal(false);
            setAlert({
                show: true,
                message: 'Não foi possível excluir as tarifas selecionadas.',
                type: 'danger',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        const filterRoom = async () => {
            try {
                const jwt = authentication;
                const { results } = await getAccommodationRooms(accommodation.id, jwt);
                const allFares = await getAccommodationFares({ id: accommodation.id }, jwt);
                const roomName = [];
                const variantName = [];
                results.forEach((room) => {
                    roomName.push({ id: room.id, name: room.name });
                    variantName.push(
                        ...room.variants.map((variant) => ({ id: variant.id, name: variant.label }))
                    );
                });

                const fareName = allFares.results.map((fare) => ({ id: fare.id, name: fare.name }));

                setRooms(roomName);
                setVariants(variantName);
                setFares(fareName);
            } catch {
                setRooms([]);
                setVariants([]);
                setFares([]);
            }
        };

        filterRoom();
    }, [accommodation.id, authentication]);

    return (
        <TemplateDefault
            title="Tarifários"
            buttons={
                userIsSuperUser && (
                    <Button type={TYPE.secondary} onClick={handleNewFare}>
                        Adicionar tarifa
                    </Button>
                )
            }
        >
            <div className={style.content}>
                <div className={style.section}>
                    <FilterBar>
                        <div />
                        <DropDownContainer>
                            <DropDownDateRange
                                label="Data"
                                handleDate={(dates) => setDate(dates)}
                            />
                            <DropDownFilterList
                                label="Quarto"
                                onHandleFilterList={() => setRoomIds(checkBoxRooms)}
                                onResetFilter={() => {
                                    setRoomIds([]);
                                    setCheckBoxRooms([]);
                                }}
                                itemSelected={roomIds.length}
                            >
                                {rooms.map((room) => (
                                    <Checkbox
                                        key={room.id}
                                        name="room"
                                        labelText={room.name}
                                        onChange={() => setCheckBoxRooms(room)}
                                        checked={checkBoxRooms.some((item) => item.id === room.id)}
                                    />
                                ))}
                            </DropDownFilterList>
                            <DropDownFilterList
                                label="Variante"
                                onHandleFilterList={() => setVariantIds(checkBoxVariants)}
                                onResetFilter={() => {
                                    setVariantIds([]);
                                    setCheckBoxVariants([]);
                                }}
                                itemSelected={variantIds.length}
                            >
                                {variants.map((variant) => (
                                    <Checkbox
                                        key={variant.id}
                                        name="variant"
                                        labelText={variant.name}
                                        onChange={() => setCheckBoxVariants(variant)}
                                        checked={checkBoxVariants.some(
                                            (item) => item.id === variant.id
                                        )}
                                    />
                                ))}
                            </DropDownFilterList>
                            <DropDownFilterList
                                label="Tipo de tarifa"
                                onHandleFilterList={() => setFareIds(checkBoxFares)}
                                onResetFilter={() => {
                                    setFareIds([]);
                                    setCheckBoxFares([]);
                                }}
                                itemSelected={fareIds.length}
                            >
                                {fares.map((fare) => (
                                    <RadioButton
                                        key={fare.id}
                                        name="fare"
                                        checked={checkBoxFares.some((item) => item.id === fare.id)}
                                        labelText={fare.name}
                                        onChange={() => setCheckBoxFares(fare)}
                                        value={`${fare.id}`}
                                    />
                                ))}
                            </DropDownFilterList>
                        </DropDownContainer>
                    </FilterBar>
                    {userIsSuperUser && (
                        <DropdownWithAction
                            value={
                                rowSelected.length > 1
                                    ? `${rowSelected.length} tarifas selecionadas`
                                    : `${rowSelected.length} tarifa selecionada`
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    setShowModal(true);
                                    setAlert({ show: false, message: '', type: '' });
                                }}
                                disabled={!rowSelected.length}
                            >
                                Excluir tarifa
                            </button>
                        </DropdownWithAction>
                    )}
                    {alert.show && (
                        <div className="row mb-4">
                            <div className="col-xl-12">
                                <Alert type={alert.type}>{alert.message}</Alert>
                            </div>
                        </div>
                    )}
                    {Object.values(errorMessage).length > 0 && (
                        <div className="row mb-3">
                            <div className="col-xl-12">
                                <ErrorHandler errors={errorMessage} />
                            </div>
                        </div>
                    )}
                </div>
                <TableSelectionRow
                    columns={columns}
                    data={data}
                    pageNumber={pageNumber}
                    fetchData={getFareday}
                    loading={loading}
                    columnClassName={style.column}
                    selectedRow={selectedRow}
                    columnSize={TABLESELECTION_COLUMN_SIZE.small}
                    errorLoadingData={errorTableData}
                    rowsPerPage={200}
                    disableSortBy
                />
            </div>
            <Modal
                modalName="deleteConfirmation"
                onhandleCloseModal={() => setShowModal(false)}
                showModal={showModal}
                size={SIZE.large}
                internalPadding={INTERNAL_PADDING.fat}
            >
                <div className={style.containerModal}>
                    <div className="mb-3">
                        <Heading level={LEVEL.h2} className={style.header}>
                            Deseja excluir?
                        </Heading>
                        <p className={style.text}>
                            Tem certeza de que deseja apagar o Tarifas por dia selecionado? Todos os
                            seguintes objetos e seus itens relacionados serão removidos:
                        </p>
                    </div>
                    <div className="mb-3">
                        <Heading level={LEVEL.h4}>Resumo:</Heading>
                        <p>Tarifas por dia: {rowSelected.length}</p>
                    </div>
                    <div className="mb-3">
                        <Heading level={LEVEL.h4}>Detalhe:</Heading>
                        <div className={style.containerTable}>
                            <Table
                                columns={columns}
                                data={rowSelected}
                                pageNumber={rowSelected.length}
                                fetchData={() => {}}
                                loading={loading}
                                pagination={false}
                                columnSize={COLUMN_SIZE.small}
                                disableSortBy
                            />
                        </div>
                    </div>
                    <div className={classNames('row justify-content-end mt-3', style.blockButton)}>
                        <div className="col-auto">
                            <Button
                                type={TYPE.link_danger}
                                size={BUTTON_SIZE.normal}
                                onClick={() => setShowModal(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div className="col-auto pr-0">
                            <Button
                                type={TYPE.danger}
                                size={BUTTON_SIZE.normal}
                                onClick={onDeleteFareday}
                                typeSubmit
                            >
                                Sim, excluir tarifas
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </TemplateDefault>
    );
};

export default TarifasDia;
