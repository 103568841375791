import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dashboard from '_templates/Dashboard';
import Heading, { LEVEL } from '_atoms/Heading';
import ErrorBoundary from '_molecules/ErrorBoundary';

import style from '_templates/TemplateDefault/index.module.scss';

const TemplateDefault = ({ title, subtitle, children, badge, buttons }) => (
    <Dashboard>
        <div className={style.header}>
            <div className={style.blockTitle}>
                {title && (
                    <Heading level={LEVEL.h1} className={classNames(style.h1)}>
                        {title}
                        {badge && <div className={style.blockBadge}>{badge}</div>}
                    </Heading>
                )}
                {subtitle && <Heading level={LEVEL.h5}>{subtitle}</Heading>}
            </div>
            {buttons && <div className={style.blockButtons}>{buttons}</div>}
        </div>
        <section className={style.content}>
            <ErrorBoundary>{children}</ErrorBoundary>
        </section>
    </Dashboard>
);

TemplateDefault.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node.isRequired,
    badge: PropTypes.node,
    buttons: PropTypes.node,
};

TemplateDefault.defaultProps = {
    title: undefined,
    subtitle: undefined,
    badge: undefined,
    buttons: undefined,
};

export default TemplateDefault;
