import { SET_AUTHENTICATION } from '_redux/types';
import { decrypt } from '_utils/crypt';
import { getCookie } from '_utils/cookie';

const profile = decrypt(getCookie('portal_profile'));
const initialState = profile || {
    pk: '',
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    is_staff: false,
    is_superuser: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return action.payload.user;

        default:
            return state;
    }
};
