export const MENU_ITEMS = [
    {
        label: 'Dashboard',
        href: '/dashboard',
    },
    {
        label: 'Disponibilidade',
        href: '/disponibilidades',
    },
    {
        label: 'Reservas',
        href: '/reservas',
    },
    {
        label: 'Tarifários',
        href: '/tarifas-dia',
    },
];

export const PROFILE = {
    avatar: 'http://i.pravatar.cc/40',
    name: 'John Doe',
};

export const PROFILE_DROPDOWN_ITEMS = [
    {
        text: 'Trocar hotel',
        href: '/escolher-hotel',
    },
    {
        text: 'Dados do hotel',
        href: '/meu-hotel',
    },
    {
        text: 'Tipos de tarifa',
        href: '/tarifas',
    },
    {
        text: 'Quartos',
        href: '/quartos',
    },
    {
        text: 'Sair',
        href: '/sair',
    },
];

export const NAV_MENU_PROFILE = [
    { name: 'Dados cadastrais', href: '/meu-hotel/dados-cadastrais' },
    { name: 'Contatos', href: '/meu-hotel/contatos' },
    { name: 'Políticas e condições', href: '/meu-hotel/politicas-e-condicoes' },
    { name: 'Informações', href: '/meu-hotel/informacoes' },
    { name: 'Distâncias da acomodação', href: '/meu-hotel/distancias-da-acomodacao' },
    { name: 'Galeria de imagens', href: '/meu-hotel/galeria' },
];

export const PROFILE_ITEMS = [
    {
        id: 1,
        icon: 'dados-do-hotel.svg',
        name: 'Dados cadastrais',
        href: 'dados-cadastrais',
        description: 'Informações básicas de cadastro.',
    },
    {
        id: 2,
        icon: 'dados-de-contato.svg',
        name: 'Contatos',
        href: 'contatos',
        description: 'Dados de contato para assuntos de reservas.',
    },
    {
        id: 3,
        icon: 'politicas.svg',
        name: 'Políticas e condições',
        href: 'politicas-e-condicoes',
        description: 'Check-in, check-out e políticas personalizadas.',
    },
    {
        id: 4,
        icon: 'informacoes.svg',
        name: 'Informações',
        href: 'informacoes',
        description: 'Descrição e características para a sua página.',
    },
    {
        id: 5,
        icon: 'distancias.svg',
        name: 'Distâncias',
        href: 'distancias-da-acomodacao',
        description: 'Distância entre sua acomodação e pontos importantes.',
    },
    {
        id: 6,
        icon: 'galeria.svg',
        name: 'Galeria de imagens',
        href: 'galeria',
        description: 'Seleção de fotos para a sua página.',
    },
];

export const NAV_BEDROOMS = (roomId) => [
    { id: 0, name: 'Informações gerais', href: `/quarto/${roomId}/informacoes-gerais` },
    { id: 1, name: 'Características', href: `/quarto/${roomId}/caracteristicas` },
    { id: 2, name: 'Mínimo de noites', href: `/quarto/${roomId}/minimo-noites` },
    { id: 3, name: 'Deadlines', href: `/quarto/${roomId}/deadlines` },
    { id: 4, name: 'Variantes', href: `/quarto/${roomId}/variantes` },
    { id: 5, name: 'Galeria de imagens', href: `/quarto/${roomId}/galeria` },
];
