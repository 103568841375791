import {
    SET_CALENDAR_ROOMS,
    SET_CALENDAR_AVAILABILITY_ROOMS,
    SET_CALENDAR_VARIANTS_ROOMS,
    CLEAN_CALENDAR,
} from '_redux/types';

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CALENDAR_ROOMS:
            return action.payload;
        case SET_CALENDAR_AVAILABILITY_ROOMS:
            state.map((room) => {
                if (room.id === action.payload.room) {
                    /* eslint-disable-next-line */
                    room.availability = [];
                    room.availability.push(...action.payload.availability);
                }
                return true;
            });

            return [...state];
        case SET_CALENDAR_VARIANTS_ROOMS:
            state.map((room) => {
                if (room.id === action.payload.room) {
                    /* eslint-disable-next-line */
                    room.variants = [];
                    room.variants.push(...action.payload.variants);
                }
                return true;
            });

            return [...state];
        case CLEAN_CALENDAR:
            state.splice(0, state.length);
            return [...state];
        default:
            return state;
    }
};
