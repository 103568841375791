import React from 'react';

import style from '_atoms/CalendarHeaderDay/index-shimmer.module.scss';

const CalendarHeaderDayShimmer = () => {
    const renderMonth = () => {
        const blockShimmer = [];

        for (let i = 0; i < 17; i += 1) {
            blockShimmer.push(<div key={i} className={style.CalendarHeaderDay} />);
        }
        return blockShimmer;
    };

    return renderMonth();
};

export default CalendarHeaderDayShimmer;
