import { SET_ACCOMMODATION, CLEAN_ACCOMMODATION } from '_redux/types';
import { encrypt } from '_utils/crypt';
import { setCookie } from '_utils/cookie';
import { accommodationUpdate } from '_services/requestAccommodations';

const setAccommodation = (accommodation) => (dispatch) => {
    const { id, trade_mark: tradeMark } = accommodation;
    setCookie('portal_accommodation', encrypt({ id, tradeMark }));
    dispatch({ type: SET_ACCOMMODATION, payload: { id, tradeMark } });
};

const updateAccommodation = (accommodationId, jwt, params) => async (dispatch) => {
    try {
        const accommodation = await accommodationUpdate(accommodationId, params, jwt);
        dispatch(setAccommodation(accommodation));
    } catch (err) {
        throw Error(err);
    }
};

const cleanAccommodation = () => (dispatch) => {
    dispatch({ type: CLEAN_ACCOMMODATION });
};

export default {
    setAccommodation,
    updateAccommodation,
    cleanAccommodation,
};
