/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import TemplateDefault from '_templates/TemplateDefault';
import SectionBorder from '_molecules/SectionBorder';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Select from '_atoms/Select/Select';
import SelectShimmer from '_atoms/Select/shimmer';
import Input from '_atoms/Input/Input';
import Label from '_atoms/Label';
import Checkbox from '_atoms/Checkbox/Checkbox';
import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import { useHistory } from 'react-router-dom';
import DisponibilidadeShimmer from '_pages/disponibilidade/shimmer';
import MaskedInput from 'react-text-mask';
import { DATE_MASK } from '_helpers/inputMasks';
import { getAccommodationRooms } from '_services/requestRooms';
import { createAvailabilityByPeriod } from '_services/requestAvailability';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_pages/disponibilidade/index.module.scss';

const weekDays = [
    { id: '0', value: 'Segunda-feira' },
    { id: '1', value: 'Terça-feira' },
    { id: '2', value: 'Quarta-feira' },
    { id: '3', value: 'Quinta-feira' },
    { id: '4', value: 'Sexta-feira' },
    { id: '5', value: 'Sábado' },
    { id: '6', value: 'Domingo' },
];

const validationSchema = Yup.object().shape({
    start_date: Yup.string()
        .required('Obrigatório')
        .test('startDate', 'Data inválida', (value) => moment(value, 'YYYY-MM-DD').isValid()),
    end_date: Yup.string()
        .required('Obrigatório')
        .test('endDate', 'Data inválida', (value) => moment(value, 'YYYY-MM-DD').isValid())
        .test('validate', 'Data inferior a data inicial', function validateAfterDate(date) {
            const startDate = moment(this.parent.start_date, 'DD/MM/YYYY');
            const endDate = moment(date, 'DD/MM/YYYY');

            return moment(endDate).isSameOrAfter(startDate);
        }),
    quantity: Yup.number()
        .typeError('Deve ser um número')
        .required('Obrigatório')
        .positive('Deve ser maior que 0')
        .integer('Deve ser inteiro'),
});

const Disponibilidade = () => {
    const goOut = useRef(false);
    const [rooms, setRooms] = useState([]);
    const [alert, setAlert] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const history = useHistory();

    const { accommodation, authentication } = useSelector((state) => state);

    const initialValues = {
        room: rooms.length ? `${rooms[0].value}` : '',
        quantity: '',
        available: false,
        start_date: '',
        end_date: '',
        days_week_only: [],
    };

    const handleCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    const onHandleSubmit = async (values, resetForm, setFieldError) => {
        const params = {
            start_date: moment(values.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            end_date: moment(values.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            quantity: values.quantity,
            available: values.available,
            days_week_only: values.days_week_only,
        };

        setIsLoading(true);
        try {
            const jwt = authentication;
            await createAvailabilityByPeriod(accommodation.id, values.room, params, jwt);
            if (goOut.current) {
                history.push({
                    pathname: '/disponibilidades',
                    search: '?view=list',
                    data: {
                        show: true,
                        message: 'Disponibilidade cadastrada',
                        type: 'success',
                    },
                });
                return;
            }
            setAlert({
                show: true,
                message: 'Disponibilidade cadastrada com sucesso!',
                type: 'success',
            });
            resetForm();
        } catch (error) {
            setAlert({
                show: true,
                message: 'Não foi possível cadastrar a disponibilidade.',
                type: 'danger',
            });
            setErrorMessage(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const getRooms = async () => {
            try {
                const jwt = authentication;
                const { results } = await getAccommodationRooms(accommodation.id, jwt);

                if (!results.length) {
                    setRooms([{ value: 0, label: 'Nenhum quarto foi encontrado' }]);
                    return;
                }

                const roomsList = results.map((room) => ({
                    value: room.id,
                    label: room.name,
                    ...room,
                }));
                setRooms(roomsList);
            } catch (error) {
                setErrorMessage(error);
            }
        };
        getRooms();
    }, [accommodation.id, authentication]);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
                onHandleSubmit(values, resetForm, setFieldError);
                setSubmitting(false);
            }}
        >
            {(formProps) => {
                const { isSubmitting, handleChange, handleBlur, handleSubmit, submitForm } =
                    formProps;

                return (
                    <form onSubmit={handleSubmit}>
                        {isLoading ? (
                            <DisponibilidadeShimmer />
                        ) : (
                            <TemplateDefault title="Adicionar disponibilidade">
                                <div className={style.container}>
                                    {alert.show && (
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <Alert type={alert.type}>{alert.message}</Alert>
                                            </div>
                                        </div>
                                    )}
                                    {Object.values(errorMessage).length > 0 && (
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <ErrorHandler errors={errorMessage} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <SectionBorder>
                                                <Heading level={LEVEL.h6} className={style.header}>
                                                    Informações da disponibilidade
                                                </Heading>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        {!rooms.length ? (
                                                            <SelectShimmer />
                                                        ) : (
                                                            <Select
                                                                label="Quarto"
                                                                name="room"
                                                                quantityOptions={rooms}
                                                                onChange={handleChange}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        <Input
                                                            label="Quantidade"
                                                            name="quantity"
                                                            inputMode="decimal"
                                                            autoCorrect="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                        <MaskedInput
                                                            label="Data inicial"
                                                            mask={DATE_MASK}
                                                            name="start_date"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCorrect="off"
                                                            inputMode="decimal"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <MaskedInput
                                                            label="Data final"
                                                            mask={DATE_MASK}
                                                            name="end_date"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCorrect="off"
                                                            inputMode="decimal"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        <Label>
                                                            Tipo de solicitação de reserva
                                                        </Label>
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <div className="col-xm-12 col-md-12">
                                                        <Checkbox name="available">
                                                            Allotments
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        <Label>Apenas nos dias da semana</Label>
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <div
                                                        className={classNames(
                                                            'col-xm-12 col-md-12',
                                                            style.checkBoxContent
                                                        )}
                                                    >
                                                        {weekDays.map((weekday) => (
                                                            <div
                                                                className={style.checkBox}
                                                                key={weekday.id}
                                                            >
                                                                <Checkbox
                                                                    name="days_week_only"
                                                                    value={weekday.id}
                                                                >
                                                                    {weekday.value}
                                                                </Checkbox>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </SectionBorder>
                                            <div className="row justify-content-end">
                                                <div className="col-sm-12 col-md-auto">
                                                    <Button
                                                        type={TYPE.link}
                                                        size={BUTTON_SIZE.normal}
                                                        onClick={handleCancel}
                                                        className="button-col-6-multi-submit mt-3"
                                                    >
                                                        Voltar
                                                    </Button>
                                                    <Button
                                                        type={TYPE.secondary}
                                                        size={BUTTON_SIZE.normal}
                                                        className="button-col-6-multi-submit mt-3"
                                                        onClick={() => {
                                                            goOut.current = true;
                                                            submitForm();
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        Salvar disponibilidade e voltar
                                                    </Button>
                                                    <Button
                                                        type={TYPE.secondary}
                                                        size={BUTTON_SIZE.normal}
                                                        onClick={() => {}}
                                                        typeSubmit
                                                        className="button-col-6-multi-submit mt-3"
                                                        disabled={isSubmitting}
                                                    >
                                                        Salvar disponibilidade
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TemplateDefault>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default Disponibilidade;
