import { combineReducers } from 'redux';
import accommodationReducer from '_redux/reducers/accommodationReducer';
import authenticationReducer from '_redux/reducers/authenticationReducer';
import profileReducer from '_redux/reducers/profileReducer';
import calendarAvailabilityReducer from '_redux/reducers/calendarAvailabilityReducer';
import calendarModalReducer from '_redux/reducers/calendarModalReducer';

const rootReducer = combineReducers({
    accommodation: accommodationReducer,
    authentication: authenticationReducer,
    profile: profileReducer,
    calendarAvailability: calendarAvailabilityReducer,
    calendarModal: calendarModalReducer,
});

export default rootReducer;
