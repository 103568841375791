import { SET_AUTHENTICATION, SET_LOGOUT } from '_redux/types';
import { encrypt } from '_utils/crypt';
import { authentication } from '_services/requestSession';
import { setCookie, removeCookie } from '_utils/cookie';

const authenticate = (data) => async (dispatch) => {
    const { token, user } = await authentication(data);

    setCookie('portal_session', encrypt(token));
    setCookie('portal_profile', encrypt(user));

    dispatch({
        type: SET_AUTHENTICATION,
        payload: { token, user },
    });
};

const logout = () => (dispatch) => {
    removeCookie('portal_session');
    removeCookie('portal_profile');
    removeCookie('portal_accommodation');

    dispatch({
        type: SET_LOGOUT,
    });
};

export default { authenticate, logout };
