import React from 'react';
import PropTypes from 'prop-types';
import Input from '_atoms/Input';
import Button, { TYPE } from '_atoms/Button';
import DropdownMenu from '_molecules/DropdownMenu';
import classNames from 'classnames';

import style from '_molecules/SelectDropdown/index.module.scss';

export const ICONPOSITION = {
    right: style.iconRight,
    left: style.iconLeft,
};

const SelectDropdown = ({
    data,
    value,
    labelButton,
    placeholder,
    icon,
    iconEdit,
    iconRemove,
    iconPosition,
    onChange,
    onClickSelect,
    onClickEdit,
    onClickRemove,
    onClickButton,
    isReadOnly,
    isLoading,
}) => (
    <DropdownMenu
        className={style.dropDown}
        trigger={
            <>
                <Input
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    isReadyOnly={isReadOnly}
                    className={classNames(style.inputText, style.normal)}
                />
                <div className={style.arrow} />
            </>
        }
    >
        <ul className={style.dropdownContent}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <>
                    {data.length > 0 ? (
                        data.map((item) => (
                            <li key={item.id} className={iconPosition}>
                                <button
                                    className={style.btSelectGuest}
                                    type="button"
                                    onClick={() => onClickSelect(item)}
                                >
                                    {item.name}
                                </button>

                                {onClickEdit && (
                                    <button
                                        className={style.icon}
                                        type="button"
                                        onClick={() => onClickEdit(item)}
                                    >
                                        <img src={iconEdit} alt="editar" />
                                    </button>
                                )}
                                {onClickRemove && (
                                    <button
                                        className={style.ico}
                                        type="button"
                                        onClick={() => onClickRemove(item)}
                                    >
                                        <img src={iconRemove} alt="remove" />
                                    </button>
                                )}

                                {icon && (
                                    <div className={style.icon}>
                                        <img src={icon} alt="icon" />
                                    </div>
                                )}
                            </li>
                        ))
                    ) : (
                        <p>Nenhum registro encontrado.</p>
                    )}
                </>
            )}
            {labelButton && (
                <li className={style.buttonLi}>
                    <Button type={TYPE.link} onClick={onClickButton} className={style.btLink}>
                        {labelButton}
                    </Button>
                </li>
            )}
        </ul>
    </DropdownMenu>
);

SelectDropdown.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.string.isRequired,
    labelButton: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    iconEdit: PropTypes.string,
    iconRemove: PropTypes.string,
    iconPosition: PropTypes.string,
    onChange: PropTypes.func,
    onClickSelect: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickButton: PropTypes.func,
    isReadOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
};

SelectDropdown.defaultProps = {
    labelButton: undefined,
    placeholder: undefined,
    icon: undefined,
    iconEdit: undefined,
    iconRemove: undefined,
    iconPosition: undefined,
    onClickEdit: undefined,
    onClickRemove: undefined,
    onClickButton: () => {},
    onChange: () => {},
    isReadOnly: false,
    isLoading: false,
};

export default SelectDropdown;
