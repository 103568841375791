import React, { useState, useCallback } from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Input from '_atoms/Input/Input';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentMeuHotelDistanciaDaAcomodacaoCadastroShimmer from '_organisms/MainContentMeuHotelDistanciaDaAcomodacaoCadastro/shimmer';
import { saveAccommodationDistance } from '_services/requestAccommodationDistances';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentMeuHotelDistanciaDaAcomodacaoCadastro/index.module.scss';

const validationSchema = Yup.object().shape({
    distances: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Obrigatório'),
            distance: Yup.number().typeError('Valor inválido.').required('Obrigatório'),
        })
    ),
});

const MainContentMeuHotelDistanciaDaAcomodacaoCadastro = () => {
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [errorMessage, setErrorMessage] = useState({});

    const initialDistanceValue = {
        accommodation: accommodation.id,
        name: '',
        distance: '',
    };

    const initValues = {
        distances: [initialDistanceValue],
    };

    const handleSaveDistance = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});

            try {
                await saveAccommodationDistance(accommodation.id, values.distances, jwt);
                history.push(`/meu-hotel/distancias-da-acomodacao`);
            } catch (error) {
                setLoading(false);
                setAlert({
                    show: true,
                    message: 'Não foi possível realizar o cadastro',
                    type: 'danger',
                });
                setErrorMessage(error);
            }
        },
        [accommodation.id, jwt, history]
    );

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                handleSaveDistance(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, values } = formProps;

                return (
                    <>
                        <section className="col-xl-8 col-md-12">
                            {alert.show && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <Alert type={alert.type}>{alert.message}</Alert>
                                    </div>
                                </div>
                            )}
                            {Object.values(errorMessage).length > 0 && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <ErrorHandler errors={errorMessage} />
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <FieldArray
                                    name="distances"
                                    render={(helpers) => (
                                        <>
                                            {loading ? (
                                                <>
                                                    <MainContentMeuHotelDistanciaDaAcomodacaoCadastroShimmer />
                                                </>
                                            ) : (
                                                <>
                                                    {values.distances.map((distance, idx) => {
                                                        const distanceKey = idx;

                                                        return (
                                                            <SectionBorder key={distanceKey}>
                                                                <Heading
                                                                    level={LEVEL.h5}
                                                                    className={style.sectionTitle}
                                                                >
                                                                    Distância da acomodação #
                                                                    {idx + 1}
                                                                </Heading>

                                                                <div className="row">
                                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                                        <Input
                                                                            label="Nome"
                                                                            name={`distances.${idx}.name`}
                                                                        />
                                                                    </div>
                                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                                        <Input
                                                                            label="Distância (em km)"
                                                                            name={`distances.${idx}.distance`}
                                                                            inputMode="decimal"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SectionBorder>
                                                        );
                                                    })}
                                                    <div className="container">
                                                        <div
                                                            className={`row mt-3 ${
                                                                values.distances.length
                                                                    ? 'justify-content-end'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <div className="row">
                                                                <div className="col-xl-12 col-md-12">
                                                                    <div className="row">
                                                                        <div className="col d-flex">
                                                                            <Button
                                                                                type={TYPE.link}
                                                                                size={
                                                                                    BUTTON_SIZE.normal
                                                                                }
                                                                                onClick={() =>
                                                                                    history.goBack()
                                                                                }
                                                                            >
                                                                                Voltar
                                                                            </Button>

                                                                            <Button
                                                                                type={
                                                                                    LINE_TYPE.secondary
                                                                                }
                                                                                size={
                                                                                    BUTTON_SIZE.normal
                                                                                }
                                                                                onClick={() =>
                                                                                    helpers.push(
                                                                                        initialDistanceValue
                                                                                    )
                                                                                }
                                                                            >
                                                                                Criar distância da
                                                                                acomodação
                                                                            </Button>
                                                                            <Button
                                                                                type={
                                                                                    TYPE.secondary
                                                                                }
                                                                                size={
                                                                                    BUTTON_SIZE.normal
                                                                                }
                                                                                onClick={() => {}}
                                                                                typeSubmit
                                                                                disabled={
                                                                                    isSubmitting
                                                                                }
                                                                            >
                                                                                Salvar
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                />
                            </form>
                        </section>
                    </>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelDistanciaDaAcomodacaoCadastro;
