import { portalAdmin } from '_services/apiConfig';

export const getAccommodations = async (params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const accommodations = await portalAdmin
        .get(`/accommodations/`, { params }, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });

    return accommodations;
};

export const accommodationUpdate = async (id, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const accommodation = await portalAdmin
        .patch(`/accommodations/${id}/`, { ...params }, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });

    return accommodation;
};

export const accommodationFeatures = async (jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const features = await portalAdmin
        .get('/accommodation-features/', { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });

    return features;
};

export const accommodationSaveImages = async (accommodationId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .post(`/accommodations/${accommodationId}/images/create_bulk/`, params, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const accommodationDeleteImage = async (accommodationId, imageId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    await portalAdmin
        .delete(`/accommodations/${accommodationId}/images/${imageId}/`, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const accommodationListImages = async (accommodationId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .get(`/accommodations/${accommodationId}/images/`, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const accommodationUpdateImageCover = async (accommodationId, imageId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const params = {
        accommodation: accommodationId,
        is_cover: true,
    };

    return portalAdmin
        .patch(`/accommodations/${accommodationId}/images/${imageId}/`, params, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const getConditions = async (jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .get(`/conditions/`, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const saveAccommodationConditions = async (accommodationId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .post(`/accommodations/${accommodationId}/accommodation-conditions/`, params, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const removeAccommodationConditions = async (accommodationId, conditionId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .delete(`/accommodations/${accommodationId}/accommodation-conditions/${conditionId}/`, {
            headers,
        })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const updateAccommodationConditions = async (accommodationId, conditionId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .patch(
            `/accommodations/${accommodationId}/accommodation-conditions/${conditionId}/`,
            params,
            {
                headers,
            }
        )
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const getAccommodationConditions = async (accommodationId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .get(`/accommodations/${accommodationId}/accommodation-conditions/`, {
            headers,
        })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const getAccommodationById = (accommodationId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .get(`/accommodations/${accommodationId}/`, {
            headers,
        })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export const createAccomodation = async (jwt, params) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin
        .post(`/accommodations/`, params, {
            headers,
        })
        .then((response) => response.data)
        .catch((err) => {
            throw Error(err);
        });
};

export default {
    getAccommodations,
    accommodationUpdate,
    accommodationFeatures,
};
