import React from 'react';
import PropTypes from 'prop-types';

import style from '_atoms/DropDownContainer/index.module.scss';

const DropDownContainer = ({ children }) => <div className={style.container}>{children}</div>;

DropDownContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object])
        .isRequired,
};
export default DropDownContainer;
