import React from 'react';
import InputShimmer from '_atoms/Input/shimmer';
import TextAreaShimmer from '_atoms/TextArea/shimmer';
import LabelShimmer from '_atoms/Label/shimmer';

const MainContentMeuHotelInformacoesShimmer = () => (
    <>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <LabelShimmer />
                <TextAreaShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
    </>
);

export default MainContentMeuHotelInformacoesShimmer;
