import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/StatusBall/index.module.scss';

export const STATUS = {
    default: style.default,
    success: style.success,
    warning: style.warning,
    disabled: style.disabled,
    danger: style.danger,
};

const StatusBall = ({ status, label, className }) => (
    <div className={classNames(style.blockStatus, status, className)}>
        <span className={style.ball} />
        {label && <p className={style.p}>{label}</p>}
    </div>
);

StatusBall.propTypes = {
    status: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
};

StatusBall.defaultProps = {
    status: STATUS.default,
    label: undefined,
    className: undefined,
};

export default StatusBall;
