import React from 'react';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentMeuHotel from '_organisms/SideContentMeuHotel';
import MainContentMeuHotelContatos from '_organisms/MainContentMeuHotelContatos';
import { PROFILE_ITEMS } from '_config/settings';
import useMobileNavigation from '_hooks/useMobileNavigation';

const Contatos = () => {
    const MobileNavigationComponent = useMobileNavigation(PROFILE_ITEMS);

    return (
        <TemplateMeuHotelContent
            title="Contatos"
            description="Mantenha seus dados atualizados para receber todas as notificações"
            main={
                <>
                    <MobileNavigationComponent />
                    <MainContentMeuHotelContatos />
                </>
            }
            side={<SideContentMeuHotel currentPath="/meu-hotel/contatos" />}
            linkBack
        />
    );
};

export default Contatos;
