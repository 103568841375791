import { portalAdmin } from '_services/apiConfig';

const requestZipCode = async (zipcode, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.get(`/zipcode/${zipcode}/`, { headers });
    return response.data;
};

export default requestZipCode;
