import React from 'react';
import TemplateLogin from '_templates/TemplateLogin';
import LoginForm from '_organisms/LoginForm';

const Entrar = () => (
    <TemplateLogin title="Acesse sua conta">
        <LoginForm />
    </TemplateLogin>
);

export default Entrar;
