import React from 'react';
import PropTypes from 'prop-types';

import style from '_molecules/ErrorMessage/index.module.scss';

const ErrorMessage = ({ message }) => (
    <div className={style.container}>
        <p>{message}</p>
    </div>
);

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ErrorMessage;
