import { portalAdmin } from '_services/apiConfig';

export const getAccommodationFares = async ({ id, ...params }, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fares = await portalAdmin.get(`/accommodations/${id}/fares/`, { headers, params });

    return fares.data;
};

export const createAccommodationFares = async ({ id, ...data }, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fare = await portalAdmin.post(`/accommodations/${id}/fares/`, data, { headers });

    return fare.data;
};

export const editAccommodationFares = async (accommodationId, id, data, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fare = await portalAdmin.patch(`/accommodations/${accommodationId}/fares/${id}/`, data, {
        headers,
    });

    return fare.data;
};

export const getAccommodationFareById = async (accommodationId, id, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fare = await portalAdmin.get(`/accommodations/${accommodationId}/fares/${id}/`, {
        headers,
    });

    return fare.data;
};

export const deleteAccommodationFares = async (accommodationId, id, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fare = await portalAdmin.delete(`/accommodations/${accommodationId}/fares/${id}/`, {
        headers,
    });

    return fare.data;
};

export const getCancelPlansAccommodation = async (accommodationId, id, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.get(
        `/accommodations/${accommodationId}/fares/${id}/cancel_plans/`,
        { headers }
    );

    return response.data;
};

export const saveCancelPlansAccommodation = async (accommodationId, id, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.post(
        `/accommodations/${accommodationId}/fares/${id}/cancel_plans/`,
        params,
        { headers }
    );

    return response.data;
};

export const updateCancelPlansAccommodation = async (accommodationId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.patch(
        `/accommodations/${accommodationId}/fares/cancel_plans/update_bulk/`,
        params,
        {
            headers,
        }
    );

    return response.data;
};

export const removeCancelPlansAccommodation = async (accommodationId, fareId, ruleId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `/accommodations/${accommodationId}/fares/${fareId}/cancel_plans/${ruleId}/`,
        {
            headers,
        }
    );

    return response.data;
};

export default {
    getAccommodationFares,
    createAccommodationFares,
    editAccommodationFares,
    getAccommodationFareById,
    deleteAccommodationFares,
};
