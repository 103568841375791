import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTable, usePagination, useSortBy } from 'react-table';
import TableShimmer, { COLUMN_SIZE as SHIMMER_COLUMN_SIZE } from '_molecules/Table/shimmer';
import PaginationTable from '_molecules/PaginationTable';
import ErrorMessage from '_molecules/ErrorMessage';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSwapVert } from 'react-icons/md';

import style from '_molecules/Table/index.module.scss';

export const COLUMN_SIZE = {
    small: { component: style.small, shimmer: SHIMMER_COLUMN_SIZE.small },
    normal: { component: style.normal, shimmer: SHIMMER_COLUMN_SIZE.normal },
    large: { component: style.large, shimmer: SHIMMER_COLUMN_SIZE.large },
};

export const SHIMMER_SIZE = {
    small: 2,
    normal: 8,
    large: 15,
};

const Table = ({
    columns,
    data,
    rowStriped,
    columnStriped,
    columnClassName,
    columnSize,
    rowClassName,
    rowFeatured,
    rowFeaturedClassName,
    pageNumber,
    loading,
    fetchData,
    pagination,
    handlePagination,
    rowsPerPage,
    shimmerSize,
    errorLoadingData,
    disableSortBy,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: rowsPerPage > 100 ? 100 : rowsPerPage,
                sortBy: [{ id: 'created_at', desc: true }],
            },
            manualPagination: handlePagination,
            pageCount: pageNumber || data / rowsPerPage,
            disableSortBy,
            autoResetPage: false,
            autoResetSortBy: false,
        },
        useSortBy,
        usePagination
    );

    const tableStateUpdateRef = useRef(false);

    useEffect(() => {
        tableStateUpdateRef.current = true;
    }, [pageIndex]);

    useEffect(() => {
        tableStateUpdateRef.current = false;
    }, [sortBy]);

    useEffect(() => {
        if (fetchData) {
            fetchData({ pageIndex, pageSize, sortBy });
            if (!tableStateUpdateRef.current) {
                gotoPage(0);
            }
        }
        return () => {
            tableStateUpdateRef.current = false;
        };
    }, [fetchData, pageIndex, pageSize, gotoPage, sortBy]);

    return (
        <>
            <div className={style.container} {...getTableProps()} id="headerTable">
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()}>
                        <header className={classNames(style.header, style.block, rowClassName)}>
                            {headerGroup.headers.map((column) => (
                                <div
                                    className={classNames(
                                        style.header_item,
                                        {
                                            [style.columnStriped]: columnStriped,
                                        },
                                        columnClassName
                                    )}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    {column.render('Header')}

                                    {!disableSortBy && column.canSort && (
                                        <>
                                            {column.isSorted ? (
                                                <span>
                                                    {column.isSortedDesc ? (
                                                        <MdKeyboardArrowDown size={20} />
                                                    ) : (
                                                        <MdKeyboardArrowUp size={20} />
                                                    )}
                                                </span>
                                            ) : (
                                                <MdSwapVert size={20} />
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </header>
                    </div>
                ))}
                <div className={style.body} {...getTableBodyProps()}>
                    {errorLoadingData && (
                        <ErrorMessage message="Não foi possível carregar as informações." />
                    )}

                    {loading ? (
                        <TableShimmer columnSize={columnSize.shimmer} shimmerSize={shimmerSize} />
                    ) : (
                        <>
                            {!errorLoadingData && !page.length ? (
                                <ErrorMessage message="Nenhuma informação foi encontrada." />
                            ) : (
                                page.map((row) => {
                                    prepareRow(row);

                                    const featured = Object.values(row.values).find((value) =>
                                        rowFeatured.includes(value)
                                    )
                                        ? style.rowFeatured
                                        : '';
                                    return (
                                        <div
                                            className={classNames(
                                                style.content,
                                                style.block,

                                                {
                                                    [style.rowStriped]: rowStriped,
                                                },
                                                rowClassName,
                                                featured
                                            )}
                                            {...row.getRowProps()}
                                        >
                                            {row.cells.map((cell) => (
                                                <div
                                                    className={classNames(
                                                        style.content_item,
                                                        {
                                                            [style.columnStriped]: columnStriped,
                                                            [rowFeaturedClassName]: featured,
                                                        },
                                                        columnClassName,
                                                        columnSize.component
                                                    )}
                                                    data-name={cell.column.Header}
                                                    {...cell.getCellProps()}
                                                >
                                                    <>
                                                        {cell.column.renderColumn
                                                            ? cell.render('renderColumn')
                                                            : cell.render('Cell')}
                                                    </>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })
                            )}
                        </>
                    )}
                </div>
            </div>
            {page.length > 0 && pagination && !loading && (
                <PaginationTable
                    active={pageIndex}
                    pages={pageOptions}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                />
            )}
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnClassName: PropTypes.string,
    columnSize: PropTypes.shape({
        component: PropTypes.string,
        shimmer: PropTypes.string,
    }),
    rowClassName: PropTypes.string,
    cellClassName: PropTypes.string,
    rowStriped: PropTypes.bool,
    columnStriped: PropTypes.bool,
    rowFeatured: PropTypes.arrayOf(PropTypes.string),
    rowFeaturedClassName: PropTypes.string,
    fetchData: PropTypes.func,
    pageNumber: PropTypes.number,
    loading: PropTypes.bool,
    pagination: PropTypes.bool,
    handlePagination: PropTypes.bool,
    rowsPerPage: PropTypes.number,
    shimmerSize: PropTypes.number,
    errorLoadingData: PropTypes.bool,
    disableSortBy: PropTypes.bool,
};

Table.defaultProps = {
    rowStriped: false,
    columnStriped: false,
    columnClassName: '',
    columnSize: COLUMN_SIZE.normal,
    rowClassName: '',
    cellClassName: '',
    rowFeatured: [],
    rowFeaturedClassName: '',
    loading: false,
    pagination: true,
    handlePagination: true,
    pageNumber: undefined,
    fetchData: undefined,
    rowsPerPage: 10,
    shimmerSize: SHIMMER_SIZE.normal,
    errorLoadingData: false,
    disableSortBy: false,
};

export default Table;
