import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import search from '_images/search.svg';
import style from '_molecules/InputWithButton/index.module.scss';

const InputWithButton = forwardRef((props, ref) => {
    const { handleSearch, buttonPosition, image, label, buttonColor, placeholder } = props;

    const position = {
        left: style.borderLeft,
        right: style.borderRight,
    };

    const color = {
        primary: style.primary,
        secondary: style.secondary,
        success: style.success,
        danger: style.danger,
    };

    const handleForm = (e) => {
        e.preventDefault();
        handleSearch({});
    };

    return (
        <form onSubmit={handleForm}>
            <div
                className={classNames(style.boxInputSearch, {
                    [style.boxReverse]: buttonPosition === 'left',
                })}
            >
                <input
                    name="input"
                    placeholder={placeholder}
                    className={classNames(
                        style.input,
                        buttonPosition === 'right' ? position.left : position.right
                    )}
                    ref={ref}
                />
                <button
                    type="submit"
                    className={classNames(
                        style.button,
                        position[buttonPosition],
                        color[buttonColor]
                    )}
                    onClick={handleForm}
                >
                    {image && <img src={image} alt="buscar" className={style.image} />}
                    {label && label}
                </button>
            </div>
        </form>
    );
});

InputWithButton.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    buttonPosition: PropTypes.string,
    image: PropTypes.string,
    buttonColor: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

InputWithButton.defaultProps = {
    buttonPosition: 'right',
    image: search,
    buttonColor: 'secondary',
    label: null,
    placeholder: null,
};
export default InputWithButton;
