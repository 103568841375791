import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button, { TYPE } from '_atoms/Button';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentQuarto from '_organisms/SideContentQuarto';
import MainContentQuartoInformacoesGerais from '_organisms/MainContentQuartoInformacoesGerais';
import MainContentQuartoCaracteristicas from '_organisms/MainContentQuartoCaracteristicas';
import MainContentQuartoVariantes from '_organisms/MainContentQuartoVariantes';
import MainContentQuartoGaleriaImagens from '_organisms/MainContentQuartoGaleriaImagens';
import MainContentQuartoDeadlines from '_organisms/MainContentQuartoDeadlines';
import MainContentQuartoMinimoNoites from '_organisms/MainContentQuartoMinimoNoites';
import Label from '_atoms/Label';
import SelectDropdown from '_molecules/SelectDropdown';
import ModalAction from '_organisms/ModalAction';
import useQueryRoom from '_hooks/useQueryRoom';
import useRemoveRoom from '_hooks/useRemoveRoom';
import { NAV_BEDROOMS } from '_config/settings';

import style from '_pages/quarto/index.module.scss';

const Quarto = () => {
    const [room, getRoom] = useQueryRoom();
    const { id: roomId } = useParams();
    const history = useHistory();
    const title = useRef();
    const [component, setComponent] = useState('');
    const [handleRemove, loadingModal] = useRemoveRoom();
    const [modalRemove, setModalRemove] = useState(false);
    const [menuName, setMenuName] = useState('');

    useEffect(() => {
        const bedroomPage = () => {
            const page = history.location.pathname.split('/');
            switch (page[3]) {
                case 'informacoes-gerais':
                    title.current = room.name;
                    return <MainContentQuartoInformacoesGerais room={room} updateRoom={getRoom} />;

                case 'caracteristicas':
                    title.current = room.name;
                    return <MainContentQuartoCaracteristicas room={room} updateRoom={getRoom} />;

                case 'variantes':
                    title.current = room.name;
                    return <MainContentQuartoVariantes room={room} updateRoom={getRoom} />;

                case 'galeria':
                    title.current = room.name;
                    return <MainContentQuartoGaleriaImagens room={room} updateRoom={getRoom} />;

                case 'deadlines':
                    title.current = room.name;
                    return <MainContentQuartoDeadlines room={room} updateRoom={getRoom} />;

                case 'minimo-noites':
                    title.current = room.name;
                    return <MainContentQuartoMinimoNoites room={room} updateRoom={getRoom} />;

                default:
                    title.current = 'Quarto Novo';
                    return <MainContentQuartoInformacoesGerais room={room} updateRoom={getRoom} />;
            }
        };

        setComponent(bedroomPage());
    }, [getRoom, history.location, room]);

    const handleModal = useCallback(() => {
        setModalRemove(!modalRemove);
    }, [modalRemove]);

    useEffect(() => {
        if (history.location.data) {
            setModalRemove(false);
        }
    }, [history.location.data]);

    const navigation = useMemo(() => NAV_BEDROOMS(roomId), [roomId]);

    useEffect(() => {
        if (!room.id) return;
        const navItem = navigation.find((item) => item.href === history.location.pathname);
        setMenuName(navItem.name);
    }, [history, navigation, room.id]);

    const handleMobileNavigation = (value) => {
        setMenuName(value.name);
        history.push(`${value.href}`);
    };

    return (
        <>
            <TemplateMeuHotelContent
                title={title.current}
                main={
                    <>
                        {room.id && (
                            <div className={style.navigationMenu}>
                                <Label>Menu</Label>
                                <SelectDropdown
                                    data={navigation}
                                    value={menuName}
                                    placeholder="Selecione uma opção"
                                    onChange={() => {}}
                                    onClickSelect={handleMobileNavigation}
                                />
                            </div>
                        )}
                        {component}
                    </>
                }
                side={<SideContentQuarto />}
                buttons={
                    roomId && (
                        <Button type={TYPE.danger} onClick={handleModal}>
                            Excluir quarto
                        </Button>
                    )
                }
                linkBack
            />
            {modalRemove && (
                <ModalAction
                    showModal={modalRemove}
                    modalName="cancelBooking"
                    title="Deseja realmente deletar este quarto?"
                    type="danger"
                    submitLabel="Confirmar"
                    cancelLabel="Cancelar"
                    onCancel={handleModal}
                    onSubmit={handleRemove}
                    onhandleCloseModal={handleModal}
                    closeOnOverlay
                    closeOnEsc
                    isLoading={loadingModal}
                />
            )}
        </>
    );
};

export default Quarto;
