import React from 'react';
import CalendarDayShimmer from '_atoms/CalendarDay/shimmer';
import CalendarHeaderDayShimmer from '_atoms/CalendarHeaderDay/shimmer';
import CalendarDescriptionDayShimmer from '_atoms/CalendarDescriptionDay/shimmer';

import style from '_pages/disponibilidades/index-shimmer.module.scss';
import styleComponent from '_pages/disponibilidades/calendar.module.scss';

export const CalendarioDisponibilidadeVariantsShimmer = () => (
    <div className={style.calendarioDisponibilidadeVariant} />
);

export const CalendarioDisponibilidadeShimmer = () => (
    <section className={styleComponent.calendar}>
        <div className={styleComponent.headerLine}>
            <div className={styleComponent.header} />
            <CalendarHeaderDayShimmer />
        </div>
        <div className={styleComponent.calendarLine}>
            <div className={styleComponent.sectionAvailabilities}>
                <div className={styleComponent.blockAvailability}>
                    <div className={style.header} />
                    <CalendarDayShimmer />
                </div>
                <div className={styleComponent.blockAvailabilityDescription}>
                    <div className={style.header} />
                    <CalendarDescriptionDayShimmer />
                </div>
                <div className={styleComponent.blockAvailabilityDescription}>
                    <div className={style.header} />
                    <CalendarDescriptionDayShimmer />
                </div>
                <div className={styleComponent.blockAvailabilityDescription}>
                    <div className={style.header} />
                    <CalendarDescriptionDayShimmer />
                </div>
                <div className={styleComponent.blockAvailabilityDescription}>
                    <div className={style.header} />
                    <CalendarDescriptionDayShimmer />
                </div>
            </div>
            <CalendarioDisponibilidadeVariantsShimmer />
        </div>
    </section>
);

export default { CalendarioDisponibilidadeVariantsShimmer, CalendarioDisponibilidadeShimmer };
