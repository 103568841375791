import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import PasswordChecklist from 'react-password-checklist';
import Input from '_atoms/Input/Input';
import Heading, { LEVEL } from '_atoms/Heading';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Alert from '_molecules/Alert';
import { passwordCreate } from '_services/requestSession';
import { useHistory, useParams } from 'react-router-dom';
import LoginFormShimmer from '_organisms/LoginForm/shimmer';
import { useDispatch } from 'react-redux';
import actions from '_redux/actions';

import check from '_images/check.svg';

import style from '_organisms/PasswordCreate/index.module.scss';

const PasswordCreate = () => {
    const { uid, token } = useParams();
    const dispatch = useDispatch();

    const initValues = {
        password: '',
        confirmPassword: '',
    };
    const [success, setSuccess] = useState(false);
    const [counter, setCounter] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [canRedirect, setCanRedirect] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [isFormValid, setIsFormValid] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let timer;
        if (canRedirect) {
            timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        }
        return () => clearInterval(timer);
    }, [counter, canRedirect]);

    useEffect(() => {
        if (!counter) {
            history.push('/dashboard');
        }
    }, [counter, history]);

    const onHandleSubmit = async (values, resetForm) => {
        if (!isFormValid) {
            return;
        }

        setIsLoading(true);
        try {
            const data = {
                new_password1: values.password,
                new_password2: values.confirmPassword,
                uid,
                token,
            };

            await passwordCreate(data);

            dispatch(actions.cleanAccommodation());
            dispatch(actions.logout());

            setSuccess(true);
            resetForm();
            setIsLoading(false);
            setCanRedirect(true);
        } catch (error) {
            setAlert({
                show: true,
                message: 'Ocorreu algum problema ao tentar enviar a solicitação.',
                type: 'danger',
            });
            setIsLoading(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={initValues}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    onHandleSubmit(values, resetForm);
                    setSubmitting(false);
                }}
            >
                {(formProps) => {
                    const { isSubmitting, handleSubmit, values } = formProps;

                    return (
                        <>
                            {isLoading ? (
                                <LoginFormShimmer />
                            ) : (
                                <>
                                    {!success ? (
                                        <>
                                            {alert.show && (
                                                <div className="row mt-3 mb-3">
                                                    <div className="col-xl-12">
                                                        <Alert type={alert.type}>
                                                            {alert.message}
                                                        </Alert>
                                                    </div>
                                                </div>
                                            )}
                                            <form onSubmit={handleSubmit}>
                                                <div className="row mt-3 mb-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        <Input
                                                            label="Nova senha"
                                                            name="password"
                                                            placeholder="Digite sua nova senha"
                                                            autoCapitalize="off"
                                                            autoCorrect="off"
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3 mb-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        <Input
                                                            label="Repita a nova senha"
                                                            name="confirmPassword"
                                                            placeholder="Digite novamente sua nova senha"
                                                            autoCapitalize="off"
                                                            autoCorrect="off"
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                                {values.password && (
                                                    <div className="row mt-3 mb-3">
                                                        <div className="col-xl-12 col-md-12">
                                                            <p>A nova senha deve:</p>
                                                            <PasswordChecklist
                                                                rules={[
                                                                    'length',
                                                                    'number',
                                                                    'capital',
                                                                    'match',
                                                                ]}
                                                                minLength={8}
                                                                value={values.password}
                                                                valueAgain={values.confirmPassword}
                                                                onChange={(isValid) =>
                                                                    setIsFormValid(isValid)
                                                                }
                                                                iconSize={8}
                                                                validColor="#0cca81"
                                                                invalidColor="#e0294a"
                                                                className={style.passwordChecklist}
                                                                messages={{
                                                                    length: 'Possuir, pelo menos, 8 caracteres.',
                                                                    number: 'Incluir letras e números (a-z, 0-9)',
                                                                    capital:
                                                                        'Conter letras maiúsculas e minúsculas (A-Z,a-z).',
                                                                    match: 'Devem ser iguais.',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.large}
                                                    typeSubmit
                                                    isBlock
                                                    onClick={() => {}}
                                                    disabled={isSubmitting}
                                                >
                                                    Confirmar
                                                </Button>
                                            </form>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <img
                                                        className={style.check}
                                                        src={check}
                                                        alt="Ícone de confirmação"
                                                    />
                                                    <Heading
                                                        className={style.header}
                                                        level={LEVEL.h3}
                                                    >
                                                        Senha criada com sucesso!
                                                    </Heading>
                                                    <p className={style.paragraph}>
                                                        Você será redirecionado em {counter}s
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default PasswordCreate;
