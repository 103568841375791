import React from 'react';
import ButtonShimmer from '_atoms/Button/shimmer';
import InputShimmer from '_atoms/Input/shimmer';

const ModalNewUserShimmer = () => (
    <>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <InputShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <InputShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <ButtonShimmer />
            </div>
        </div>
    </>
);

export default ModalNewUserShimmer;
