import React, { useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { NAV_BEDROOMS } from '_config/settings';

import style from '_organisms/SideContentQuarto/index.module.scss';

const SideContentQuarto = () => {
    const { id: roomId } = useParams();
    const navigation = useMemo(() => NAV_BEDROOMS(roomId), [roomId]);

    return (
        <div className={style.sideBlock}>
            <ul>
                {navigation.map((item) => (
                    <li key={item.name} className={style.item}>
                        {!roomId ? (
                            <span className={style.disabledItem}>{item.name}</span>
                        ) : (
                            <NavLink
                                to={item.href}
                                activeClassName={style.activeItem}
                                isActive={(match) => {
                                    if (!match) return false;
                                    return item.href === match.url;
                                }}
                            >
                                {item.name}
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SideContentQuarto;
