import { auth, portal } from '_services/apiConfig';

const authentication = async (data) => {
    const userProfile = await auth
        .post('/login/', data)
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(error);
        });

    return userProfile;
};

const passwordReset = async (data) => {
    const res = await auth
        .post('/password/reset/', data)
        .then(() => true)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

const passwordCreate = async (data) => {
    const res = await auth
        .post('/password/reset/confirm/', data)
        .then(() => true)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

const getUsers = async (jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const users = await portal
        .get(`/users/`, { headers })
        .then((response) => response.data)
        .catch(() => {});

    return users;
};

const createUser = async (jwt, user) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const data = {
        first_name: user.name,
        is_active: true,
        email: user.email,
    };

    const users = await portal
        .post(`/users/`, data, { headers })
        .then((response) => response.data)
        .catch((error) => error.response);

    return users;
};

const deleteUser = async (id, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fare = await portal
        .delete(`/users/${id}/`, { headers })
        .then((response) => response.data)
        .catch((err) => {
            throw new Error(err);
        });

    return fare;
};

export { authentication, passwordReset, passwordCreate, getUsers, createUser, deleteUser };
