import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import moment from 'moment';

import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Input from '_atoms/Input/Input';
import ButtonShimmer from '_atoms/Button/shimmer';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentQuartoCadastroDeadlineShimmer from '_organisms/MainContentQuartoCadastroDeadline/shimmer';
import { createRoomDeadline } from '_services/requestRooms';
import { DATE_MASK } from '_helpers/inputMasks';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentQuartoCadastroDeadline/index.module.scss';

const validationSchema = Yup.object().shape({
    value: Yup.number()
        .integer('Valor inválido')
        .min(0, 'Valor inválido')
        .typeError('Apenas números')
        .required('Obrigatório'),
    lower: Yup.string()
        .required('Obrigatório')
        .test('startDate', 'Data inválida', (value) => moment(value, 'DD-MM-YYYY').isValid()),
    upper: Yup.string()
        .required('Obrigatório')
        .test('endDate', 'Data inválida', (value) => moment(value, 'DD-MM-YYYY').isValid())
        .test('validate', 'Data inferior a data inicial', function validateAfterDate(date) {
            const startDate = moment(this.parent.lower, 'DD/MM/YYYY');
            const endDate = moment(date, 'DD/MM/YYYY');

            return moment(endDate).isSameOrAfter(startDate);
        }),
});

const MainContentQuartoCadastroDeadline = ({ room }) => {
    const [alert, setAlert] = useState({});
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { id: roomId } = useParams();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        value: 1,
        lower: '',
        upper: '',
    };

    const onHandleSubmit = async (values, resetForm) => {
        setLoading(true);
        setErrorMessage({});
        try {
            const params = {
                room: roomId,
                value: values.value,
                period: {
                    lower: moment(values.lower, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    upper: moment(values.upper, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                },
            };

            await createRoomDeadline(accommodation.id, roomId, params, jwt);
            setAlert({
                show: true,
                message: 'Cadastro realizado com sucesso!',
                type: 'success',
            });
            resetForm();
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível realizar o cadastro',
                type: 'danger',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (Object.keys(room).length) setLoading(false);
    }, [room]);

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }
        return () => setAlert({});
    }, [history.location.data]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onHandleSubmit(values, resetForm);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, handleChange, handleBlur } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <>
                                {loading ? (
                                    <MainContentQuartoCadastroDeadlineShimmer />
                                ) : (
                                    <>
                                        <SectionBorder>
                                            <Heading
                                                level={LEVEL.h5}
                                                className={style.sectionTitle}
                                            >
                                                Deadline
                                            </Heading>

                                            <div className="row mt-3">
                                                <div className="col-xl-6 col-md-6">
                                                    <Input
                                                        label="Dias antes do check-in"
                                                        name="value"
                                                        placeholder="Valor"
                                                        inputMode="decimal"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                    <MaskedInput
                                                        label="Data inicial"
                                                        mask={DATE_MASK}
                                                        name="lower"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoCorrect="off"
                                                        inputMode="decimal"
                                                        placeholder="Data inicial"
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xm-6 col-md-6">
                                                    <MaskedInput
                                                        label="Data final"
                                                        mask={DATE_MASK}
                                                        name="upper"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoCorrect="off"
                                                        inputMode="decimal"
                                                        placeholder="Data final"
                                                        render={(ref, props) => (
                                                            <Input ref={ref} {...props} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col d-flex justify-content-end">
                                                    {loading ? (
                                                        <ButtonShimmer />
                                                    ) : (
                                                        <>
                                                            <Button
                                                                type={TYPE.link}
                                                                size={BUTTON_SIZE.normal}
                                                                onClick={() => history.goBack()}
                                                            >
                                                                Voltar
                                                            </Button>

                                                            <Button
                                                                type={TYPE.secondary}
                                                                size={BUTTON_SIZE.normal}
                                                                onClick={() => {}}
                                                                typeSubmit
                                                                disabled={isSubmitting}
                                                            >
                                                                Salvar
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </SectionBorder>
                                    </>
                                )}
                            </>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

MainContentQuartoCadastroDeadline.propTypes = {
    room: PropTypes.shape({
        id: PropTypes.number,
        isLoading: PropTypes.bool,
    }),
};

MainContentQuartoCadastroDeadline.defaultProps = {
    room: {},
};

export default MainContentQuartoCadastroDeadline;
