import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Input from '_atoms/Input';
import DropdownMenu from '_molecules/DropdownMenu';
import classNames from 'classnames';

import style from '_molecules/DropdownWithAction/index.module.scss';

const DropdownWithAction = ({
    value,
    placeholder,
    onChange,
    onSelectAll,
    children,
    selectAllLabel,
    className,
}) => (
    <div className={style.container}>
        <p>Ações:</p>
        <div className={classNames(style.dropDownContainer, className)}>
            <DropdownMenu
                className={style.dropDown}
                trigger={
                    <>
                        <Input
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            className={classNames(style.inputText, style.normal)}
                            isReadyOnly
                        />
                        <div className={style.arrow} />
                    </>
                }
            >
                <ul className={style.dropdownContent}>
                    {Children.map(children, (item) => (
                        <li className={style.btAction}>{item}</li>
                    ))}
                </ul>
            </DropdownMenu>
        </div>
        {selectAllLabel && (
            <div className={style.faresBlock}>
                <button className={style.btSelection} type="button" onClick={onSelectAll}>
                    {selectAllLabel}
                </button>
            </div>
        )}
    </div>
);

DropdownWithAction.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    selectAllLabel: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]),
    onSelectAll: PropTypes.func,
    className: PropTypes.string,
};

DropdownWithAction.defaultProps = {
    value: undefined,
    placeholder: undefined,
    selectAllLabel: undefined,
    children: undefined,
    onSelectAll: () => {},
    onChange: () => {},
    className: undefined,
};

export default DropdownWithAction;
