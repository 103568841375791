import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from '_atoms/Icon/index.module.scss';

export const THEME = {
    filled: style.filled,
    outlined: style.outlined,
};

export const TYPE = {
    primary: style.primary,
    secondary: style.secondary,
    white: style.white,
    gray: style.gray,
};

const Icon = ({ image, alt, size, type, theme, onClick, classname }) => (
    <div className={classNames(style.iconContainer, theme, type, classname)}>
        {onClick ? (
            <button type="button" className={style.button} onClick={onClick}>
                <img src={image} alt={alt} width={size} className={style.icon} />
            </button>
        ) : (
            <img src={image} alt={alt} width={size} className={style.icon} />
        )}
    </div>
);

Icon.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    theme: PropTypes.string,
    onClick: PropTypes.func,
    classname: PropTypes.string,
};

Icon.defaultProps = {
    image: '',
    alt: '',
    size: '',
    type: '',
    theme: '',
    onClick: undefined,
    classname: '',
};

export default Icon;
