/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TemplateDefault from '_templates/TemplateDefault';
import SectionBorder from '_molecules/SectionBorder';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Select from '_atoms/Select/Select';
import Input from '_atoms/Input/Input';
import Label from '_atoms/Label';
import Checkbox from '_atoms/Checkbox/Checkbox';
import RadioButton from '_atoms/RadioButton/RadioButton';
import Heading, { LEVEL } from '_atoms/Heading';
import SelectShimmer from '_atoms/Select/shimmer';
import Alert from '_molecules/Alert';
import TarifaDiaShimmerVariants from '_pages/tarifa-dia/shimmerVariants';
import TarifaDiaShimmer from '_pages/tarifa-dia/shimmer';
import MaskedInput from 'react-text-mask';
import { DATE_MASK, currencyNumberOnly } from '_helpers/inputMasks';
import { getAccommodationFares } from '_services/requestFares';
import { getAccommodationRooms } from '_services/requestRooms';
import { createFaredayByPeriod } from '_services/requestFareday';
import CurrencyInput from '_molecules/CurrencyInput';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_pages/tarifa-dia/index.module.scss';

const weekDays = [
    { id: '0', value: 'Segunda-feira' },
    { id: '1', value: 'Terça-feira' },
    { id: '2', value: 'Quarta-feira' },
    { id: '3', value: 'Quinta-feira' },
    { id: '4', value: 'Sexta-feira' },
    { id: '5', value: 'Sábado' },
    { id: '6', value: 'Domingo' },
];

Yup.addMethod(Yup.object, 'shoudSendVariant', function shoudSendVariant(defaultValue = undefined) {
    return this.transform((values) => {
        if (!values) {
            return true;
        }

        if (values.shouldSendVariant) {
            return values;
        }

        return undefined;
    }).default(defaultValue);
});

const validationSchema = Yup.object().shape({
    start_date: Yup.string()
        .required('Obrigatório')
        .test('startDate', 'Data inválida', (value) => moment(value, 'YYYY-MM-DD').isValid())
        .test('startDateBeforeToday', 'Data inicial não pode ser anterior a hoje', (date) => {
            const today = moment(new Date().getDate(), 'DD/MM/YYYY');
            const startDate = moment(date, 'DD/MM/YYYY');

            return moment(startDate).isSameOrAfter(today);
        }),
    end_date: Yup.string()
        .required('Obrigatório')
        .test('endDate', 'Data inválida', (value) => moment(value, 'YYYY-MM-DD').isValid())
        .test('validate', 'Data inferior a data inicial', function validateAfterDate(date) {
            const startDate = moment(this.parent.start_date, 'DD/MM/YYYY');
            const endDate = moment(date, 'DD/MM/YYYY');

            return moment(endDate).isSameOrAfter(startDate);
        }),
    price: Yup.string().when('variant_type', {
        is: 'all',
        then: Yup.string().required('Obrigatório'),
    }),
    markup_price: Yup.string().when('variant_type', {
        is: 'all',
        then: Yup.string().required('Obrigatório'),
    }),
    variants: Yup.array().when('variant_type', {
        is: 'single',
        then: Yup.array(
            Yup.object({
                price: Yup.string().required('Obrigatório'),
                markup_price: Yup.string().required('Obrigatório'),
            }).shoudSendVariant()
        ),
    }),
});

const TarifaDia = () => {
    const goOut = useRef(false);

    const [alert, setAlert] = useState({});
    const [fares, setFares] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});
    const [loadingVariants, setLoadingVariants] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const variants = useRef([]);
    const roomObject = useRef();
    const fareObject = useRef();

    const history = useHistory();

    const { accommodation, authentication } = useSelector((state) => state);

    const initialValues = {
        fare: fares.length ? `${fares[0].value}` : '',
        room: rooms.length ? `${rooms[0].value}` : '',
        start_date: '',
        end_date: '',
        days_week_only: [],
        variant_type: 'single',
        price: '0',
        markup_price: '0',
        variants: variants.current,
    };

    const oneVariantToMany = useCallback(({ price, markup_price: markupPrice }) => {
        const allVariants = variants.current.map((variant) => ({
            ...variant,
            price,
            markup_price: markupPrice,
            shouldSendVariant: true,
        }));

        return allVariants;
    }, []);

    const handleCancel = useCallback(() => {
        history.push('/tarifas-dia');
    }, [history]);

    const onHandleSubmit = useCallback(
        async (values, resetForm) => {
            const formatVariantsValue =
                values.variant_type === 'all' ? oneVariantToMany(values) : values.variants;

            const formatPriceToSave = formatVariantsValue.map((variant) => ({
                ...variant,
                price: currencyNumberOnly(variant.price),
                markup_price: currencyNumberOnly(variant.markup_price),
            }));

            const filterVariantsWithValue = formatPriceToSave.filter(
                (variant) => variant.shouldSendVariant
            );

            const params = {
                accommodationId: accommodation.id,
                roomId: values.room,
                fare: values.fare,
                start_date: moment(values.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                end_date: moment(values.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                room_variant: filterVariantsWithValue,
                days_week_only: values.days_week_only,
            };

            setIsLoading(true);

            try {
                const jwt = authentication;
                await createFaredayByPeriod(params, jwt);

                if (goOut.current) {
                    history.push({
                        pathname: '/tarifas-dia',
                        data: { show: true, message: 'Tarifa cadastrada', type: 'success' },
                    });
                    return;
                }
                setAlert({
                    show: true,
                    message: 'Tarifário cadastrado com sucesso!',
                    type: 'success',
                });
                resetForm();
            } catch (error) {
                setAlert({
                    show: true,
                    message: 'Não foi possível cadastrar o tarifário.',
                    type: 'danger',
                });
                setErrorMessage(error);
            }
            setIsLoading(false);
        },
        [accommodation.id, authentication, history, oneVariantToMany]
    );

    const handleVariants = useCallback((roomobj = {}, fareobj = {}) => {
        if (Object.keys(roomobj).length <= 2 || Object.keys(fareobj).length <= 2) {
            return;
        }

        const [...newVariants] = roomobj.variants;

        const variantFilter = newVariants
            .filter((variant) => variant.places <= fareobj.max_places)
            .map((variant) => ({
                id: variant.id,
                label: variant.label,
                price: '',
                markup_price: '',
                shouldSendVariant: false,
            }));
        variants.current = variantFilter;
    }, []);

    const onChangeFare = useCallback(
        (fareId) => {
            const selectedFare = fares.find((fare) => fare.id === Number(fareId));
            fareObject.current = selectedFare;
            handleVariants(roomObject.current, selectedFare);
        },
        [fares, handleVariants]
    );

    const onChangeRoom = useCallback(
        (roomId) => {
            const selectedRoom = rooms.find((room) => room.id === Number(roomId));
            roomObject.current = selectedRoom;
            handleVariants(selectedRoom, fareObject.current);
        },
        [rooms, handleVariants]
    );

    const onChangeResetVariant = useCallback((e, setFieldValue) => {
        if (e.target.value === 'all') {
            setFieldValue('variants', variants.current);
        } else {
            setFieldValue('price', '');
            setFieldValue('markup_price', '');
        }
    }, []);

    useEffect(() => {
        const getFares = async () => {
            try {
                const jwt = authentication;
                const { results } = await getAccommodationFares({ id: accommodation.id }, jwt);

                if (!results.length) {
                    setFares([{ value: 0, label: 'Nenhuma tarifa foi encontrada' }]);
                    return;
                }

                const fareList = results.map((fare) => ({
                    value: fare.id,
                    label: fare.name,
                    ...fare,
                }));
                setFares(fareList);
                [fareObject.current] = fareList;
            } catch (error) {
                setErrorMessage(error);
            }
        };
        getFares();

        const getRooms = async () => {
            try {
                const jwt = authentication;
                const { results } = await getAccommodationRooms(accommodation.id, jwt);

                if (!results.length) {
                    setRooms([{ value: 0, label: 'Nenhum quarto foi encontrado' }]);
                    setLoadingVariants(false);
                    return;
                }

                const roomsList = results.map((room) => ({
                    value: room.id,
                    label: room.name,
                    ...room,
                }));
                setRooms(roomsList);
                [roomObject.current] = roomsList;
                setLoadingVariants(false);
            } catch (error) {
                setErrorMessage(error);
            }
        };
        getRooms();
    }, [accommodation.id, authentication]);

    useEffect(() => {
        handleVariants(rooms[0], fares[0]);
    }, [handleVariants, rooms, fares]);

    const onHandleChangeCurrencyInput = async (e, value, setFieldValue) => {
        const { name } = e.target;
        setFieldValue(name, value);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
                onHandleSubmit(values, resetForm, setFieldError);
                setSubmitting(false);
            }}
        >
            {(formProps) => {
                const {
                    isSubmitting,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    submitForm,
                } = formProps;

                return (
                    <form onSubmit={handleSubmit}>
                        {isLoading ? (
                            <TarifaDiaShimmer />
                        ) : (
                            <TemplateDefault title="Novo tarifário">
                                <div className={style.container}>
                                    {alert.show && (
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <Alert type={alert.type}>{alert.message}</Alert>
                                            </div>
                                        </div>
                                    )}
                                    {Object.values(errorMessage).length > 0 && (
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <ErrorHandler errors={errorMessage} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <SectionBorder>
                                                <Heading level={LEVEL.h6} className={style.header}>
                                                    Informações do tarifário
                                                </Heading>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        {!fares.length ? (
                                                            <SelectShimmer />
                                                        ) : (
                                                            <Select
                                                                label="Nome da tarifa"
                                                                name="fare"
                                                                quantityOptions={fares}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeFare(e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        {!rooms.length ? (
                                                            <SelectShimmer />
                                                        ) : (
                                                            <Select
                                                                label="Quarto"
                                                                name="room"
                                                                quantityOptions={rooms}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeRoom(e.target.value);
                                                                    setFieldValue(
                                                                        'variants',
                                                                        variants.current
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                        <MaskedInput
                                                            label="Data inicial"
                                                            mask={DATE_MASK}
                                                            name="start_date"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCorrect="off"
                                                            inputMode="decimal"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <MaskedInput
                                                            label="Data final"
                                                            mask={DATE_MASK}
                                                            name="end_date"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCorrect="off"
                                                            inputMode="decimal"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-12 col-md-12">
                                                        <Label>Apenas nos dias da semana</Label>
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <div
                                                        className={classNames(
                                                            'col-xm-12 col-md-12',
                                                            style.checkBoxContent
                                                        )}
                                                    >
                                                        {weekDays.map((weekday) => (
                                                            <div
                                                                className={style.checkBox}
                                                                key={weekday.id}
                                                            >
                                                                <Checkbox
                                                                    name="days_week_only"
                                                                    value={weekday.id}
                                                                >
                                                                    {weekday.value}
                                                                </Checkbox>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </SectionBorder>
                                        </div>

                                        <div className="col-xl-6 col-md-6">
                                            {loadingVariants ? (
                                                <TarifaDiaShimmerVariants />
                                            ) : (
                                                <>
                                                    <SectionBorder>
                                                        <Heading
                                                            level={LEVEL.h6}
                                                            className={style.header}
                                                        >
                                                            Variantes do quarto
                                                        </Heading>
                                                        <div className="row mt-3">
                                                            <div className="col-xm-6 col-md-6">
                                                                <RadioButton
                                                                    name="variant_type"
                                                                    value="single"
                                                                    onClick={(e) =>
                                                                        onChangeResetVariant(
                                                                            e,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                >
                                                                    Aplicar individualmente
                                                                </RadioButton>
                                                            </div>
                                                            <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                                <RadioButton
                                                                    name="variant_type"
                                                                    value="all"
                                                                    onClick={(e) =>
                                                                        onChangeResetVariant(
                                                                            e,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                >
                                                                    Aplicar para todas
                                                                </RadioButton>
                                                            </div>
                                                        </div>

                                                        {!values.variants.length ? (
                                                            <div className={style.variantBlock}>
                                                                <div className={style.sectionTitle}>
                                                                    <p>Não possui variantes</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {values.variant_type === 'all' ? (
                                                                    <div
                                                                        className={
                                                                            style.variantBlock
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                style.sectionTitle
                                                                            }
                                                                        >
                                                                            <strong>
                                                                                Todas as variantes
                                                                            </strong>
                                                                            <p>
                                                                                {values.variants
                                                                                    .map(
                                                                                        (variant) =>
                                                                                            variant.label
                                                                                    )
                                                                                    .toString()}
                                                                            </p>
                                                                        </div>
                                                                        <div className="row mt-3">
                                                                            <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                                                <CurrencyInput
                                                                                    label="Preço"
                                                                                    name="price"
                                                                                    onChange={(
                                                                                        e,
                                                                                        value
                                                                                    ) => {
                                                                                        onHandleChangeCurrencyInput(
                                                                                            e,
                                                                                            value,
                                                                                            setFieldValue
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xm-6 col-md-6">
                                                                                <CurrencyInput
                                                                                    label="Preço markup(agente)"
                                                                                    name="markup_price"
                                                                                    onChange={(
                                                                                        e,
                                                                                        value
                                                                                    ) =>
                                                                                        onHandleChangeCurrencyInput(
                                                                                            e,
                                                                                            value,
                                                                                            setFieldValue
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {values.variants &&
                                                                            values.variants.map(
                                                                                (variant, idx) => (
                                                                                    <div
                                                                                        className={
                                                                                            style.variantBlock
                                                                                        }
                                                                                        key={
                                                                                            variant.id
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                style.sectionTitle
                                                                                            }
                                                                                        >
                                                                                            <Heading
                                                                                                level={
                                                                                                    LEVEL.h5
                                                                                                }
                                                                                                className={classNames(
                                                                                                    style.header,
                                                                                                    {
                                                                                                        [style.disableHeader]:
                                                                                                            !variant.shouldSendVariant,
                                                                                                    }
                                                                                                )}
                                                                                            >
                                                                                                <strong>
                                                                                                    {
                                                                                                        variant.label
                                                                                                    }
                                                                                                </strong>

                                                                                                <Checkbox
                                                                                                    name={`variants.${idx}.shouldSendVariant`}
                                                                                                    className={
                                                                                                        style.checkBoxVariants
                                                                                                    }
                                                                                                    title="Ignorar variante"
                                                                                                />
                                                                                            </Heading>
                                                                                        </div>
                                                                                        <div
                                                                                            className={classNames(
                                                                                                style.contentVariants,
                                                                                                {
                                                                                                    [style.disableVariant]:
                                                                                                        !variant.shouldSendVariant,
                                                                                                }
                                                                                            )}
                                                                                        >
                                                                                            <div className="row mt-3">
                                                                                                <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                                                                    <CurrencyInput
                                                                                                        label="Preço"
                                                                                                        name={`variants.${idx}.price`}
                                                                                                        onChange={(
                                                                                                            e,
                                                                                                            value
                                                                                                        ) => {
                                                                                                            onHandleChangeCurrencyInput(
                                                                                                                e,
                                                                                                                value,
                                                                                                                setFieldValue
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-xm-6 col-md-6">
                                                                                                    <CurrencyInput
                                                                                                        label="Preço markup(agente)"
                                                                                                        name={`variants.${idx}.markup_price`}
                                                                                                        onChange={(
                                                                                                            e,
                                                                                                            value
                                                                                                        ) => {
                                                                                                            onHandleChangeCurrencyInput(
                                                                                                                e,
                                                                                                                value,
                                                                                                                setFieldValue
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </SectionBorder>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className=" col-sm-12 col-md-auto">
                                            <Button
                                                type={TYPE.link}
                                                size={BUTTON_SIZE.normal}
                                                onClick={handleCancel}
                                                className="button-auto-multi-submit mt-3"
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                type={TYPE.secondary}
                                                size={BUTTON_SIZE.normal}
                                                onClick={() => {
                                                    goOut.current = true;
                                                    submitForm();
                                                }}
                                                className="button-auto-multi-submit mt-3"
                                                disabled={isSubmitting}
                                            >
                                                Salvar Tarifário e voltar
                                            </Button>
                                            <Button
                                                type={TYPE.secondary}
                                                size={BUTTON_SIZE.normal}
                                                onClick={() => {}}
                                                typeSubmit
                                                className="button-auto-multi-submit mt-3"
                                                disabled={isSubmitting}
                                            >
                                                Salvar tarifário
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </TemplateDefault>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default TarifaDia;
