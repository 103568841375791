import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '_atoms/Logo';
import HamburgerMenuButton from '_atoms/HamburgerMenuButton';
import ProfileDropdown from '_molecules/ProfileDropdown';
import ProfileDropdownMobile from '_molecules/ProfileDropdownMobile';
import Menu, { MenuItem } from '_atoms/Menu';
import { MENU_ITEMS, PROFILE_DROPDOWN_ITEMS } from '_config/settings';
import HotelNameBadge, { HotelNameBadgeMobile } from '_molecules/HotelNameBadge';
import Button, { TYPE } from '_atoms/Button';
import actions from '_redux/actions';
import useUserPermission from '_hooks/useUserPermission';

import style from '_organisms/Header/index.module.scss';

const Header = ({ currentPath }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { profile, accommodation } = useSelector((state) => state);

    const { push } = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const { userIsStaff, userIsSuperUser } = useUserPermission();

    const userName = useMemo(() => `${profile.first_name} ${profile.last_name}`, [profile]);

    useEffect(() => {
        const element = document.body;
        element.classList.remove(style.scrollLock);
    }, []);

    const menuToggle = (e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
        const element = document.body;
        /* istanbul ignore next */
        if (element.classList) {
            element.classList.toggle(style.scrollLock);
        } else {
            // For IE9
            /* istanbul ignore next */
            const classes = element.className.split(' ');
            /* istanbul ignore next */
            const i = classes.indexOf(style.scrollLock);
            /* istanbul ignore next */
            if (i >= 0) {
                classes.splice(i, 1);
            } else {
                classes.push(style.scrollLock);
            }
            /* istanbul ignore next */
            element.className = classes.join(' ');
        }
    };

    const logout = () => {
        dispatch(actions.cleanAccommodation());
        dispatch(actions.logout());
        push('/entrar');
    };

    return (
        <header className={style.header}>
            <div className={classNames(style.blockHeader, { [style.fixed]: isOpen })}>
                <Logo />

                <HamburgerMenuButton menuStatus={isOpen} handleMenuToggle={(e) => menuToggle(e)} />
            </div>
            {!accommodation.id ? (
                <div className={style.navNewHotel}>
                    {(userIsStaff || userIsSuperUser) &&
                        (pathname === '/cadastrar-hotel' ? (
                            <Button type={TYPE.secondary} onClick={() => push('/escolher-hotel')}>
                                Selecionar um hotel
                            </Button>
                        ) : (
                            <Button type={TYPE.secondary} onClick={() => push('/cadastrar-hotel')}>
                                Cadastrar novo hotel
                            </Button>
                        ))}

                    <Button type={TYPE.link} onClick={logout}>
                        Sair
                    </Button>
                </div>
            ) : (
                <>
                    <Menu className={classNames(style.menu)}>
                        {MENU_ITEMS.map((item) => (
                            <MenuItem
                                href={item.href}
                                isActive={item.href === currentPath}
                                key={item.label}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Menu>
                    <HotelNameBadge />
                    <ProfileDropdown itens={PROFILE_DROPDOWN_ITEMS} name={userName} />
                </>
            )}

            <div className={classNames(style.contentMenuMobile, { [style.isOpen]: isOpen })}>
                {!accommodation.id ? (
                    <div className={style.navNewHotel}>
                        {(userIsStaff || userIsSuperUser) &&
                            (pathname === '/cadastrar-hotel' ? (
                                <Button
                                    type={TYPE.secondary}
                                    onClick={() => push('/escolher-hotel')}
                                >
                                    Selecionar um hotel
                                </Button>
                            ) : (
                                <Button
                                    type={TYPE.secondary}
                                    onClick={() => push('/cadastrar-hotel')}
                                >
                                    Cadastrar novo hotel
                                </Button>
                            ))}

                        <Button type={TYPE.link} onClick={logout}>
                            Sair
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className={style.blockItens}>
                            {MENU_ITEMS.map((item) => (
                                <MenuItem
                                    href={item.href}
                                    isActive={item.href === currentPath}
                                    key={item.label}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </div>
                        <HotelNameBadgeMobile />
                        <ProfileDropdownMobile
                            itens={PROFILE_DROPDOWN_ITEMS}
                            name={userName}
                            currentPath={currentPath}
                        />

                        <Menu className={classNames(style.menu)}>
                            {MENU_ITEMS.map((item) => (
                                <MenuItem
                                    href={item.href}
                                    isActive={item.href === currentPath}
                                    key={item.label}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                        <HotelNameBadge />
                        <ProfileDropdown itens={PROFILE_DROPDOWN_ITEMS} name={userName} />
                    </>
                )}
            </div>
        </header>
    );
};

Header.propTypes = {
    currentPath: PropTypes.string,
};

Header.defaultProps = {
    currentPath: '/',
};

export default Header;
