import React from 'react';
import PropTypes from 'prop-types';

import style from '_atoms/CalendarDescriptionDay/index.module.scss';

const CalendarDescriptionDay = ({ type, value }) => (
    <div className={style.blockDescriptionDays}>
        <p title={type} className={style.total}>
            {value}
        </p>
    </div>
);

CalendarDescriptionDay.propTypes = {
    value: PropTypes.number,
    type: PropTypes.string,
};

CalendarDescriptionDay.defaultProps = {
    value: '',
    type: '',
};

export default CalendarDescriptionDay;
