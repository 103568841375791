import React from 'react';

import style from '_molecules/CardUserCompanion/index-shimmer.module.scss';

const CardUserCompanionShimmer = () => (
    <>
        <div className={style.blockCompanion} />
        <div className={style.blockCompanion} />
        <div className={style.blockCompanion} />
    </>
);

export default CardUserCompanionShimmer;
