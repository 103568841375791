import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import TemplateDefault from '_templates/TemplateDefault';
import Button, { LINE_TYPE, TYPE } from '_atoms/Button';
import List from '_pages/disponibilidades/list';
import CalendarioDisponibilidade from '_pages/disponibilidades/calendar';
import { MdReorder } from 'react-icons/md';
import { RiDashboardLine } from 'react-icons/ri';
import useUserPermission from '_hooks/useUserPermission';

import style from '_pages/disponibilidades/index.module.scss';

const Disponibilidades = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();
    const [viewType, setViewType] = useState(query.get('view') || 'calendar');
    const history = useHistory();

    const handleNewFare = () => {
        history.push('/disponibilidade/novo');
    };

    const { userIsSuperUser } = useUserPermission();

    const setView = useCallback(
        (type) => {
            if (type === 'list' || type === 'calendar') {
                setViewType(type);
                history.push({
                    pathname: '/disponibilidades',
                    search: `?view=${type}`,
                });
            }
        },
        [history]
    );

    useEffect(() => {
        if (!query.get('view')) {
            setView('calendar');
        }
    }, [query, setView]);

    return (
        <TemplateDefault
            title="Disponibilidade"
            buttons={
                <>
                    <Button
                        className={classNames(style.iconButton, {
                            [style.active]: viewType === 'calendar',
                        })}
                        type={LINE_TYPE.secondary}
                        onClick={() => {
                            setView('calendar');
                        }}
                    >
                        <RiDashboardLine size={22} />
                    </Button>
                    <Button
                        className={classNames(style.iconButton, {
                            [style.active]: viewType === 'list',
                        })}
                        type={LINE_TYPE.secondary}
                        onClick={() => {
                            setView('list');
                        }}
                    >
                        <MdReorder size={22} />
                    </Button>
                    {userIsSuperUser && (
                        <Button
                            type={TYPE.secondary}
                            onClick={() => {
                                handleNewFare();
                            }}
                        >
                            Cadastrar disponibilidade
                        </Button>
                    )}
                </>
            }
        >
            <div className={style.content}>
                {viewType === 'list' ? <List /> : <CalendarioDisponibilidade />}
            </div>
        </TemplateDefault>
    );
};

export default Disponibilidades;
