import React from 'react';
import PropTypes from 'prop-types';
import Heading, { LEVEL } from '_atoms/Heading';
import Button, { TYPE } from '_atoms/Button';
import StatusBall, { STATUS } from '_atoms/StatusBall';
import { defineActiveOrInactive, defineLabelActiveOrInactive } from '_utils/createStatus';

import style from '_molecules/CardListUser/index.module.scss';

const CardListUser = ({ user, handleNext }) => {
    const onhandleNext = (id) => handleNext(id);

    return (
        <div className={style.blockCompanion}>
            <Heading level={LEVEL.h5} className={style.blockTitle}>
                {user.get_full_name || user.first_name}
                <StatusBall
                    className={style.status}
                    status={STATUS[defineActiveOrInactive(user.is_active)]}
                    label={defineLabelActiveOrInactive(user.is_active)}
                />
            </Heading>
            <Button className={style.button} type={TYPE.link} onClick={() => onhandleNext(user.id)}>
                Excluir
            </Button>
        </div>
    );
};

CardListUser.propTypes = {
    user: PropTypes.shape({
        get_full_name: PropTypes.string,
        first_name: PropTypes.string,
        is_active: PropTypes.bool,
        id: PropTypes.number,
    }),
    handleNext: PropTypes.func,
};

CardListUser.defaultProps = {
    user: {},
    handleNext: () => {},
};

export default CardListUser;
