import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_molecules/Alert/index.module.scss';

const defineType = (type) => {
    switch (type) {
        case 'success':
            return style.success;
        case 'warning':
            return style.warning;
        case 'danger':
            return style.danger;
        case 'message':
            return style.message;
        default:
            return style.info;
    }
};

const Alert = ({ className, type, children, title, icon, label, onClosed }) => {
    const styleType = defineType(type);

    const [showAlert, setShowAlert] = useState(true);

    const handleClickShowAlert = () => {
        setShowAlert(false);
    };

    if (showAlert) {
        return (
            <div className={classNames(style.boxAlert, styleType, className)}>
                {icon && <img src={icon} alt={label} />}
                <div className={style.content}>
                    {title && <strong className={style.title}>{title}</strong>}
                    <div className={style.msg}>{children}</div>
                </div>
                {onClosed && (
                    <div className={style.exit}>
                        <button
                            className={style.closeButton}
                            type="button"
                            onClick={handleClickShowAlert}
                            aria-label="Close alert"
                        />
                    </div>
                )}
            </div>
        );
    }
    return <div />;
};

Alert.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string,
    onClosed: PropTypes.bool,
};

Alert.defaultProps = {
    children: undefined,
    className: undefined,
    type: 'info',
    title: undefined,
    icon: undefined,
    label: undefined,
    onClosed: false,
};

export default Alert;
