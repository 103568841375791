export const errorHandler = (error) =>
    Object.entries(error.response.data).map(
        ([, value]) => `${Array.isArray(value) ? value.join(' ') : value}`
    );

export const formErrorHandler = (error) =>
    Object.entries(error.response.data).map(([key, value]) => ({
        key,
        value: Array.isArray(value) ? value.join(' ') : value,
    }));
