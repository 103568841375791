import React from 'react';
import { useField } from 'formik';
import IntlCurrencyInput from 'react-intl-currency-input';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '_atoms/Label';
import LineInstruction from '_molecules/LineInstruction';
import { currencyNumberOnly } from '_helpers/inputMasks';

import style from '_molecules/CurrencyInput/index.module.scss';

const currencyConfig = {
    locale: 'pt-BR',
    formats: {
        number: {
            BRL: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

const CurrencyInput = ({ className, label, onChange, config, value, ...rest }) => {
    // eslint-disable-next-line no-unused-vars
    const [field, meta] = useField(rest);
    const newValue = currencyNumberOnly(value);

    return (
        <div className={className}>
            <Label>{label}</Label>
            <IntlCurrencyInput
                defaultValue={newValue}
                currency="BRL"
                config={config}
                onChange={onChange}
                className={classNames(style.input, { [style.danger]: meta.touched && meta.error })}
                {...rest}
            />
            {meta.touched && meta.error && (
                <LineInstruction className={style.errorMessage}>{meta.error}</LineInstruction>
            )}
        </div>
    );
};

CurrencyInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    config: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
};

CurrencyInput.defaultProps = {
    className: undefined,
    label: undefined,
    value: '0',
    config: currencyConfig,
};

export default CurrencyInput;
