import React, { useState, useCallback, useEffect } from 'react';

import Button, { TYPE } from '_atoms/Button';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import ModalAction from '_organisms/ModalAction';
import SideContentQuarto from '_organisms/SideContentQuarto';
import MainContentQuartoCadastroVariante from '_organisms/MainContentQuartoCadastroVariante';
import useQueryRoom from '_hooks/useQueryRoom';
import useRemoveRoom from '_hooks/useRemoveRoom';
import { useHistory } from 'react-router-dom';

const Variante = () => {
    const history = useHistory();
    const [room] = useQueryRoom();
    const [handleRemove, loadingModal] = useRemoveRoom();
    const [modalRemove, setModalRemove] = useState(false);

    const handleModal = useCallback(() => {
        setModalRemove(!modalRemove);
    }, [modalRemove]);

    useEffect(() => {
        if (history.location.data) {
            setModalRemove(false);
        }
    }, [history.location.data]);

    return (
        <>
            <TemplateMeuHotelContent
                title={room.name || ''}
                main={<MainContentQuartoCadastroVariante />}
                side={<SideContentQuarto />}
                buttons={
                    <Button type={TYPE.danger} onClick={handleModal}>
                        Excluir quarto
                    </Button>
                }
                linkBack
            />
            {modalRemove && (
                <ModalAction
                    showModal={modalRemove}
                    modalName="cancelBooking"
                    title="Deseja realmente deletar este quarto?"
                    type="danger"
                    submitLabel="Confirmar"
                    cancelLabel="Cancelar"
                    onCancel={handleModal}
                    onSubmit={handleRemove}
                    onhandleCloseModal={handleModal}
                    closeOnOverlay
                    closeOnEsc
                    isLoading={loadingModal}
                />
            )}
        </>
    );
};

export default Variante;
