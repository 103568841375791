import React from 'react';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentMeuHotel from '_organisms/SideContentMeuHotel';
import MainContentMeuHotelGaleriaImagens from '_organisms/MainContentMeuHotelGaleriaImagens';
import { PROFILE_ITEMS } from '_config/settings';
import useMobileNavigation from '_hooks/useMobileNavigation';

const Galeria = () => {
    const MobileNavigationComponent = useMobileNavigation(PROFILE_ITEMS);

    return (
        <TemplateMeuHotelContent
            title="Galeria de imagens"
            main={
                <>
                    <MobileNavigationComponent />
                    <MainContentMeuHotelGaleriaImagens />
                </>
            }
            side={<SideContentMeuHotel currentPath="/meu-hotel/galeria" />}
            linkBack
        />
    );
};

export default Galeria;
