import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Button/index.module.scss';

export const TYPE = {
    primary: style.primary,
    secondary: style.secondary,
    success: style.success,
    warning: style.warning,
    danger: style.danger,
    disabled: style.disabled,
    link: style.link,
    link_danger: style.linkDanger,
    link_disabled: style.linkDisabled,
};

export const LINE_TYPE = {
    primary: style.primaryOutlined,
    secondary: style.secondaryOutlined,
    success: style.successOutlined,
    warning: style.warningOutlined,
    danger: style.dangerOutlined,
};

export const BUTTON_SIZE = {
    small: style.small,
    normal: style.normal,
    large: style.large,
};

const Button = ({ onClick, children, className, type, size, isBlock, isDisabled, typeSubmit }) => {
    const submit = typeSubmit ? 'submit' : 'button';
    return (
        <button
            type={submit}
            className={classNames(style.button, type, size, { [style.block]: isBlock }, className)}
            disabled={isDisabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    isBlock: PropTypes.bool,
    isDisabled: PropTypes.bool,
    typeSubmit: PropTypes.bool,
};

Button.defaultProps = {
    className: undefined,
    type: TYPE.primary,
    size: BUTTON_SIZE.normal,
    isBlock: false,
    isDisabled: false,
    typeSubmit: false,
};

export default Button;
