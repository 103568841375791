export const DATE_MASK = [
    /[0-9]/,
    /[0-9]/,
    '/',
    /[0-9]/,
    /[0-9]/,
    '/',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

export const PHONE_MASK = [
    '(',
    /[0-9]/,
    /[0-9]/,
    ')',
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

export const CELLPHONE_MASK = [
    '(',
    /[0-9]/,
    /[0-9]/,
    ')',
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

export const CPF_MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
];

export const CNPJ_MASK = [
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '/',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
];

export const CEP_MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

export const CREDITCARD_MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

export const HOUR_MASK = (value) => {
    const chars = value.split('');

    let hours;
    if (chars[0] === '0' || chars[0] === '1' || chars[0] === '2') {
        if (chars[1] === ':') {
            // only one digit before the colon: "0", "1", "2"
            hours = [/[0-2]/];
        } else {
            // two digits: 00, 01, 02, 03, 04, ..., 18, 19, 20, 21, 22, 23
            hours = [/[0-2]/, chars[0] === '2' ? /[0-3]/ : /[0-9]/];
        }
    } else {
        // one digit greater than two: 3, 4, 5, 6, 7, 8, 9
        hours = [/[3-9]/];
    }

    // minutes is always two-digits
    const minutes = [/[0-5]/, /[0-9]/];

    return hours.concat(':').concat(minutes);
};

export const MONTH_YEAR_MASK = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

export const dateToSend = (date) => {
    const alreadyInverted = date.split('').filter((number) => number === '-');

    if (!alreadyInverted.length) {
        const convertedDate = date
            .split('')
            .filter((number) => number !== '/')
            .reverse();

        return `${convertedDate[3] + convertedDate[2] + convertedDate[1] + convertedDate[0]}-${
            convertedDate[5]
        }${convertedDate[4]}-${convertedDate[7]}${convertedDate[6]}`;
    }

    return date;
};

export const dateToShow = (date) => {
    if (!date) return '';
    return date.split('-').reverse().join('');
};

export const numberOnly = (value) => {
    const reg = /\d+/g;
    if (!value) return '';
    const parseNumber = String(value).match(reg).join([]);

    return parseNumber;
};
