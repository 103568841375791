import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/Badge/index.module.scss';

export const BADGE_SIZE = {
    normal: style.normal,
    big: style.big,
};

const Badge = ({ status, message, badgeSize, secondary, icon }) => {
    const styleStatus = {
        success: style.success,
        danger: style.danger,
        info: style.info,
        warning: style.warning,
        default: style.default,
    };

    return (
        <div
            className={classNames(
                style.badge,
                badgeSize,
                styleStatus[status],
                {
                    [style.secondary]: secondary,
                },
                { [style.badgeIcon]: icon }
            )}
        >
            {icon && <img src={icon} alt={message} />}
            <p className={style.text}>{message}</p>
        </div>
    );
};

Badge.propTypes = {
    status: PropTypes.string.isRequired,
    message: PropTypes.string,
    badgeSize: PropTypes.string,
    secondary: PropTypes.bool,
    icon: PropTypes.string,
};

Badge.defaultProps = {
    message: undefined,
    badgeSize: BADGE_SIZE.normal,
    secondary: false,
    icon: undefined,
};

export default Badge;
