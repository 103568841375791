import React, { useState, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Input from '_atoms/Input/Input';
import TextArea from '_atoms/TextArea/TextArea';
import ButtonShimmer from '_atoms/Button/shimmer';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentQuartoInformacoesGeraisShimmer from '_organisms/MainContentQuartoInformacoesGerais/shimmer';
import { saveAccommodationRoom, updateAccommodationRoom } from '_services/requestRooms';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentQuartoInformacoesGerais/index.module.scss';

const MainContentQuartoInformacoesGerais = ({ room, updateRoom }) => {
    const history = useHistory();
    const [alert, setAlert] = useState({});
    const { id: roomId } = useParams();
    const [loading, setLoading] = useState(() => {
        if (!roomId) {
            return false;
        }
        return true;
    });
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        name: room.id && room.name ? room.name : '',
        details: room.id && room.details ? room.details : '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Obrigatório'),
        details: Yup.string().required('Obrigatório'),
    });

    const onHandleSubmit = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});
            try {
                if (roomId) {
                    await updateAccommodationRoom(accommodation.id, roomId, values, jwt);
                    setLoading(false);
                    setAlert({
                        show: true,
                        message: 'As informações do quarto foram atualizadas!',
                        type: 'success',
                    });
                    updateRoom();
                    return;
                }

                const savedRoom = await saveAccommodationRoom(accommodation.id, values, jwt);
                setLoading(false);
                updateRoom();
                if (savedRoom.id) {
                    history.push(`/quarto/${savedRoom.id}/informacoes-gerais`);
                }

                setAlert({
                    show: true,
                    message: 'As informações do quarto foram cadastradas!',
                    type: 'success',
                });
            } catch (error) {
                setLoading(false);
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível realizar essa operação.',
                    type: 'danger',
                });
            }
        },
        [accommodation.id, roomId, history, updateRoom, jwt]
    );

    useEffect(() => {
        if (Object.keys(room).length) setLoading(false);
    }, [room]);

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }
        return () => setAlert({});
    }, [history.location.data]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                onHandleSubmit(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit } = formProps;
                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <SectionBorder>
                                <div>
                                    <Heading level={LEVEL.h6} className={style.sectionTitle}>
                                        Dados do quarto
                                    </Heading>
                                    {loading ? (
                                        <MainContentQuartoInformacoesGeraisShimmer />
                                    ) : (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="Nome do quarto"
                                                        name="name"
                                                        placeholder="Digite o nome do quarto"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <TextArea
                                                        label="Descrição"
                                                        name="details"
                                                        rows={10}
                                                        placeholder="Descrição do quarto"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </SectionBorder>

                            <div className="container">
                                <div className="row justify-content-end">
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            {loading ? (
                                                <ButtonShimmer />
                                            ) : (
                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.normal}
                                                    onClick={() => {}}
                                                    typeSubmit
                                                    isBlock
                                                    disabled={isSubmitting}
                                                >
                                                    Salvar
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

MainContentQuartoInformacoesGerais.propTypes = {
    room: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        details: PropTypes.string,
        isLoading: PropTypes.bool,
    }).isRequired,
    updateRoom: PropTypes.func.isRequired,
};

export default MainContentQuartoInformacoesGerais;
