import React from 'react';

import style from '_molecules/ImageGalleryUpload//index-shimmer.module.scss';

const ImageGalleryShimmer = () => (
    <>
        <li>
            <div className={style.image} />
        </li>
        <li>
            <div className={style.image} />
        </li>
        <li>
            <div className={style.image} />
        </li>
        <li>
            <div className={style.image} />
        </li>
        <li>
            <div className={style.image} />
        </li>
    </>
);

export default ImageGalleryShimmer;
