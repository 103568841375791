import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Input from '_atoms/Input/Input';
import ButtonShimmer from '_atoms/Button/shimmer';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentQuartoCadastroVarianteShimmer from '_organisms/MainContentQuartoCadastroVariante/shimmer';
import { createMultipleRoomVariants } from '_services/requestRooms';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentQuartoCadastroVariante/index.module.scss';

const MainContentQuartoCadastroVariante = ({ room }) => {
    const [alert, setAlert] = useState({});
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const { id: roomId } = useParams();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const initialVariant = {
        children: 1,
        double_beds: 1,
        label: '',
        places: 0,
        single_beds: 0,
        sofa_beds: 1,
    };

    const initValues = {
        variants: [initialVariant],
    };

    const validationSchema = Yup.object().shape({
        variants: Yup.array().of(
            Yup.object().shape({
                label: Yup.string().required('Obrigatório'),
                places: Yup.number()
                    .integer('Valor inválido')
                    .min(0, 'Valor inválido')
                    .typeError('Apenas números')
                    .required('Obrigatório'),
                single_beds: Yup.number()
                    .integer('Valor inválido')
                    .min(0, 'Valor inválido')
                    .typeError('Apenas números')
                    .required('Obrigatório'),
                double_beds: Yup.number()
                    .integer('Valor inválido')
                    .min(0, 'Valor inválido')
                    .typeError('Apenas números')
                    .required('Obrigatório'),
                sofa_beds: Yup.number()
                    .integer('Valor inválido')
                    .min(0, 'Valor inválido')
                    .typeError('Apenas números')
                    .required('Obrigatório'),
                children: Yup.number()
                    .integer('Valor inválido')
                    .min(0, 'Valor inválido')
                    .typeError('Apenas números')
                    .required('Obrigatório'),
            })
        ),
    });

    const onHandleSubmit = async (values) => {
        setLoading(true);
        setErrorMessage({});
        try {
            await createMultipleRoomVariants(accommodation.id, roomId, values.variants, jwt);
            history.push(`/quarto/${roomId}/variantes`);
        } catch (error) {
            setLoading(false);
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível realizar o cadastro',
                type: 'danger',
            });
        }
    };

    useEffect(() => {
        if (Object.keys(room).length) setLoading(false);
    }, [room]);

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }
        return () => setAlert({});
    }, [history.location.data]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                onHandleSubmit(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, values } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <FieldArray
                                name="variants"
                                render={(helpers) => (
                                    <>
                                        {loading ? (
                                            <>
                                                <MainContentQuartoCadastroVarianteShimmer />
                                                <MainContentQuartoCadastroVarianteShimmer />
                                                <MainContentQuartoCadastroVarianteShimmer />
                                            </>
                                        ) : (
                                            <>
                                                {values.variants.map((variant, idx) => {
                                                    const variantKey = idx;
                                                    return (
                                                        <SectionBorder key={variantKey}>
                                                            <Heading
                                                                level={LEVEL.h5}
                                                                className={style.sectionTitle}
                                                            >
                                                                {variant.label}
                                                            </Heading>

                                                            <div className="row mt-3">
                                                                <div className="col-xl-12 col-md-12">
                                                                    <Input
                                                                        label="Nome da variante"
                                                                        name={`variants.${idx}.label`}
                                                                        placeholder="Digite o nome da variação"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-xl-6 col-md-6">
                                                                    <Input
                                                                        label="Cama simples"
                                                                        name={`variants.${idx}.single_beds`}
                                                                        placeholder="Quantidade de camas simples"
                                                                        inputMode="decimal"
                                                                    />
                                                                </div>
                                                                <div className="col-xl-6 col-md-6">
                                                                    <Input
                                                                        label="Cama casal"
                                                                        name={`variants.${idx}.double_beds`}
                                                                        placeholder="Quantidade de camas de casal"
                                                                        inputMode="decimal"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-xl-6 col-md-6">
                                                                    <Input
                                                                        label="Berço"
                                                                        name={`variants.${idx}.sofa_beds`}
                                                                        placeholder="Quantidade de berços"
                                                                        inputMode="decimal"
                                                                    />
                                                                </div>
                                                                <div className="col-xl-6 col-md-6">
                                                                    <Input
                                                                        label="Max. crianças"
                                                                        name={`variants.${idx}.children`}
                                                                        placeholder="Quantidade de crianças"
                                                                        inputMode="decimal"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 justify-content-betwen">
                                                                <div className="col col-xl-12 col-md-12 d-flex justify-content-end">
                                                                    {values.variants.length > 1 && (
                                                                        <Button
                                                                            type={
                                                                                LINE_TYPE.secondary
                                                                            }
                                                                            size={
                                                                                BUTTON_SIZE.normal
                                                                            }
                                                                            onClick={() =>
                                                                                helpers.remove(idx)
                                                                            }
                                                                        >
                                                                            Remover variante
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </SectionBorder>
                                                    );
                                                })}
                                                <div className="container">
                                                    <div
                                                        className={`row mt-3 ${
                                                            values.variants.length
                                                                ? 'justify-content-end'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className="row">
                                                            <div className="col-xl-12 col-md-12">
                                                                {room.isLoading ? (
                                                                    <ButtonShimmer />
                                                                ) : (
                                                                    <div className="row">
                                                                        <div className="col d-flex">
                                                                            <Button
                                                                                type={TYPE.link}
                                                                                size={
                                                                                    BUTTON_SIZE.normal
                                                                                }
                                                                                onClick={() =>
                                                                                    history.goBack()
                                                                                }
                                                                            >
                                                                                Voltar
                                                                            </Button>

                                                                            <Button
                                                                                type={
                                                                                    LINE_TYPE.secondary
                                                                                }
                                                                                size={
                                                                                    BUTTON_SIZE.normal
                                                                                }
                                                                                onClick={() =>
                                                                                    helpers.push(
                                                                                        initialVariant
                                                                                    )
                                                                                }
                                                                            >
                                                                                Criar variante
                                                                            </Button>
                                                                            <Button
                                                                                type={
                                                                                    TYPE.secondary
                                                                                }
                                                                                size={
                                                                                    BUTTON_SIZE.normal
                                                                                }
                                                                                onClick={() => {}}
                                                                                typeSubmit
                                                                                disabled={
                                                                                    isSubmitting
                                                                                }
                                                                            >
                                                                                Salvar
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            />
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

MainContentQuartoCadastroVariante.propTypes = {
    room: PropTypes.shape({
        id: PropTypes.number,
        isLoading: PropTypes.bool,
        variants: PropTypes.arrayOf(PropTypes.object),
    }),
};

MainContentQuartoCadastroVariante.defaultProps = {
    room: {},
};

export default MainContentQuartoCadastroVariante;
