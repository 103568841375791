const translateMealPlan = (name) => {
    switch (name) {
        case 'breakfast':
            return 'Café da manhã';
        case 'breakfast_lunch':
            return 'Café da manhã e almoço';
        case 'breakfast_dinner':
            return 'Café da manhã e jantar';
        case 'lunch':
            return 'Almoço';
        case 'dinner':
            return 'Janta';
        case 'lunch_dinner':
            return 'Almoço e jantar';
        case 'all_inclusive':
            return 'All inclusive';
        case 'full_board':
            return 'Pensão completa';
        default:
            return '';
    }
};

export default translateMealPlan;
