import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import HotelNameBadgeMobile from '_molecules/HotelNameBadge/index-mobile';
import Badge, { BADGE_SIZE } from '_atoms/Badge';

import style from '_molecules/HotelNameBadge/index.module.scss';

const HotelNameBadge = () => {
    const { id: hotelId, tradeMark } = useSelector((state) => ({
        ...state.accommodation,
    }));

    return (
        hotelId && (
            <div className={classNames(style.navbarHotelNameBadge)}>
                <Badge
                    badgeSize={BADGE_SIZE.big}
                    status="info"
                    message={`${tradeMark} - ${hotelId}`}
                />
            </div>
        )
    );
};

export { HotelNameBadgeMobile };

export default HotelNameBadge;
