import { portal, portalAdmin } from '_services/apiConfig';

export const getAllBookings = async (params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const bookings = await portalAdmin
        .get(`/booking/`, { headers, params })
        .then((response) => response.data)
        .catch(() => {});

    return bookings;
};

export const getBookingById = async (id, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const bookings = await portal
        .get(`/booking/${id}/`, { headers })
        .then((response) => response.data)
        .catch(() => []);

    return bookings;
};

export const editBookingByRequestStatus = async (id, data, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const bookings = await portal
        .patch(`/booking/${id}/update_by_request_status/`, data, { headers })
        .then((response) => response.data)
        .catch(() => {});

    return bookings;
};

export const cancellationConfirmation = async (reservationId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    return portalAdmin.get(`/booking/${reservationId}/cancellation_confirmation/`, { headers });
};

export default {
    getAllBookings,
    getBookingById,
    editBookingByRequestStatus,
};
