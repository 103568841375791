import React from 'react';

import style from '_pages/quartos/shimmer.module.scss';

const QuartosShimmer = () => (
    <div className={style.bedroom}>
        <div className={style.bedroomImage} />
        <div className={style.title} />
    </div>
);

export default QuartosShimmer;
