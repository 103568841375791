import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import style from '_atoms/CalendarHeaderDay/index.module.scss';

const CalendarHeaderDay = ({ availability }) => (
    <div className={style.blockHeaderDays}>
        <p className={style.weekDay}>{moment(availability.date).format('ddd')}</p>
        <p className={style.monthDay}>{moment(availability.date).format('DD/MMM')}</p>
    </div>
);

CalendarHeaderDay.propTypes = {
    availability: PropTypes.shape({
        date: PropTypes.string,
    }),
};

CalendarHeaderDay.defaultProps = {
    availability: undefined,
};

export default CalendarHeaderDay;
