import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '_atoms/Input/Input';
import Heading, { LEVEL } from '_atoms/Heading';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Alert from '_molecules/Alert';
import { useHistory } from 'react-router-dom';
import { passwordReset } from '_services/requestSession';

import imgEmail from '_images/icon-email.svg';

import style from '_organisms/PasswordRecovery/index.module.scss';

const PasswordRecovery = () => {
    const initValues = {
        email: '',
    };
    const [success, setSuccess] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Obrigatório').email('Digite um e-mail válido.'),
    });

    const history = useHistory();

    const onHandleSubmit = async (values, resetForm) => {
        try {
            await passwordReset(values);
            setSuccess(true);
            resetForm();
        } catch (error) {
            setAlert({
                show: true,
                message: 'Ocorreu algum problema ao tentar enviar a solicitação.',
                type: 'danger',
            });
        }
    };

    const onHandleGoToLogin = () => history.push('/entrar');

    return (
        <>
            <Formik
                initialValues={initValues}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    onHandleSubmit(values, resetForm);
                    setSubmitting(false);
                }}
                validationSchema={validationSchema}
            >
                {(formProps) => {
                    const { isSubmitting, handleSubmit } = formProps;

                    return (
                        <>
                            {!success ? (
                                <>
                                    {alert.show && (
                                        <div className="row mt-3 mb-3">
                                            <div className="col-xl-12">
                                                <Alert type={alert.type}>{alert.message}</Alert>
                                            </div>
                                        </div>
                                    )}
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mt-3 mb-3">
                                            <div className="col-xl-12 col-md-12">
                                                <Input
                                                    label="E-mail"
                                                    name="email"
                                                    placeholder="Digite seu e-mail"
                                                    inputMode="email"
                                                    autoCapitalize="off"
                                                    autoCorrect="off"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            type={TYPE.secondary}
                                            size={BUTTON_SIZE.large}
                                            typeSubmit
                                            isBlock
                                            onClick={() => {}}
                                            disabled={isSubmitting}
                                        >
                                            Solicitar Troca de senha
                                        </Button>
                                        <Button
                                            type={TYPE.link}
                                            isBlock
                                            onClick={onHandleGoToLogin}
                                        >
                                            Voltar
                                        </Button>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            <img
                                                className={style.imgEmail}
                                                src={imgEmail}
                                                alt="Ícone E-mail"
                                            />
                                            <Heading className={style.header} level={LEVEL.h3}>
                                                Enviamos sua nova senha
                                            </Heading>
                                            <p className={style.paragraph}>
                                                Verifique sua caixa de entrada. Em alguns minutos
                                                você receberá um e-mail com sua nova senha.
                                                Lembre-se de alterar este código já no primeiro
                                                acesso para a sua segurança.
                                            </p>
                                        </div>
                                    </div>
                                    <Button type={TYPE.link} isBlock onClick={onHandleGoToLogin}>
                                        Voltar
                                    </Button>
                                </>
                            )}
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default PasswordRecovery;
