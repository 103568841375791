import { portalAdmin } from '_services/apiConfig';

export const getAccommodationRooms = async (accommodationId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const rooms = await portalAdmin.get(`/accommodations/${accommodationId}/rooms/`, { headers });

    return rooms.data;
};

export const getAccommodationRoomsAvailabilityDay = async (accommodationId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const rooms = await portalAdmin.get(`/accommodations/${accommodationId}/availability_days/`, {
        headers,
        params,
    });

    return rooms.data;
};

export const getCalendarRooms = async (accommodationId, cancelToken, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const rooms = await portalAdmin.get(`/accommodations/${accommodationId}/rooms/calendar/`, {
        headers,
        cancelToken,
    });

    return rooms.data;
};

export const getCalendarAvailability = async (accommodationId, id, params, cancelToken, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const availability = await portalAdmin.get(
        `/accommodations/${accommodationId}/rooms/${id}/availability_days/calendar/`,
        {
            headers,
            cancelToken,
            params,
        }
    );

    return availability.data;
};

export const getCalendarVariants = async (accommodationId, id, params, cancelToken, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const variants = await portalAdmin.get(
        `/accommodations/${accommodationId}/rooms/${id}/variants/calendar/`,
        {
            headers,
            cancelToken,
            params,
        }
    );

    return variants.data;
};

export const getAccommodationRoomById = async (accommodationId, id, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const fare = await portalAdmin.get(`/accommodations/${accommodationId}/rooms/${id}/`, {
        headers,
    });

    return fare.data;
};

export const saveAccommodationRoom = async (accommodationId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const room = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/`,
        {
            accommodation: accommodationId,
            ...params,
        },
        { headers }
    );

    return room.data;
};

export const updateAccommodationRoom = async (accommodationId, roomId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const room = await portalAdmin.patch(
        `/accommodations/${accommodationId}/rooms/${roomId}/`,
        {
            accommodation: accommodationId,
            ...params,
        },
        { headers }
    );

    return room.data;
};

export const roomsFeatures = async (jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const features = await portalAdmin.get('/rooms/features/', { headers });

    return features.data;
};

export const roomVariants = async (accommodationId, roomId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const variants = await portalAdmin.get(
        `/accommodations/${accommodationId}/rooms/${roomId}/variants/`,
        { headers }
    );

    return variants.data;
};

export const createMultipleRoomVariants = async (accommodationId, roomId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const variants = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/${roomId}/bulk-variants/`,
        params,
        {
            headers,
        }
    );

    return variants.data;
};

export const removeRoomVariants = async (accommodationId, roomId, variantId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const variants = await portalAdmin.delete(
        `/accommodations/${accommodationId}/rooms/${roomId}/variants/${variantId}/`,
        {
            headers,
        }
    );

    return variants.data;
};

export const updateRoomVariants = async (accommodationId, roomId, variantId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const variants = await portalAdmin.patch(
        `/accommodations/${accommodationId}/rooms/${roomId}/variants/${variantId}/`,
        params,
        headers
    );

    return variants.data;
};

export const saveBulkImagesRoom = async (accommodationId, roomId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/${roomId}/images/create_bulk/`,
        params,
        {
            headers,
        }
    );

    return response.data;
};

export const updateImageCoverRoom = async (accommodationId, roomId, imageId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const params = {
        accommodation: accommodationId,
        is_cover: true,
    };

    const response = await portalAdmin.patch(
        `/accommodations/${accommodationId}/rooms/${roomId}/images/${imageId}/`,
        params,
        {
            headers,
        }
    );

    return response.data;
};

export const listImagesRooms = async (accommodationId, roomId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.get(
        `/accommodations/${accommodationId}/rooms/${roomId}/images/`,
        { headers }
    );

    return response.data;
};

export const deleteImageRoom = async (accommodationId, roomId, imageId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `/accommodations/${accommodationId}/rooms/${roomId}/images/${imageId}`,
        { headers }
    );

    return response.data;
};

export const createRoomDeadline = async (accommodationId, roomId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/${roomId}/deadlines/`,
        params,
        { headers }
    );

    return response.data;
};

export const removeRoomDeadline = async (accommodationId, roomId, deadlineId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `/accommodations/${accommodationId}/rooms/${roomId}/deadlines/${deadlineId}/`,
        {
            headers,
        }
    );

    return response.data;
};

export const getRoomDeadline = async (accommodationId, roomId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.get(
        `/accommodations/${accommodationId}/rooms/${roomId}/deadlines/`,
        {
            headers,
        }
    );

    return response.data;
};

export const updateRoomDeadline = async (accommodationId, roomId, deadLineId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.patch(
        `/accommodations/${accommodationId}/rooms/${roomId}/deadlines/${deadLineId}/`,
        params,
        { headers }
    );
    return response.data;
};

export const removeRoom = async (accommodationId, roomId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `/accommodations/${accommodationId}/rooms/${roomId}/`,
        { headers }
    );

    return response.data;
};

export const getRoomMinNights = async (accommodationId, roomId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.get(
        `/accommodations/${accommodationId}/rooms/${roomId}/minnights/`,
        {
            headers,
        }
    );
    return response.data;
};

export const createRoomMinNights = async (accommodationId, roomId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/${roomId}/minnights/`,
        params,
        {
            headers,
        }
    );

    return response.data;
};

export const updateRoomMinNights = async (accommodationId, roomId, minNightId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.patch(
        `/accommodations/${accommodationId}/rooms/${roomId}/minnights/${minNightId}/`,
        params,
        {
            headers,
        }
    );
    return response.data;
};

export const removeRoomMinNights = async (accommodationId, roomId, minNightId, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `/accommodations/${accommodationId}/rooms/${roomId}/minnights/${minNightId}/`,
        {
            headers,
        }
    );
    return response.data;
};

export default {
    getAccommodationRooms,
    getCalendarRooms,
    getCalendarAvailability,
    getCalendarVariants,
    getAccommodationRoomById,
};
