import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAccommodationById } from '_services/requestAccommodations';
import actions from '_redux/actions';

const useAccommodation = (accommodationId, jwt) => {
    const [accommodationDetails, setAccommodationDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const getAccommodation = async () => {
            try {
                const accomodation = await getAccommodationById(accommodationId, jwt);
                setAccommodationDetails(accomodation);
                dispatch(actions.setAccommodation(accomodation));
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        getAccommodation();
    }, [accommodationId, jwt, dispatch]);

    return [accommodationDetails, isLoading];
};

export default useAccommodation;
