import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button, { TYPE, BUTTON_SIZE, LINE_TYPE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Input from '_atoms/Input/Input';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentMeuHotelDistanciasAcomodacaoShimmer from '_organisms/MainContentMeuHotelDistanciasAcomodacao/shimmer';
import {
    getAccommodationDistance,
    updateAccommodationDistance,
    removeAccommodationDistance,
} from '_services/requestAccommodationDistances';
import ModalAction from '_organisms/ModalAction';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentMeuHotelDistanciasAcomodacao/index.module.scss';

const validationSchema = Yup.object().shape({
    distances: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Obrigatório'),
            distance: Yup.number().typeError('Valor inválido.').required('Obrigatório'),
        })
    ),
});

const MainContentMeuHotelDistanciasAcomodacao = () => {
    const [loading, setLoading] = useState(true);
    const [modalRemove, setModalRemove] = useState(false);
    const [loadingModalDelete, setLoadingModalDelete] = useState(false);
    const [alert, setAlert] = useState({});
    const [distances, setDistances] = useState([]);
    const refDistanceId = useRef();
    const history = useHistory();
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        distances,
    };

    const handleRemove = useCallback(async () => {
        setLoadingModalDelete(true);
        setErrorMessage({});
        try {
            await removeAccommodationDistance(accommodation.id, refDistanceId.current, jwt);

            const distancesList = distances.filter(
                (distance) => distance.id !== refDistanceId.current
            );
            setDistances(distancesList);

            setAlert({
                show: true,
                message: 'Cadsatro excluído com sucesso!',
                type: 'success',
            });
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível excluir o cadastro.',
                type: 'danger',
            });
        }

        setModalRemove(false);
        setLoadingModalDelete(false);
    }, [accommodation.id, jwt, distances]);

    const handleModalRemove = useCallback(
        (distanceId) => {
            refDistanceId.current = distanceId;
            setModalRemove(!modalRemove);
        },
        [modalRemove]
    );

    const handleUpdate = useCallback(
        async (values) => {
            setLoading(true);
            setErrorMessage({});

            const distanceValue = values.distances.find(
                (distance) => distance.id === refDistanceId.current
            );

            try {
                await updateAccommodationDistance(
                    accommodation.id,
                    refDistanceId.current,
                    distanceValue,
                    jwt
                );
                setAlert({
                    show: true,
                    message: 'Cadastro atualizado com sucesso!',
                    type: 'success',
                });
            } catch (error) {
                setErrorMessage(error);
                setAlert({
                    show: true,
                    message: 'Não foi possível atualizar o cadastro.',
                    type: 'danger',
                });
            }
            setLoading(false);
        },
        [accommodation.id, jwt]
    );

    useEffect(() => {
        const getDistances = async () => {
            const { results } = await getAccommodationDistance(accommodation.id, jwt);
            setDistances(results);
            setLoading(false);
            if (!results.length) {
                setAlert({
                    show: true,
                    message: 'Não existem cadastros de distâncias.',
                    type: 'message',
                });
            }
        };
        getDistances();
    }, [accommodation.id, jwt]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                handleUpdate(values);
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit, values } = formProps;

                return (
                    <>
                        <section className="col-xl-8 col-md-12">
                            {alert.show && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <Alert type={alert.type}>{alert.message}</Alert>
                                    </div>
                                </div>
                            )}
                            {Object.values(errorMessage).length > 0 && (
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <ErrorHandler errors={errorMessage} />
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                {loading ? (
                                    <>
                                        <MainContentMeuHotelDistanciasAcomodacaoShimmer />
                                        <MainContentMeuHotelDistanciasAcomodacaoShimmer />
                                        <MainContentMeuHotelDistanciasAcomodacaoShimmer />
                                    </>
                                ) : (
                                    <>
                                        {values.distances.map((distance, idx) => (
                                            <SectionBorder key={distance.id}>
                                                <Heading
                                                    level={LEVEL.h5}
                                                    className={style.sectionTitle}
                                                >
                                                    Distância da acomodação #{idx + 1}
                                                </Heading>

                                                <div className="row">
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <Input
                                                            label="Nome"
                                                            name={`distances.${idx}.name`}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <Input
                                                            label="Distância (em km)"
                                                            name={`distances.${idx}.distance`}
                                                            inputMode="decimal"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3 justify-content-betwen">
                                                    <div className="col d-flex justify-content-end">
                                                        <Button
                                                            type={TYPE.link}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() =>
                                                                handleModalRemove(distance.id)
                                                            }
                                                        >
                                                            Remover
                                                        </Button>
                                                        <Button
                                                            type={TYPE.secondary}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() => {
                                                                refDistanceId.current = distance.id;
                                                                return refDistanceId.current;
                                                            }}
                                                            typeSubmit
                                                            disabled={isSubmitting}
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </SectionBorder>
                                        ))}
                                        <div className="container">
                                            <div
                                                className={`row ${
                                                    values.distances.length
                                                        ? 'justify-content-end'
                                                        : ''
                                                }`}
                                            >
                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        <Button
                                                            type={LINE_TYPE.secondary}
                                                            size={BUTTON_SIZE.normal}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/meu-hotel/distancia-da-acomodacao/novo`
                                                                )
                                                            }
                                                            isBlock
                                                        >
                                                            Criar distância da acomodação
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        </section>
                        {modalRemove && (
                            <ModalAction
                                showModal={modalRemove}
                                modalName="cancelBooking"
                                title="Deseja realmente deletar a distância da acomodação?"
                                type="danger"
                                submitLabel="Confirmar"
                                cancelLabel="Cancelar"
                                onCancel={handleModalRemove}
                                onSubmit={handleRemove}
                                onhandleCloseModal={handleModalRemove}
                                closeOnOverlay
                                closeOnEsc
                                isLoading={loadingModalDelete}
                            />
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelDistanciasAcomodacao;
