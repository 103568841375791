import React from 'react';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentMeuHotel from '_organisms/SideContentMeuHotel';
import MainContentMeuHotelDistanciaDaAcomodacaoCadastro from '_organisms/MainContentMeuHotelDistanciaDaAcomodacaoCadastro';

const DistanciaDaAcomodacaoCadastro = () => (
    <TemplateMeuHotelContent
        title="Distância da acomodação"
        main={<MainContentMeuHotelDistanciaDaAcomodacaoCadastro />}
        side={<SideContentMeuHotel currentPath="/meu-hotel/distancia-da-acomodacao/novo" />}
        linkBack
    />
);

export default DistanciaDaAcomodacaoCadastro;
