import React from 'react';
import TemplateLogin from '_templates/TemplateLogin';
import PasswordRecovery from '_organisms/PasswordRecovery';

const EsqueciSenha = () => (
    <TemplateLogin title="Recuperação de senha">
        <PasswordRecovery />
    </TemplateLogin>
);

export default EsqueciSenha;
