import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import Heading, { LEVEL } from '_atoms/Heading';
import Alert from '_molecules/Alert';
import TextArea from '_atoms/TextArea/TextArea';
import Label from '_atoms/Label';
import Checkbox from '_atoms/Checkbox/Checkbox';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import SectionBorder from '_molecules/SectionBorder';
import actions from '_redux/actions';
import { accommodationFeatures } from '_services/requestAccommodations';
import MainContentMeuHotelInformacoesShimmer from '_organisms/MainContentMeuHotelInformacoes/shimmer';
import useAccommodation from '_hooks/useAccommodation';
import ErrorHandler from '_molecules/ErrorHandler';

import style from '_organisms/MainContentMeuHotelInformacoes/index.module.scss';

const MainContentMeuHotelInformacoes = () => {
    const [featuresAccommodation, setFeaturesAccommodation] = useState([]);
    const { accommodation, authentication: jwt } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const [accommodationDetails, isLoading] = useAccommodation(accommodation.id, jwt);
    const [errorMessage, setErrorMessage] = useState({});

    const initValues = {
        details: accommodationDetails.details || '',
        features: accommodationDetails.features
            ? accommodationDetails.features.map((feature) => feature.id.toString())
            : [],
    };

    const onHandleSubmit = async (values) => {
        setLoading(true);
        setErrorMessage({});
        try {
            await dispatch(actions.updateAccommodation(accommodation.id, jwt, values));
            setAlert({
                show: true,
                message: 'As informações do hotel foram atualizadas!',
                type: 'success',
            });
        } catch (error) {
            setErrorMessage(error);
            setAlert({
                show: true,
                message: 'Não foi possível atualizar as informações.',
                type: 'danger',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        const getAccommodationFeatures = async () => {
            const { results } = await accommodationFeatures(jwt);
            setFeaturesAccommodation(results);
        };

        getAccommodationFeatures();
    }, [jwt]);

    useEffect(() => setLoading(isLoading), [isLoading]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                onHandleSubmit(values, setFieldError);
                setSubmitting(false);
            }}
        >
            {(formProps) => {
                const { isSubmitting, handleChange, handleBlur, handleSubmit } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        {Object.values(errorMessage).length > 0 && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <ErrorHandler errors={errorMessage} />
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <SectionBorder>
                                <Heading level={LEVEL.h6} className={style.sectionTitle}>
                                    Descrição
                                </Heading>
                                {loading ? (
                                    <MainContentMeuHotelInformacoesShimmer />
                                ) : (
                                    <>
                                        <div className="row mt-3">
                                            <div className="col-xl-12 col-md-12">
                                                <TextArea
                                                    label="Sobre o hotel"
                                                    name="details"
                                                    rows={5}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Informações do hotel"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-xl-12 col-md-12">
                                                <Label>Selecione as características do hotel</Label>
                                            </div>
                                        </div>
                                        {!featuresAccommodation.length ? (
                                            <p>Carregando</p>
                                        ) : (
                                            <>
                                                {featuresAccommodation.map((feature) => (
                                                    <div className="row mt-3" key={feature.id}>
                                                        <div className="col-xl-12 col-md-12">
                                                            <Checkbox
                                                                name="features"
                                                                value={feature.id}
                                                            >
                                                                {feature.name}
                                                            </Checkbox>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </SectionBorder>
                            <div className="container">
                                <div
                                    className={classNames(
                                        'row justify-content-end',
                                        style.blockButton
                                    )}
                                >
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            <Button
                                                type={TYPE.secondary}
                                                size={BUTTON_SIZE.normal}
                                                onClick={() => {}}
                                                typeSubmit
                                                isBlock
                                                disabled={isSubmitting}
                                            >
                                                Salvar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

export default MainContentMeuHotelInformacoes;
