import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '_organisms/Modal';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Input from '_atoms/Input/Input';
import Heading, { LEVEL } from '_atoms/Heading';
import Icon from '_atoms/Icon';
import Alert from '_molecules/Alert';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import { createUser } from '_services/requestSession';
import ModalNewUserShimmer from '_organisms/ModalNewUser/shimmer';

import cadastro from '_images/dados-cadastrais.svg';
import style from '_organisms/ModalNewUser/index.module.scss';

const ModalNewUser = ({ onSubmit, onCancel, isLoading, ...props }) => {
    const initValues = {
        name: '',
        email: '',
    };

    const { accommodation, authentication } = useSelector((state) => state);

    const [alert, setAlert] = useState({ show: false, message: [], type: '' });
    const [loading, setLoading] = useState(isLoading);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Obrigaatório'),
        email: Yup.string().email('E-mail inválido').required('Obrigatório'),
    });

    const onHandleSubmit = async (values) => {
        const jwt = authentication;
        setLoading(true);
        setAlert({
            show: false,
            message: [],
            type: '',
        });

        const response = await createUser(jwt, values);
        setLoading(false);
        if (response.status >= 400) {
            const messageArray = [];

            Object.keys(response.data).forEach((item) => {
                messageArray.push(`${response.data[item]}`);
            });

            setAlert({
                show: true,
                message: messageArray,
                type: 'danger',
            });
            return;
        }

        onSubmit(response);
    };

    return (
        <>
            <Modal modalName="newUser" onhandleCloseModal={onCancel} {...props}>
                <div className={classNames(style.containerModal)}>
                    <Icon image={cadastro} alt="icone" size="85" classname={style.icon} />
                    <Heading level={LEVEL.h2}>Convidar membros</Heading>
                    <p className={style.text}>
                        {`Convide um novo usuário para integrar a equipe do ${accommodation.tradeMark}`}
                    </p>
                    <Formik
                        initialValues={initValues}
                        enableReinitialize
                        onSubmit={(values, { setSubmitting, setFieldError }) => {
                            onHandleSubmit(values, setFieldError);
                            setSubmitting(false);
                        }}
                        validationSchema={validationSchema}
                    >
                        {(formProps) => {
                            const { isSubmitting, handleSubmit } = formProps;

                            return (
                                <form onSubmit={handleSubmit}>
                                    {loading ? (
                                        <ModalNewUserShimmer />
                                    ) : (
                                        <>
                                            {alert.show && (
                                                <Alert type={alert.type}>
                                                    {alert.message.map((message) => (
                                                        <Fragment key={message}>{message}</Fragment>
                                                    ))}
                                                </Alert>
                                            )}
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="Nome"
                                                        name="name"
                                                        placeholder="Digite o nome"
                                                        autoCapitalize="off"
                                                        autoCorrect="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Input
                                                        label="E-mail"
                                                        name="email"
                                                        placeholder="Digite o email"
                                                        inputMode="email"
                                                        autoCapitalize="off"
                                                        autoCorrect="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Button
                                                        type={TYPE.secondary}
                                                        size={BUTTON_SIZE.large}
                                                        onClick={() => {}}
                                                        typeSubmit
                                                        isBlock
                                                        disabled={isSubmitting}
                                                    >
                                                        Enviar convite
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Button
                                                        type={TYPE.link}
                                                        size={BUTTON_SIZE.large}
                                                        onClick={onCancel}
                                                        isBlock
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
        </>
    );
};

ModalNewUser.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool,
};

ModalNewUser.defaultProps = {
    onCancel: undefined,
    isLoading: false,
};

export default ModalNewUser;
