import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';

import TemplateDefault from '_templates/TemplateDefault';
import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Input from '_atoms/Input/Input';
import Checkbox from '_atoms/Checkbox/Checkbox';
import TextArea from '_atoms/TextArea/TextArea';
import Label from '_atoms/Label';
import Alert from '_molecules/Alert';
import SectionBorder from '_molecules/SectionBorder';
import { CEP_MASK, numberOnly, HOUR_MASK, CNPJ_MASK, PHONE_MASK } from '_utils/inputMasks';
import requestZipCode from '_services/requestZipCode';
import { createAccomodation, accommodationFeatures } from '_services/requestAccommodations';
import CadastrarHotelShimmer from '_pages/cadastrar-hotel/shimmer';
import actions from '_redux/actions';
import ErrorHandler from '_molecules/ErrorHandler';
import { searchCities } from '_services/requestCities';
import Select from '_atoms/Select/Select';
import OPTIONS_STATES from '_config/mocks-states';
import LineInstruction from '_molecules/LineInstruction';

import style from '_pages/cadastrar-hotel/index.module.scss';

const initialValues = {
    is_active: true,
    best_price: true,
    is_preferential: false,
    trade_mark: '',
    company_name: '',
    document: '',
    slug: '',
    state_register: '',
    municipal_register: '',
    email: '',
    reservation_email: '',
    phone: '',
    website: '',
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    days_to_pay_after_checkout: '',
    check_in: '',
    check_out: '',
    details: '',
};

const CadastrarHotel = () => {
    const { authentication: jwt } = useSelector((state) => state);
    const [featuresAccommodation, setFeaturesAccommodation] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasAlert, setHasAlert] = useState(false);
    const isCepValid = useRef(true);
    const { push } = useHistory();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState({});
    const [isFieldDisabled, setIsFieldDisabled] = useState(true);
    const [searchCityValue, setSearchCityValue] = useState('');
    const [cityData, setCityData] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [noSuggestions, setNoSuggestions] = useState(false);

    const validationSchema = Yup.object().shape({
        trade_mark: Yup.string().required('Obrigatório'),
        company_name: Yup.string().required('Obrigatório'),
        document: Yup.string().required('Obrigatório'),
        email: Yup.string().email('Digite um e-mail válido').required('Obrigatório'),
        reservation_email: Yup.string().email('Digite um e-mail válido').required('Obrigatório'),
        website: Yup.string().url('Digite uma url válida'),
        phone: Yup.string().required('Obrigatório'),
        zipcode: Yup.string()
            .min(8, 'Cep inválido')
            .required('Obrigatório')
            .test('minValue', 'Cep deve possuir 8 dígitos', (value) => {
                const cepDigit = numberOnly(value);
                return cepDigit.length === 8;
            })
            .test('isCepValid', 'CEP inválido', () => isCepValid.current),
        street: Yup.string().required('Obrigatório'),
        number: Yup.number().typeError('Apenas número').required('Obrigatório'),
        neighborhood: Yup.string().required('Obrigatório'),
        city: Yup.string()
            .required('Obrigatório')
            .test('city', 'Obrigatório', (value) => parseInt(value, 10) !== 0),
        days_to_pay_after_checkout: Yup.number()
            .positive('Valor inválido')
            .integer('Valor inválido')
            .typeError('Apenas números')
            .required('Obrigatório'),
        check_in: Yup.string().required('Obrigatório'),
        check_out: Yup.string().required('Obrigatório'),
        municipal_register: Yup.number().integer().typeError('Apenas números'),
        state_register: Yup.number().integer().typeError('Apenas números'),
    });

    const onHandleSubmit = async (values) => {
        try {
            setHasAlert({ show: false });
            setErrorMessage({});
            setIsLoading(true);
            const params = {
                ...values,
                document: numberOnly(values.document),
                municipal_register: numberOnly(values.municipal_register),
                state_register: numberOnly(values.state_register),
                city: cityData.id,
                zipcode: numberOnly(values.zipcode),
                phone: numberOnly(values.phone),
            };

            const hotel = await createAccomodation(jwt, params);
            dispatch(actions.setAccommodation(hotel));
            push('/meu-hotel/');
        } catch (error) {
            setErrorMessage(error);
            setIsLoading(false);
            setHasAlert({
                show: true,
                message: 'Não foi possível realizar o cadastro.',
                type: 'danger',
            });
        }
    };

    const handleCepBlur = (e, setFieldTouched, setFieldError) => {
        const { value } = e.target;

        setFieldTouched('zipcode', true, false);

        if (!value) {
            setFieldError('zipcode', 'Obrigatório');
        }
    };

    const handleCep = async (e, setFieldValue, setFieldTouched, setFieldError) => {
        const { name, value } = e.target;
        const cepDigit = numberOnly(value);
        setFieldValue(name, value);
        setErrorMessage({});

        if (cepDigit.length === 8) {
            try {
                setErrorMessage({});
                const response = await requestZipCode(numberOnly(value), jwt);
                const zipAddress = {
                    number: '',
                    ...response,
                    city: response.city.name,
                    state: response.city.state,
                };
                isCepValid.current = true;

                Object.entries(zipAddress).forEach(([fieldName, fieldValue]) => {
                    setFieldValue(fieldName, fieldValue);
                });
                setCityData(response.city);
                setSearchCityValue(response.city.name);
                setIsFieldDisabled(true);
            } catch (error) {
                if (
                    error.response.data.error &&
                    error.response.data.error[0] === 'zipcode not exists'
                ) {
                    isCepValid.current = false;
                    setFieldTouched('zipcode', true, false);
                    setFieldError('zipcode', 'CEP inválido');
                    setErrorMessage(error);
                } else {
                    isCepValid.current = true;
                    setErrorMessage({});

                    setSearchCityValue('');
                    setCityData({});
                    setFieldValue('street', '');
                    setFieldValue('number', '');
                    setFieldValue('neighborhood', '');
                    setFieldValue('complement', '');

                    setIsFieldDisabled(false);
                }
            }
        }
    };

    useEffect(() => {
        const getAccommodationFeatures = async () => {
            const { results } = await accommodationFeatures(jwt);
            setFeaturesAccommodation(results);
        };

        getAccommodationFeatures();
    }, [jwt]);

    const handleChangeSearchCity = useCallback((event, { newValue }) => {
        if (!newValue) setCityData('');

        setSearchCityValue(newValue);
    }, []);

    const handleBlurSearchCity = useCallback((event, { highlightedSuggestion }) => {
        if (!highlightedSuggestion) return;
        setSearchCityValue(highlightedSuggestion.name);
        setCityData(highlightedSuggestion);
    }, []);

    const handleSuggestionsFetchRequested = useCallback(
        async ({ value }) => {
            try {
                const response = await searchCities(value, jwt);
                const cities = response.map((city) => city);
                setSuggestions(cities);
                setNoSuggestions(value && cities.length === 0);
            } catch (error) {
                setNoSuggestions(false);
                setSuggestions([]);
            }
        },
        [jwt]
    );

    const handleSuggestionsClearRequested = useCallback(() => {
        setNoSuggestions(false);
        setSuggestions([]);
    }, []);

    const getSuggestionValue = useCallback((suggestion) => {
        setCityData(suggestion);

        return suggestion.name;
    }, []);

    const renderSuggestion = useCallback(
        (suggestion) => (
            <p>
                <span className={style.destinationName}>{suggestion.name}</span>
            </p>
        ),
        []
    );

    const inputProps = useMemo(
        () => ({
            placeholder: 'Cidade',
            name: 'city',
            value: searchCityValue,
            onChange: handleChangeSearchCity,
            onBlur: handleBlurSearchCity,
            autoComplete: 'off',
            disabled: isFieldDisabled,
        }),
        [handleBlurSearchCity, handleChangeSearchCity, isFieldDisabled, searchCityValue]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onHandleSubmit(values, resetForm);
                setSubmitting(false);
            }}
        >
            {(formProps) => {
                const {
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    errors,
                } = formProps;

                return (
                    <form onSubmit={handleSubmit}>
                        {isLoading ? (
                            <CadastrarHotelShimmer />
                        ) : (
                            <TemplateDefault title="Novo hotel">
                                <div>
                                    {hasAlert.show && (
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <Alert type={hasAlert.type}>
                                                    {hasAlert.message}
                                                </Alert>
                                            </div>
                                        </div>
                                    )}
                                    {Object.values(errorMessage).length > 0 && (
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <ErrorHandler errors={errorMessage} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <SectionBorder>
                                                <div className="row mt-3">
                                                    <div className="col-xl-4 col-md-4">
                                                        <Checkbox name="is_active">
                                                            Hotel ativo
                                                        </Checkbox>
                                                    </div>
                                                    <div className="col-xl-4 col-md-4">
                                                        <Checkbox name="best_price">
                                                            Sob consulta
                                                        </Checkbox>
                                                    </div>
                                                    <div className="col-xl-4 col-md-4">
                                                        <Checkbox name="is_preferential">
                                                            Preferencial
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6 mb-3 mb-lg-0">
                                                        <Input
                                                            label="Nome fantasia"
                                                            name="trade_mark"
                                                            placeholder="Digite o nome fantasia do hotel"
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input
                                                            label="Razão social"
                                                            name="company_name"
                                                            placeholder="Digite a razão social do hotel"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input
                                                            label="Registro municipal"
                                                            name="municipal_register"
                                                            placeholder="Digite o registro municipal"
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input
                                                            label="Registro estadual"
                                                            name="state_register"
                                                            placeholder="Digite o registro estadual"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6">
                                                        <MaskedInput
                                                            label="CNPJ"
                                                            mask={CNPJ_MASK}
                                                            name="document"
                                                            placeholder="Digite o CNPJ do hotel"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            inputMode="decimal"
                                                            autoCorrect="off"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input label="Slug" name="slug" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6">
                                                        <MaskedInput
                                                            label="Telefone"
                                                            placeholder="Digite o telefone"
                                                            mask={PHONE_MASK}
                                                            name="phone"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input
                                                            label="Website"
                                                            name="website"
                                                            placeholder="Digite a url do site"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input
                                                            label="E-mail"
                                                            name="email"
                                                            placeholder="Digite um e-mail"
                                                        />
                                                    </div>
                                                    <div className="col-xm-6 col-md-6">
                                                        <Input
                                                            label="E-mail de reserva"
                                                            name="reservation_email"
                                                            placeholder="Digite um e-mail para reservas"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-4 col-md-4 mt-3">
                                                        <MaskedInput
                                                            label="CEP"
                                                            mask={CEP_MASK}
                                                            placeholder="Digite o CEP"
                                                            name="zipcode"
                                                            inputMode="decimal"
                                                            autoCorrect="off"
                                                            onChange={(e) =>
                                                                handleCep(
                                                                    e,
                                                                    setFieldValue,
                                                                    setFieldTouched,
                                                                    setFieldError
                                                                )
                                                            }
                                                            onBlur={(e) =>
                                                                handleCepBlur(
                                                                    e,
                                                                    setFieldTouched,
                                                                    setFieldError
                                                                )
                                                            }
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <Input
                                                            label="Logradouro"
                                                            name="street"
                                                            placeholder="Rua, Av, Servidão... "
                                                        />
                                                    </div>
                                                    <div className="col-xl-2 col-md-2 mt-3">
                                                        <Input
                                                            label="Nº"
                                                            placeholder="N"
                                                            name="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <Input
                                                            label="Complemento"
                                                            name="complement"
                                                            placeholder="Digite o complemento"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <Input
                                                            label="Bairro"
                                                            name="neighborhood"
                                                            placeholder="Digite o bairro"
                                                            disabled={isFieldDisabled}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <div className={style.searchInputBox}>
                                                            <Label>Cidade</Label>
                                                            <Autosuggest
                                                                suggestions={suggestions}
                                                                onSuggestionsFetchRequested={
                                                                    handleSuggestionsFetchRequested
                                                                }
                                                                onSuggestionsClearRequested={
                                                                    handleSuggestionsClearRequested
                                                                }
                                                                getSuggestionValue={
                                                                    getSuggestionValue
                                                                }
                                                                renderSuggestion={renderSuggestion}
                                                                inputProps={inputProps}
                                                                theme={{
                                                                    ...style,
                                                                    input: `${style.contentSearch}`,
                                                                }}
                                                                highlightFirstSuggestion
                                                            />
                                                            {noSuggestions && (
                                                                <div
                                                                    className={style.noSuggestions}
                                                                >
                                                                    Nenhuma cidade foi encontrada
                                                                </div>
                                                            )}
                                                            {!searchCityValue && errors.city && (
                                                                <LineInstruction
                                                                    className={classNames(
                                                                        style.errorMessage
                                                                    )}
                                                                >
                                                                    {errors.city}
                                                                </LineInstruction>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-md-6 mt-3">
                                                        <Select
                                                            label="Estado"
                                                            name="state"
                                                            quantityOptions={OPTIONS_STATES}
                                                            disabled={isFieldDisabled}
                                                        />
                                                    </div>
                                                </div>
                                            </SectionBorder>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <SectionBorder>
                                                <div className="row">
                                                    <div className="col-xl-6 col-md-6">
                                                        <Input
                                                            label="Dias para pagar após check-out"
                                                            name="days_to_pay_after_checkout"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xl-6 col-md-6">
                                                        <MaskedInput
                                                            label="Check-in"
                                                            mask={HOUR_MASK}
                                                            placeholder="Digite o horário de check-in"
                                                            name="check_in"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            inputMode="tel"
                                                            autoCorrect="off"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-md-6">
                                                        <MaskedInput
                                                            label="Check-out"
                                                            mask={HOUR_MASK}
                                                            placeholder="Digite o horário de check-out"
                                                            name="check_out"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            inputMode="tel"
                                                            autoCorrect="off"
                                                            render={(ref, props) => (
                                                                <Input ref={ref} {...props} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12 mt-3">
                                                        <TextArea
                                                            label="Sobre o hotel"
                                                            name="details"
                                                            rows={5}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Informações do hotel"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xl-12 col-md-12">
                                                        <Label>
                                                            Selecione as características do hotel
                                                        </Label>
                                                    </div>
                                                </div>
                                                {!featuresAccommodation.length ? (
                                                    <p>Carregando</p>
                                                ) : (
                                                    <>
                                                        {featuresAccommodation.map((feature) => (
                                                            <div
                                                                className="row mt-3"
                                                                key={feature.id}
                                                            >
                                                                <div className="col-xl-12 col-md-12">
                                                                    <Checkbox
                                                                        name="features"
                                                                        value={feature.id}
                                                                    >
                                                                        {feature.name}
                                                                    </Checkbox>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </SectionBorder>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className=" col-sm-12 col-md-auto">
                                            <Button
                                                type={TYPE.link}
                                                size={BUTTON_SIZE.normal}
                                                onClick={() => {}}
                                                className="button-auto-multi-submit mt-3"
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                type={TYPE.secondary}
                                                size={BUTTON_SIZE.normal}
                                                onClick={() => {}}
                                                typeSubmit
                                                className="button-auto-multi-submit mt-3"
                                                disabled={isSubmitting}
                                            >
                                                Salvar hotel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </TemplateDefault>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default CadastrarHotel;
