import React from 'react';
import classNames from 'classnames';
import TemplateDefault from '_templates/TemplateDefault';
import SectionBorder from '_molecules/SectionBorder';

import style from '_pages/reserva/shimmer.module.scss';

const ReservaShimmer = () => (
    <TemplateDefault title="Reserva">
        <div className={style.content}>
            <div className="row">
                <div className="col-xl-6 col-md-6">
                    <SectionBorder>
                        <div className={style.blockCompanion} />
                    </SectionBorder>
                    <SectionBorder>
                        <div className={style.blockCompanion} />
                    </SectionBorder>
                </div>
                <div className={classNames('col-xl-6 col-md-6', style.extraMargin)}>
                    <SectionBorder>
                        <div className={style.blockCompanion} />
                    </SectionBorder>
                </div>
            </div>
        </div>
    </TemplateDefault>
);

export default ReservaShimmer;
