import { SET_MODAL_BOOKINGS, SET_MODAL_TITLE } from '_redux/types';

const setModalBookings = (bookings) => (dispatch) => {
    dispatch({ type: SET_MODAL_BOOKINGS, payload: bookings });
};

const setModalTitle = (title) => (dispatch) => {
    dispatch({ type: SET_MODAL_TITLE, payload: title });
};

export default {
    setModalBookings,
    setModalTitle,
};
