import React from 'react';
import PropTypes from 'prop-types';
import bgLogin from '_images/bg-login.png';
import logoMini from '_images/logo-mini-white.svg';
import logo from '_images/logo-mini.svg';
import Logo from '_atoms/Logo';
import Heading, { LEVEL } from '_atoms/Heading';

import style from '_templates/TemplateLogin/index.module.scss';

const TemplateLogin = ({ title, children }) => (
    <section className={style.loginContent}>
        <div className={style.left}>
            <img className={style.img} src={bgLogin} alt="Login Background" />
            <img className={style.logo} src={logoMini} alt="Viajar Faz Bem Logo" />
        </div>
        <div className={style.right}>
            <div className={style.formContent}>
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className={style.blockLogo}>
                            <Logo src={logo} alt="Viajar Faz Bem Logo" />
                        </div>
                        <Heading className={style.header} level={LEVEL.h3}>
                            {title}
                        </Heading>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-md-12">{children}</div>
                </div>
            </div>
        </div>
    </section>
);

TemplateLogin.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
};

TemplateLogin.defaultProps = {
    children: undefined,
    title: '',
};

export default TemplateLogin;
