import CryptoJS from 'crypto-js';

const key = process.env.REACT_APP_KEY_CRYPT;

export const encrypt = (object) => {
    const encryptText = CryptoJS.AES.encrypt(JSON.stringify(object), key).toString();
    return encryptText;
};

export const decrypt = (encryptText) => {
    try {
        const decryptedtext = JSON.parse(
            CryptoJS.AES.decrypt(encryptText, key).toString(CryptoJS.enc.Utf8)
        );
        return decryptedtext;
    } catch {
        return null;
    }
};
