import React from 'react';

import style from '_atoms/CalendarDescriptionDay/index-shimmer.module.scss';

const CalendarDescriptionDayShimmer = () => {
    const renderMonth = () => {
        const blockShimmer = [];

        for (let i = 0; i < 17; i += 1) {
            blockShimmer.push(
                <div key={i} className={style.CalendarDescriptionDay}>
                    <p />
                </div>
            );
        }
        return blockShimmer;
    };

    return renderMonth();
};

export default CalendarDescriptionDayShimmer;
