import React from 'react';
import PropTypes from 'prop-types';
import Dashboard from '_templates/Dashboard';
import Heading, { LEVEL } from '_atoms/Heading';
import ArrowIcon, { TYPE, COLOR } from '_atoms/ArrowIcon';
import { useHistory } from 'react-router-dom';

import style from '_templates/TemplateMeuHotelContent/index.module.scss';

const TemplateMeuHotelContent = ({ title, description, side, main, linkBack, buttons }) => {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    return (
        <Dashboard>
            <section className={style.blockHeader}>
                {linkBack && (
                    <button className={style.button} type="button" onClick={goBack}>
                        <ArrowIcon
                            className={style.arrow}
                            type={TYPE.arrowLeft}
                            color={COLOR.secondary}
                            withBox
                        />
                    </button>
                )}
                <div className={style.headers}>
                    <div className={style.blockTitle}>
                        {title && (
                            <Heading level={LEVEL.h1} className={style.h1}>
                                {title}
                            </Heading>
                        )}
                        {description && (
                            <Heading className={style.subtitle} level={LEVEL.h5}>
                                {description}
                            </Heading>
                        )}
                    </div>
                    {buttons && <div className={style.blockButtons}>{buttons}</div>}
                </div>
            </section>
            <div className={style.masterContent}>
                <div className={style.sideContent}>{side}</div>
                <div className={style.mainContent}>{main}</div>
            </div>
        </Dashboard>
    );
};

TemplateMeuHotelContent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    side: PropTypes.node.isRequired,
    main: PropTypes.node.isRequired,
    linkBack: PropTypes.bool,
    buttons: PropTypes.node,
};

TemplateMeuHotelContent.defaultProps = {
    title: undefined,
    description: undefined,
    linkBack: false,
    buttons: undefined,
};

export default TemplateMeuHotelContent;
