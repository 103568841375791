import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button, { TYPE, BUTTON_SIZE } from '_atoms/Button';
import Heading, { LEVEL } from '_atoms/Heading';
import Label from '_atoms/Label';
import Checkbox from '_atoms/Checkbox/Checkbox';
import ButtonShimmer from '_atoms/Button/shimmer';
import SectionBorder from '_molecules/SectionBorder';
import Alert from '_molecules/Alert';
import MainContentQuartoCaracteristicasShimmer from '_organisms/MainContentQuartoCaracteristicas/shimmer';
import { roomsFeatures, updateAccommodationRoom } from '_services/requestRooms';

import style from '_organisms/MainContentQuartoCaracteristicas/index.module.scss';

const MainContentQuartoCaracteristicas = ({ room, updateRoom }) => {
    const history = useHistory();
    const [alert, setAlert] = useState({});
    const [features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(false);
    const { accommodation, authentication: jwt } = useSelector((state) => state);

    const initValues = {
        features: room.features ? room.features.map((feature) => feature.id.toString()) : [],
    };

    useEffect(() => {
        const getFeatures = async () => {
            setLoading(true);
            const { results } = await roomsFeatures(jwt);
            setFeatures(results);
            setLoading(false);
        };

        getFeatures();
    }, [jwt]);

    const onHandleSubmit = async (values) => {
        setLoading(true);
        try {
            await updateAccommodationRoom(accommodation.id, room.id, values, jwt);
            setLoading(false);
            setAlert({
                show: true,
                message: 'As informações do quarto foram atualizadas!',
                type: 'success',
            });
            updateRoom();
        } catch (error) {
            setAlert({
                show: true,
                message: 'Não foi possível atualizar as informações!',
                type: 'danger',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (history.location.data) {
            setAlert(history.location.data);
        }

        return () => setAlert({});
    }, [history.location.data]);

    return (
        <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                onHandleSubmit(values);
                setSubmitting(false);
            }}
        >
            {(formProps) => {
                const { isSubmitting, handleSubmit } = formProps;

                return (
                    <section className="col-xl-8 col-md-12">
                        {alert.show && (
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <Alert type={alert.type}>{alert.message}</Alert>
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <SectionBorder>
                                <div>
                                    <Heading level={LEVEL.h6} className={style.sectionTitle}>
                                        Características do quarto
                                    </Heading>
                                    {loading ? (
                                        <MainContentQuartoCaracteristicasShimmer />
                                    ) : (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-12 col-md-12">
                                                    <Label>Selecione as características</Label>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                {features.map((feature) => (
                                                    <div
                                                        className="col-xl-6 col-md-6 mb-3"
                                                        key={feature.id}
                                                    >
                                                        <Checkbox
                                                            name="features"
                                                            value={feature.id}
                                                        >
                                                            {feature.name}
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </SectionBorder>

                            <div className="container">
                                <div className="row justify-content-end">
                                    <div className="row mt-3">
                                        <div className="col-xl-12 col-md-12">
                                            {room.isLoading ? (
                                                <ButtonShimmer />
                                            ) : (
                                                <Button
                                                    type={TYPE.secondary}
                                                    size={BUTTON_SIZE.normal}
                                                    onClick={() => {}}
                                                    typeSubmit
                                                    isBlock
                                                    disabled={isSubmitting}
                                                >
                                                    Salvar
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                );
            }}
        </Formik>
    );
};

MainContentQuartoCaracteristicas.propTypes = {
    room: PropTypes.shape({
        isLoading: PropTypes.bool,
        features: PropTypes.arrayOf(PropTypes.object),
        id: PropTypes.number,
    }).isRequired,
    updateRoom: PropTypes.func.isRequired,
};

export default MainContentQuartoCaracteristicas;
