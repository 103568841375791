import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { MdClear } from 'react-icons/md';

import Button, { TYPE } from '_atoms/Button';
import Input, { INPUT_STATE } from '_atoms/Input';
import DropdownMenu from '_molecules/DropdownMenu';
import { DATE_MASK } from '_helpers/inputMasks';
import controlDropDownPosition from '_utils/controlDropDownPosition';

import style from '_molecules/DropDownDateRange/index.module.scss';

const DropDownDateRange = ({ label, handleDate, initialActiveState }) => {
    const [showDateRange, setShowDateRange] = useState(false);
    const [reverseDropDown, setReverseDropDown] = useState(false);
    const [invalidField, setInvalidField] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [dates, setDates] = useState();
    const [active, setActive] = useState(initialActiveState);
    const dropDownSubMenu = useRef(null);

    const handleMenuButtonDate = () => {
        setShowDateRange(!showDateRange);
        setShowDropDown(true);
    };

    const handleStates = () => {
        setShowDateRange(false);
        setShowDropDown(false);
        setInvalidField(false);
        setActive(true);
    };

    const filterOptions = [
        {
            name: 'Este mês',
            action: () => {
                handleStates();
                handleDate([
                    moment().startOf('month').format('YYYY-MM-DD'),
                    moment().endOf('month').format('YYYY-MM-DD'),
                ]);
            },
        },
        {
            name: 'Esta semana',
            action: () => {
                handleStates();
                handleDate([
                    moment().startOf('week').format('YYYY-MM-DD'),
                    moment().endOf('week').format('YYYY-MM-DD'),
                ]);
            },
        },
        {
            name: 'Hoje',
            action: () => {
                handleStates();
                handleDate([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
            },
        },
        {
            name: 'Ontem',
            action: () => {
                handleStates();
                handleDate([
                    moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    moment().subtract(1, 'days').format('YYYY-MM-DD'),
                ]);
            },
        },
        {
            name: 'Semana passada',
            action: () => {
                handleStates();
                handleDate([
                    moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD'),
                    moment().startOf('week').subtract(1, 'days').format('YYYY-MM-DD'),
                ]);
            },
        },
        {
            name: 'Mês passado',
            action: () => {
                handleStates();
                handleDate([
                    moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
                    moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
                ]);
            },
        },
    ];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDates((state) => ({ ...state, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!dates || !dates.dateStart || !dates.dateEnd) {
            setInvalidField(true);
            return;
        }

        const dateStart = moment(dates.dateStart, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const dateEnd = moment(dates.dateEnd, 'DD-MM-YYYY').format('YYYY-MM-DD');

        if (!moment(dateStart).isValid() || !moment(dateEnd).isValid()) {
            setInvalidField(true);
            return;
        }

        setInvalidField(false);
        setShowDropDown(false);
        setActive(true);

        handleDate([
            moment(dates.dateStart, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            moment(dates.dateEnd, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        ]);
    };

    useEffect(() => {
        setReverseDropDown(controlDropDownPosition(dropDownSubMenu.current));
    }, [showDateRange]);

    return (
        <div className={style.container}>
            <div className={style.content}>
                <DropdownMenu
                    trigger={
                        <Button
                            onClick={() => setShowDropDown(showDateRange)}
                            className={classNames(style.buttonComponent, {
                                [style.isActive]: active,
                            })}
                        >
                            {label}
                        </Button>
                    }
                    className={classNames(style.dropDownMenuContent, {
                        [style.dropDownReverse]: reverseDropDown,
                    })}
                    keepOnClickInside={showDropDown}
                >
                    <ul className={style.nav}>
                        {filterOptions.map((option) => (
                            <li className={style.navItem} key={option.name}>
                                <button
                                    type="button"
                                    onClick={option.action}
                                    className={style.navButton}
                                >
                                    {option.name}
                                </button>
                            </li>
                        ))}
                        <li className={style.navItem}>
                            <button
                                type="button"
                                onClick={handleMenuButtonDate}
                                className={style.navButton}
                            >
                                Personalizar
                            </button>
                        </li>
                    </ul>

                    <div
                        className={classNames(style.showDate, {
                            [style.active]: showDateRange,
                        })}
                    >
                        <form>
                            <div className={style.dateSearchContainer}>
                                <div
                                    className={classNames(
                                        style.dateSearchContent,
                                        style.dateSearchReverse
                                    )}
                                    ref={dropDownSubMenu}
                                >
                                    <p>
                                        <strong>Período personalizado</strong>
                                    </p>
                                    <div className={style.dateSearch}>
                                        <div className={style.dateSearchBox}>
                                            <MaskedInput
                                                label="Data inicial"
                                                mask={DATE_MASK}
                                                name="dateStart"
                                                onChange={handleChange}
                                                autoCorrect="off"
                                                state={
                                                    invalidField
                                                        ? INPUT_STATE.danger
                                                        : INPUT_STATE.default
                                                }
                                                render={(ref, props) => (
                                                    <Input inputRefCallback={ref} {...props} />
                                                )}
                                            />
                                        </div>
                                        <div className={style.dateSearchBox}>
                                            <MaskedInput
                                                label="Data final"
                                                mask={DATE_MASK}
                                                name="dateEnd"
                                                onChange={handleChange}
                                                autoCorrect="off"
                                                state={
                                                    invalidField
                                                        ? INPUT_STATE.danger
                                                        : INPUT_STATE.default
                                                }
                                                render={(ref, props) => (
                                                    <Input inputRefCallback={ref} {...props} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <Button type={TYPE.secondary} onClick={handleSubmit} typeSubmit>
                                        Aplicar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </DropdownMenu>
                {active && (
                    <div className={style.resetFilter}>
                        <button
                            onClick={() => {
                                setActive(false);
                                setShowDateRange(false);
                                handleDate([]);
                            }}
                            className={style.resetFilterButton}
                        >
                            <MdClear className={style.icon} size={16} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

DropDownDateRange.propTypes = {
    label: PropTypes.string.isRequired,
    handleDate: PropTypes.func.isRequired,
    initialActiveState: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

DropDownDateRange.defaultProps = {
    initialActiveState: false,
};

export default DropDownDateRange;
