import React, { useCallback } from 'react';
import { MdAddCircleOutline, MdClose, MdCheck } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Heading, { LEVEL } from '_atoms/Heading';
import Button, { TYPE } from '_atoms/Button';
import SectionBorder from '_molecules/SectionBorder';
import LineInstruction, { MESSAGE_TYPE } from '_molecules/LineInstruction';
import ImageGalleryShimmer from '_molecules/ImageGalleryUpload/shimmer';

import style from '_molecules/ImageGalleryUpload/index.module.scss';

const ImageGalleryUpload = ({
    onDrop,
    onDelete,
    onActiveImage,
    onSubmit,
    files,
    maxSize,
    loading,
    title,
}) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone(
        {
            onDrop,
            accept: 'image/jpg, image/jpeg, image/png',
            maxSize,
        }
    );

    const rejectedMessage = useCallback((error) => {
        const message = error.map((err) => err.errors[0].code).join();

        switch (message) {
            case 'file-too-large':
                return 'Tamanho do arquivo inválido.';
            case 'file-invalid-type':
                return 'Formato do arquivo inválido.';
            default:
                return message;
        }
    }, []);

    return (
        <>
            <SectionBorder>
                <Heading level={LEVEL.h6} className={style.title}>
                    {title}
                </Heading>
                <div className={style.content}>
                    <ul className={style.galleryList}>
                        {loading ? (
                            <ImageGalleryShimmer />
                        ) : (
                            <>
                                {!!files.length &&
                                    files.map((file) => (
                                        <li key={file.id}>
                                            <div>
                                                <label htmlFor={file.id}>
                                                    <input
                                                        type="radio"
                                                        name="image"
                                                        id={file.id}
                                                        className={style.selectImage}
                                                        onClick={() => onActiveImage(file)}
                                                        defaultChecked={!!file.image_cover}
                                                    />
                                                    <img
                                                        src={file.preview}
                                                        alt={file.name}
                                                        className={style.image}
                                                    />

                                                    <MdCheck
                                                        size={40}
                                                        color="#ffffff"
                                                        className={style.iconSelectedImage}
                                                    />
                                                </label>
                                            </div>

                                            <button
                                                type="button"
                                                className={style.btDelete}
                                                onClick={() => onDelete(file.id)}
                                            >
                                                <MdClose size={16} className={style.icon} />
                                            </button>
                                        </li>
                                    ))}
                                <li
                                    {...getRootProps({
                                        className: classNames(style.containerButtonAdd, {
                                            [style.isDragActive]: isDragActive,
                                            [style.isDragReject]: isDragReject,
                                        }),
                                    })}
                                >
                                    <button type="button" className={style.addImage}>
                                        <MdAddCircleOutline
                                            size={30}
                                            color="#2176ed"
                                            className={style.iconAdd}
                                        />
                                    </button>
                                    <input {...getInputProps()} />
                                </li>
                            </>
                        )}
                    </ul>
                    <p className={style.legend}>
                        <MdCheck
                            size={14}
                            color="#ffffff"
                            className={style.iconSelectedImageLegend}
                        />
                        Capa do site
                    </p>
                </div>
            </SectionBorder>
            <div className={style.footer}>
                <LineInstruction type={MESSAGE_TYPE.italic} className={style.erroMessages}>
                    {rejectedMessage(fileRejections)}
                </LineInstruction>
                <Button type={TYPE.secondary} onClick={onSubmit}>
                    Salvar
                </Button>
            </div>
        </>
    );
};

ImageGalleryUpload.propTypes = {
    onDrop: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onActiveImage: PropTypes.func,
    onSubmit: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.object).isRequired,
    maxSize: PropTypes.number,
    loading: PropTypes.bool,
    title: PropTypes.string,
};

ImageGalleryUpload.defaultProps = {
    onDelete: undefined,
    onActiveImage: undefined,
    onSubmit: undefined,
    maxSize: 10000000,
    loading: false,
    title: '',
};

export default ImageGalleryUpload;
