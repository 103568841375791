import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from '_atoms/BadgeCount/index.module.scss';

const BadgeCount = ({ message, className }) => (
    <div className={classNames(style.badge, className)}>
        <p className={style.badgeText}>{message}</p>
    </div>
);

BadgeCount.propTypes = {
    message: PropTypes.number,
    className: PropTypes.string,
};

BadgeCount.defaultProps = {
    message: undefined,
    className: undefined,
};

export default BadgeCount;
