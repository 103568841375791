import React from 'react';
import TemplateMeuHotelContent from '_templates/TemplateMeuHotelContent';
import SideContentMeuHotel from '_organisms/SideContentMeuHotel';
import MainContentMeuHotelPoliticas from '_organisms/MainContentMeuHotelPoliticas';
import { PROFILE_ITEMS } from '_config/settings';
import useMobileNavigation from '_hooks/useMobileNavigation';

const PoliticasECondicoes = () => {
    const MobileNavigationComponent = useMobileNavigation(PROFILE_ITEMS);

    return (
        <TemplateMeuHotelContent
            title="Políticas e condições"
            description="Mantenha seus dados atualizados para receber todas as notificações"
            main={
                <>
                    <MobileNavigationComponent />
                    <MainContentMeuHotelPoliticas />
                </>
            }
            side={<SideContentMeuHotel currentPath="/meu-hotel/politicas-e-condicoes" />}
            linkBack
        />
    );
};

export default PoliticasECondicoes;
