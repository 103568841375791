import { portalAdmin } from '_services/apiConfig';

export const createAvailabilityByPeriod = async (accommodationId, roomId, params, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const availabilities = await portalAdmin.post(
        `/accommodations/${accommodationId}/rooms/${roomId}/availability_days/create_range/`,
        { ...params },
        { headers }
    );

    return availabilities.data;
};

export const deleteAvailabilityByMultiplesIds = async (accommodationId, ids, jwt) => {
    const headers = {
        Authorization: `JWT ${jwt}`,
        'Content-Type': 'application/json',
    };

    const response = await portalAdmin.delete(
        `accommodations/${accommodationId}/availability_days/destroy_multiples/`,
        {
            data: { ids },
            headers,
        }
    );

    return response.data;
};

export default {
    createAvailabilityByPeriod,
    deleteAvailabilityByMultiplesIds,
};
