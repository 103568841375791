import React from 'react';
import InputShimmer from '_atoms/Input/shimmer';
import LabelShimmer from '_atoms/Label/shimmer';
import ButtonShimmer from '_atoms/Button/shimmer';

const LoginFormShimmer = () => (
    <>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <LabelShimmer />
                <InputShimmer />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-md-12">
                <ButtonShimmer />
            </div>
        </div>
    </>
);

export default LoginFormShimmer;
