import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from '_molecules/LineInstruction/index.module.scss';

export const MESSAGE_TYPE = {
    normal: style.normal,
    bold: style.bold,
    italic: style.italic,
};

const LineInstruction = ({ type, children, className }) => (
    <div className={classNames(style.message, type, className)}>{children}</div>
);

LineInstruction.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
};

LineInstruction.defaultProps = {
    type: MESSAGE_TYPE.normal,
    className: '',
};

export default LineInstruction;
