/* accommodation types */
export const SET_ACCOMMODATION = 'set_accommodation';
export const CLEAN_ACCOMMODATION = 'clean_acommodation';

/* authentication types */

export const SET_AUTHENTICATION = 'set_authentication';
export const SET_LOGOUT = 'set_logout';

/* calendar-availability types */
export const SET_CALENDAR_ROOMS = 'set_calendar_rooms';
export const SET_CALENDAR_AVAILABILITY_ROOMS = 'set_calendar_availability_rooms';
export const SET_CALENDAR_VARIANTS_ROOMS = 'set_calendar_variants_rooms';
export const CLEAN_CALENDAR = 'clean_calendar';
export const SET_MODAL_BOOKINGS = '_set_modal_bookings';
export const SET_MODAL_TITLE = 'set_modal_title';
