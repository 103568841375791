import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NAV_MENU_PROFILE } from '_config/settings';

import style from '_organisms/SideContentMeuHotel/index.module.scss';

const SideContentMeuHotel = ({ currentPath }) => (
    <div className={style.sideBlock}>
        <ul>
            {NAV_MENU_PROFILE.map((item) => (
                <Link key={item.name} to={item.href}>
                    <li
                        className={classNames(style.item, {
                            [style.activeItem]: item.href === currentPath,
                        })}
                    >
                        <p>{item.name}</p>
                    </li>
                </Link>
            ))}
        </ul>
    </div>
);

SideContentMeuHotel.propTypes = { currentPath: PropTypes.string };

SideContentMeuHotel.defaultProps = { currentPath: undefined };

export default SideContentMeuHotel;
