import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import logo from '_images/logo-viajar-faz-bem.svg';

import style from '_atoms/Logo/index.module.scss';

const Logo = () => (
    <Link to="/" className={style.container}>
        <img className={classNames(style.logo)} src={logo} alt="viajar faz bem" />
    </Link>
);

export default Logo;
