import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useUserPermission = () => {
    const { profile } = useSelector((state) => state);
    const [userIsStaff, setUserIsStaff] = useState(profile.is_staff);
    const [userIsSuperUser, setUserIsSuperUser] = useState(profile.is_superuser);
    const [userGroups, setUserGroups] = useState([]);

    useEffect(() => {
        setUserIsStaff(profile.is_staff);
        setUserIsSuperUser(profile.is_superuser);
        setUserGroups(profile.groups);
    }, [profile]);

    return { userIsStaff, userIsSuperUser, userGroups };
};

export default useUserPermission;
