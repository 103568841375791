import accommodationActions from '_redux/actions/accommodationActions';
import authenticationActions from '_redux/actions/authenticationActions';
import calendarAvailabilityActions from '_redux/actions/calendarAvailabilityActions';
import calendarModalActions from '_redux/actions/calendarModalActions';

export default {
    ...accommodationActions,
    ...authenticationActions,
    ...calendarAvailabilityActions,
    ...calendarModalActions,
};
