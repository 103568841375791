import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button, { TYPE } from '_atoms/Button';
import Alert from '_molecules/Alert';

import styles from '_molecules/ErrorBoundary/index.module.scss';

export default class ErrorBoundary extends Component {
    constructor() {
        super();
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch() {
        this.setState({
            hasError: true,
        });
    }

    handleReloadPage() {
        window.location.reload();
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <div className="wrapper">
                    <div className={`${styles.container} wrapperContent`}>
                        <Alert title="Não foi possível carregar as informações" type="message">
                            <Button
                                onClick={this.handleReloadPage}
                                type={TYPE.link}
                                className={styles.button}
                            >
                                Clique aqui para carregar a página novamente.
                            </Button>
                            Caso o problema persista entre em contato com suporte.
                        </Alert>
                    </div>
                </div>
            );
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};
